import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

export const initializeTimeZoneAndDataGrid = () => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(advancedFormat)
  dayjs.extend(customParseFormat)
  dayjs.extend(localeData)
  dayjs.extend(weekday)
  dayjs.extend(weekOfYear)
  dayjs.extend(weekYear)
  dayjs.extend(localizedFormat)
  dayjs.extend(isSameOrBefore)
  dayjs.extend(isSameOrAfter)
  dayjs.extend(relativeTime)
  dayjs.tz.setDefault(dayjs.tz.guess())
}