import { createListenerMiddleware } from '@reduxjs/toolkit'
import { LOCAL_STORAGE_KEYS } from '../../shared/constants'
import API_CALLS from './api'
import SUPABASE_API_CALLS from './supabaseApi'
import { executeAPICalls } from '../../shared/redux'

// LISTENERS
export const listenerMiddleware = createListenerMiddleware()

// ALL API CALLS
executeAPICalls(API_CALLS, listenerMiddleware)

// ALL API CALLS
executeAPICalls(SUPABASE_API_CALLS, listenerMiddleware, true)
