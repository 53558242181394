import { useState } from 'react'
import { useTranslation } from '../shared/hooks'
import {
  Typography,
  Flex,
  Button,
  Drawer,
  Space,
  Tooltip,
  Descriptions,
  Table
} from 'antd'
import { 
  CloseOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import i18n from 'i18next'
import { formatDateNoTimezone, isValidURL } from '../shared/utils'
import AddressText from './AddressText'
import CompanyInfoDrawer from './CompanyInfoDrawer'
import SequestrationWellProjectStatusTag from './SequestrationWellProjectStatusTag'
import SequestrationWellProjectStageTag from './SequestrationWellProjectStageTag'

const { Title, Text, Link } = Typography

const SequestrationWellProjectInfoDrawer = ({
  onClose,
  open,
  sequestrationWellProject,
  placement
}) => {
  const [companyInfoDrawerCompany, setCompanyInfoDrawerCompany] = useState(null)
  const { t } = useTranslation('components.SequestrationWellProjectInfoDrawer')

  const clearCompanyInfoDrawerCompany = () => setCompanyInfoDrawerCompany(null)

  const externalIdentificationTableColumns = [
    {
      title: t('external_id.external_id'),
      dataIndex: 'external_id',
      key: 'external_id'
    },
    {
      title: t('external_id.id_type'),
      dataIndex: 'id_type',
      key: 'id_type',
      render: (text) => i18n.t(`constants.sequestration_well_project_external_id_type.${text}`)
    }
  ]

  const sequestrationWellTableColumns = [
    {
      title: t('sequestration_well.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('sequestration_well.well_phase'),
      dataIndex: 'well_phase',
      key: 'well_phase',
      render: (text) => text ? i18n.t(`constants.sequestration_well_project_sequestration_well_phase.${text}`) : ''
    },
    {
      title: t('sequestration_well.permit_id'),
      dataIndex: 'permit_id',
      key: 'permit_id'
    },
    {
      title: t('sequestration_well.permit_date'),
      dataIndex: 'permit_date',
      key: 'permit_date'
    }
  ]

  return <Drawer
    placement={placement}
    title={
      <Flex justify="space-between">
        <Title level={3}>
          {sequestrationWellProject?.name}
        </Title>
        <Button type='text' size='small' onClick={onClose}>
          <CloseOutlined />
        </Button>
      </Flex>
    }
    size='large'
    closeIcon={false}
    onClose={onClose}
    open={open}>
      <Space direction='vertical'>
        { sequestrationWellProject?.description ? <Text>{sequestrationWellProject?.description}</Text> : <></> }
        <Flex vertical className='gap-2'>
          <Descriptions column={2} size='small' items={[
            ...sequestrationWellProject?.external_identifications.map(ei => ({ 
              key: ei.id_type,
              label: i18n.t(`constants.sequestration_well_project_external_id_type.${ei.id_type}`),
              children: ei.external_id 
            })),
            ... sequestrationWellProject?.well_category ? [{
              key: 'well_category',
              label: t('label.well_category'),
              children: sequestrationWellProject?.well_category ? i18n.t(`constants.sequestration_well_project_category.${sequestrationWellProject?.well_category}`) : ''
            }] : [],
            ... sequestrationWellProject?.permitting_body ? [{
              key: 'permitting_body',
              label: t('label.permitting_body'),
              children: sequestrationWellProject?.permitting_body ? i18n.t(`constants.sequestration_well_project_permitting_body.${sequestrationWellProject?.permitting_body}`) : ''
            }] : [],
            {
              key: 'status',
              label: t('label.status'),
              children: <SequestrationWellProjectStatusTag sequestrationWellProject={sequestrationWellProject} />
            },
            ... sequestrationWellProject.active_date ? [{
              key: 'active_date',
              label: t('label.active_since'),
              children: formatDateNoTimezone(sequestrationWellProject?.active_date)
            }] : [],
            ... sequestrationWellProject?.total_capacity ? [{
              key: 'total_capacity',
              label: t('label.total_capacity'),
              children: `${sequestrationWellProject?.total_capacity} ${t('label.total_capacity_unit')}`
            }] : [],
            ... sequestrationWellProject?.lease_area ? [{
              key: 'lease_area',
              label: t('label.lease_area'),
              children: `${sequestrationWellProject?.lease_area} ${t('label.lease_area_unit')}`
            }] : [],
            ... sequestrationWellProject.project_stage ? [{
              key: 'project_stage',
              label: t('label.permit_stage'),
              children: <SequestrationWellProjectStageTag project_stage={sequestrationWellProject.project_stage} />
            }] : [],
            ... sequestrationWellProject.date_current_stage_reached ? [{
              key: 'date_current_stage_reached',
              label: t('label.date_current_stage_reached'),
              children: formatDateNoTimezone(sequestrationWellProject?.date_current_stage_reached),
              span: 2
            }] : [],
            ... sequestrationWellProject.external_reference_url ? [{
              key: 'external_reference_url',
              label: <Flex align='center'>
                {t('label.external_reference_url')}
                <Tooltip className='mx-1' title={t('label.external_reference_url_tooltip')}>
                  <InfoCircleOutlined />
                </Tooltip>
              </Flex>,
              span: 2,
              children: isValidURL(sequestrationWellProject?.external_reference_url)
                ? <Link href={sequestrationWellProject?.external_reference_url} rel='noreferrer' target='_blank'>{ t('link') }</Link>
                : <Text>{sequestrationWellProject?.external_reference_url}</Text>
            }] : [],
            {
              key: 'address',
              label: t('label.address'),
              span: 2,
              children: <AddressText address={sequestrationWellProject?.address} />
            }
          ]} />
          </Flex>
          <Flex vertical className='gap-2'>
            <Title level={5}>{t('title.wells')}</Title>
            <Table
              rowKey={r => r.id}
              size='small'
              columns={sequestrationWellTableColumns}
              dataSource={sequestrationWellProject?.sequestration_wells} />
          </Flex>
          { sequestrationWellProject?.project_developer ? <Flex vertical className='gap-2'>
            <Title level={5}>{t('title.project_developer')}</Title>
            <Flex align='center'>
                <Link onClick={() => setCompanyInfoDrawerCompany(sequestrationWellProject.project_developer)}>{sequestrationWellProject.project_developer.name}</Link>
              </Flex>
          </Flex> : <></> }
      </Space>
      <CompanyInfoDrawer
        open={companyInfoDrawerCompany}
        onClose={clearCompanyInfoDrawerCompany}
        company={companyInfoDrawerCompany}
        placement={placement} />
    </Drawer>
}

export default SequestrationWellProjectInfoDrawer