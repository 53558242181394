import { Typography } from 'antd'
import { useTranslation } from '../shared/hooks'

const { Title } = Typography

const Unauthorized = () => {
  const { t } = useTranslation('components.Unauthorized')

  return <Title className='p-6'>{t('title')}</Title>
}

export default Unauthorized