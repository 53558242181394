import { useState } from 'react'
import { connect } from 'react-redux'
import { Typography, theme, Input, Space, Button } from 'antd'
import { useTranslation } from '../shared/hooks'
import { EyeOutlined, EyeInvisibleOutlined, EditOutlined } from '@ant-design/icons'
import { UPDATE_PASSWORD_REQUESTED } from '../redux/actions'

const { Title, Text } = Typography

const Account = ({ session, updatePassword }) => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { t } = useTranslation('pages.Account')
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const passwordIconRender = (visible) => visible ? <EyeOutlined /> : <EyeInvisibleOutlined />

  return (
    <div className='p-6 w-full m-6 lg:mx-8' style={{ background: colorBgContainer, borderRadius: borderRadiusLG }}>
      <Title>{ t('title') }</Title>
      <Space direction='vertical'>
        <div>
          <Text strong>{ t('email') }: </Text>
          <Text>{session?.user?.email}</Text>
        </div>
        <div>
          <Title level={4}>{ t('change_password') }</Title>
          <Space direction='vertical' className='min-w-64'>
            <Input.Password placeholder={t('new_password')} iconRender={passwordIconRender} onChange={(e) => setNewPassword(e.target.value)} />
            <Input.Password placeholder={t('confirm_password')} iconRender={passwordIconRender} onChange={(e) => setConfirmPassword(e.target.value)} status={newPassword !== confirmPassword ? 'error' : ''} />
            <Button type='primary' disabled={newPassword !== confirmPassword || newPassword === ''} onClick={() => updatePassword(newPassword)} ><EditOutlined /> { t('update_password') }</Button>
            { newPassword !== confirmPassword ? 
              <Text type='danger'>{t('unmatched_password_error')}</Text>
              : <></> }
          </Space>
        </div>
      </Space>
    </div>)
}

function mapStateToProps(state) {
  const { session } = state
  return {
    session
 }
}

function mapDispatchToProps (dispatch) {
  return {
    updatePassword: (password) => dispatch(UPDATE_PASSWORD_REQUESTED(password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);

