import { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  FETCH_COUNTRIES_REQUESTED,
  FETCH_COUNTRY_SUBDIVISIONS_REQUESTED
} from '../redux/actions'

const AddressText = ({ countries, country_subdivisions, isLoadingFor, address, fetchCountries, fetchCountrySubdivisions }) => {
  useEffect(() => {
    if (!countries.length && !isLoadingFor.FETCH_COUNTRIES_REQUESTED) fetchCountries()
  }, [isLoadingFor.FETCH_COUNTRIES_REQUESTED, countries])

  useEffect(() => {
    if (!country_subdivisions.length && !isLoadingFor.FETCH_COUNTRY_SUBDIVISIONS_REQUESTED) fetchCountrySubdivisions()
  }, [isLoadingFor.FETCH_COUNTRY_SUBDIVISIONS_REQUESTED, country_subdivisions])

  const addressBlocks = []
  if (address) {
    if (address.address1) addressBlocks.push(address.address1)
    if (address.address2) addressBlocks.push(address.address2)
    if (address.city) addressBlocks.push(address.city)

    const country = countries.find(c => c.id === address.country_id)
    const country_subdivision = country_subdivisions.find(c => c.id === address.country_subdivision_id)
    if (country || country_subdivision) {
      const countryBlocks = []

      if (country_subdivision) countryBlocks.push(country_subdivision.code.split('-')[1])
      if (country) countryBlocks.push(country.name)

      addressBlocks.push(countryBlocks.join(' '))
    }

    if (address.zip) addressBlocks.push(address.zip)
  }

  return addressBlocks.join(', ')
}

function mapStateToProps(state) {
  const {
    countries,
    country_subdivisions,
    isLoadingFor
  } = state
  return {
    countries,
    country_subdivisions,
    isLoadingFor
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchCountries: () => dispatch(FETCH_COUNTRIES_REQUESTED()),
    fetchCountrySubdivisions: () => dispatch(FETCH_COUNTRY_SUBDIVISIONS_REQUESTED())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressText);