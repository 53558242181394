import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Typography, Space, Select, Button, Table, Card, Upload, Alert } from 'antd'
import { DownloadOutlined, UploadOutlined, SaveOutlined, LoadingOutlined } from '@ant-design/icons'
import {
  FETCH_MASS_UNITS_REQUESTED,
  FETCH_COUNTRIES_REQUESTED,
  FETCH_COUNTRY_SUBDIVISIONS_REQUESTED,
  FETCH_NAICS_REQUESTED,
  FETCH_GASSES_REQUESTED,
  FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_REQUESTED,
  FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_REQUESTED,
  FETCH_ENVIROFACTS_GHG_EMITTER_GAS_REQUESTED,
  FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_REQUESTED,
  FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_REQUESTED,
  FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_REQUESTED,
  FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_REQUESTED,
  FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_REQUESTED,
  BULK_INSERT_FACILITY_DATA_REQUESTED,
  FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_REQUESTED,
  FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_REQUESTED
} from '../redux/actions'
import { processEnvirofactsEmitterData, processCanGHGRPData } from '../data-processing-scripts'
import { useTranslation } from '../shared/hooks'
import Sidebar from '../components/Sidebar'
import { formatFloat } from '../shared/utils'
import Papa from 'papaparse'

const { Title, Text } = Typography

const formatDataForDisplay = (data) => {
  return data.map((d, i) => {
    return {
      ...d,
      facility_id: (d.facility_identifications || [])[0]?.external_id,
      facility_reports: d.facility_reports?.length,
      key: i
    }
  })
}

const DataLoader = ({ 
  isLoading,
  fetchEnvirofactsEmitterData,
  fetchMetadata,
  fetchCANGHGRPPublishDate,
  bulkInsertFacilityData,
  fetchFacilityCompareIds,
  mass_units,
  countries,
  country_subdivisions,
  naics,
  gasses,
  envirofactsGHGEmitterFacilityData,
  envirofactsParentCompanyInfoData,
  envirofactsGHGEmitterGasData,
  envirofactsGHGEmitterSubpartData,
  envirofactsTSubpartInformationData,
  envirofactsISubpartInformationData,
  envirofactsFSubpartInformationData,
  envirofactsSSSubpartInformationData,
  canGhgrpDataPublishDate,
  canGhgrpFacilityReportData,
  facilityCompareIds,
  isLoadingFor
}) => {
  const [data, setData] = useState([])
  const [rawData, setRawData] = useState([])
  const [dataSource, setDataSource] = useState(null)
  const [selectedDatumId, setSelectedDatumId] = useState(null)
  const [processingFunction, setProcessingFunction] = useState(null)
  const [compareFetch, setCompareFetch] = useState(null)
  const [comparedData, setComparedData] = useState([])
  const [dataUploaded, setDataUploaded] = useState(false)
  const [errors, setErrors] = useState([])
  const [warnings, setWarnings] = useState([])
  const { t } = useTranslation('components.DataLoader')

  const selectedDatum = data[selectedDatumId]

  const clearData = () => {
    setSelectedDatumId(null)
    setData([])
    setComparedData([])
    setDataUploaded(false)
  }

  const changeDataSource = (value) => {
    clearData()
    setRawData([])
    setDataSource(value)
  }

  const dataSources = [
    { value: 'us_epa_emitters', label: t('data_sources.us_epa_emitters') },
    { value: 'can_ghgrp', label: t('data_sources.can_ghgrp') }
  ]

  const columns = [
    {
      title: t('columns.facility_id'),
      dataIndex: 'facility_id',
      key: 'facility_id',
    },
    {
      title: t('columns.facility_name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('columns.existing_id'),
      dataIndex: 'id',
      key: 'id',
    }
  ]

  useEffect(() => {
    fetchMetadata()
  }, [])

  useEffect(() => {
    if (data.length === 0) {
      switch (dataSource) {
        case 'us_epa_emitters':
          if (mass_units.length &&
            countries.length &&
            country_subdivisions.length &&
            naics.length &&
            gasses.length &&
            envirofactsGHGEmitterFacilityData.length &&
            envirofactsParentCompanyInfoData.length &&
            envirofactsGHGEmitterGasData.length &&
            envirofactsGHGEmitterSubpartData.length && 
            envirofactsTSubpartInformationData.length &&
            envirofactsISubpartInformationData.length &&
            envirofactsFSubpartInformationData.length &&
            envirofactsSSSubpartInformationData.length) {
            setProcessingFunction(processEnvirofactsEmitterData(
              mass_units,
              countries,
              country_subdivisions,
              naics,
              gasses,
              envirofactsGHGEmitterFacilityData,
              envirofactsParentCompanyInfoData,
              envirofactsGHGEmitterGasData,
              envirofactsGHGEmitterSubpartData,
              envirofactsTSubpartInformationData,
              envirofactsISubpartInformationData,
              envirofactsFSubpartInformationData,
              envirofactsSSSubpartInformationData
            ))
            const newCompareFetch = () => fetchFacilityCompareIds('us_epa_id')
            setCompareFetch(() => newCompareFetch)
          }
          break
        case 'can_ghgrp':
          if (mass_units.length &&
            countries.length &&
            country_subdivisions.length &&
            naics.length &&
            gasses.length && 
            canGhgrpDataPublishDate !== '' &&
            rawData.length) {
            setProcessingFunction(processCanGHGRPData(
              mass_units,
              countries,
              country_subdivisions,
              naics,
              gasses,
              rawData,
              canGhgrpDataPublishDate
            ))
            const newCompareFetch = () => fetchFacilityCompareIds('can_ghgrp_id')
            setCompareFetch(() => newCompareFetch)
          }
          break
        default:
          break
      }
    }
  }, [
    mass_units,
    countries,
    country_subdivisions,
    naics,
    gasses,
    envirofactsGHGEmitterFacilityData,
    envirofactsParentCompanyInfoData,
    envirofactsGHGEmitterGasData,
    envirofactsGHGEmitterSubpartData,
    envirofactsTSubpartInformationData,
    envirofactsISubpartInformationData,
    envirofactsFSubpartInformationData,
    envirofactsSSSubpartInformationData,
    canGhgrpDataPublishDate,
    canGhgrpFacilityReportData,
    data,
    rawData
  ])

  useEffect(() => {
    const newErrors = []
    const newWarnings = []

    const facilityNoIdentifications = data.filter(d => !d.facility_identifications.length)
    if (facilityNoIdentifications.length) newErrors.push(`${facilityNoIdentifications.length} facilities with no Identifications`)

    const facilityNoParentCompany = data.filter(d => !d.parent_companies.length)
    if (facilityNoParentCompany.length) newWarnings.push(`${facilityNoParentCompany.length} facilities with no Parent Company`)

    const facilityParentCompanyNoOwnership = data.filter(d => d.parent_companies.findIndex(pc => !pc.ownership_percentage && pc.ownership_percentage !== 0) !== -1 && d.parent_companies.length)
    if (facilityParentCompanyNoOwnership.length) newWarnings.push(`${facilityParentCompanyNoOwnership.length} facilities with Parent Company that is missing ownership percentage`)

    const facilityNoAddress = data.filter(d => !d.address)
    if (facilityNoAddress.length) newWarnings.push(`${facilityNoAddress.length} facilities with no Address`)

    const facilityNoLocation = data.filter(d => !d.location)
    if (facilityNoLocation.length) newWarnings.push(`${facilityNoLocation.length} facilities with no Location`)

    if (['us_epa_emitters', 'can_ghgrp'].includes(dataSource)) {
      const facilityNoNaics = data.filter(d => !d.naics.length)

      if (facilityNoNaics.length) newWarnings.push(`${facilityNoNaics.length} facilities with no NAICS`)
    }

    setErrors(newErrors)
    setWarnings(newWarnings)
  }, [data])

  useEffect(() => {
    if (processingFunction) {
      processingFunction.then(res => {
        setData(res)
        setProcessingFunction(null)
      })
    }
  }, [processingFunction])

  useEffect(() => {
    if (compareFetch) {
      compareFetch()
      setCompareFetch(null)
    }
  }, [compareFetch])

  useEffect(() => {
    if (data.length && facilityCompareIds) {
      const limitedIds = facilityCompareIds

      const existingFacilities = []
      const newFacilities = []
      const compData = [...data]
      compData.forEach(f => {
        const match = limitedIds.findIndex(fci => {
          return f.facility_identifications.findIndex(fi => {
            return fci.facility_identifications.findIndex(fciFi => fciFi.external_id.toString() === fi.external_id.toString()) !== -1
          }) !== -1
        })

        if (match !== -1) {
          existingFacilities.push(f)
          f.id = limitedIds[match].id
        } else {
          newFacilities.push(f)
        }
      })

      setComparedData(compData)
    }
  }, [facilityCompareIds, data])

  let dispatch = null
  let sourceType = null
  switch (dataSource) {
    case 'us_epa_emitters':
      sourceType = 'api'
      dispatch = fetchEnvirofactsEmitterData
      break
    case 'can_ghgrp':
      sourceType = 'csv'
      dispatch = fetchCANGHGRPPublishDate
      break
    default:
      break
  }

  const tableSelectionProps = {
    type: 'radio',
    onChange: e => setSelectedDatumId(e[0]),
    selectedRowKeys: [selectedDatumId]
  }

  return <div className='flex'>
    <div className='grow'>
      <Title>Data Loader</Title>
      <Space>
        <Select
          onChange={changeDataSource}
          className='min-w-64'
          options={dataSources}
        />
        { dataSource ? <>
              { sourceType === 'api' || dispatch ?
                <Button type='primary' onClick={() => { clearData(); dispatch() }} disabled={!dispatch}>
                  { t('retrieve_data') } <DownloadOutlined/>
                </Button>
              : <></> }
              { sourceType === 'csv' ?
                <Upload 
                    accept=".csv"
                    showUploadList={false}
                    beforeUpload={file => {
                        const reader = new FileReader();

                        reader.readAsText(file);

                        reader.onload = function(event){
                          var csv = event.target.result;
                          Papa.parse(csv, {
                            header: true,
                            skipEmptyLines: true,
                            complete: function (results) {
                              setRawData(results.data)
                            },
                          });
                        };
                        reader.onerror = function(){ alert('Unable to read ' + file.fileName); };

                        // Prevent upload
                        return false;
                    }}>
                  <Button icon={<UploadOutlined />}>{t('upload_csv_file')}</Button>
                </Upload>
              : <></> }
            </>
          : <></>
        }
        { isLoading ? <Text>Loading: {Object.keys(isLoadingFor).filter(k => isLoadingFor[k]).join(', ')}</Text> : <></> }
        { data.length ? 
          <Button onClick={() => { bulkInsertFacilityData(comparedData); setDataUploaded(true) }} disabled={dataUploaded || isLoading || !comparedData.length} >
            { isLoading ? <LoadingOutlined /> : <>{ t('upload_data') } <SaveOutlined/></> }
          </Button>
          : <></>
        }
      </Space>
      <div className='mt-4'>
        <Space direction='vertical' className='w-full'>
          { errors.map(e => <Alert message={e} type='error' />) }
        </Space>
        <Space direction='vertical' className='w-full'>
          { warnings.map(w => <Alert message={w} type='warning' />) }
        </Space>
      </div>
      <div className='mt-4'>
        <Table bordered loading={isLoading} dataSource={formatDataForDisplay(comparedData)} columns={columns} rowSelection={tableSelectionProps} />
      </div>
    </div>
    { selectedDatum ? <Sidebar forceExpanded className='min-w-96 w-[50%] bg-gray-200 rounded-lg' showCollapseButton onCollapse={() => setSelectedDatumId(null)}>
      <Space direction='vertical' className='p-2 overflow-y-scroll max-h-full'>
        <div>
          <Text strong>{ t('details.facility_name') }: </Text>
          <Text>{ selectedDatum.name }</Text>
        </div>
        <div>
          <div>
            <Text strong underline>{ t('details.facility_identifiers') }</Text>
            { selectedDatum.facility_identifications.map(n => <div>
              <div>
                <Text strong>{n.id_type}</Text>: <Text>{ n.external_id }</Text>
              </div>
            </div>) }
          </div>
        </div>
        <div>
          <div>
            <Text strong underline>{ t('details.coordinates') }</Text>
          </div>
          <Text>{ selectedDatum.location?.latitude }, { selectedDatum.location?.longitude }</Text>
        </div>
        <div>
          <div>
            <Text strong underline>{ t('details.address') }</Text>
          </div>
          <div>
            <div>
              <Text>{ selectedDatum.address?.address1 }</Text>
            </div>
            <div>
              <Text>{ selectedDatum.address?.address2 }</Text>
            </div>
          </div>
          <div>
            <Text strong>{ t('details.city') }: </Text>
            <Text>{ selectedDatum.address?.city }</Text>
          </div>
          <div>
            <Text strong>{ t('details.state') }: </Text>
            <Text>{ country_subdivisions.find(m => m.id === selectedDatum.address?.country_subdivision_id).name }</Text>
          </div>
          <div>
            <Text strong>{ t('details.country') }: </Text>
            <Text>{ countries.find(m => m.id === selectedDatum.address?.country_id).name }</Text>
          </div>
          <div>
            <Text strong>{ t('details.zip') }: </Text>
            <Text>{ selectedDatum.address?.zip }</Text>
          </div>
        </div>
        <div>
          <div>
            <Text strong underline>{ t('details.naics') }</Text>
          </div>
          { selectedDatum.naics.map(n => <div>
            <Text>{naics.find(m => m.id === n.naics_id).code}</Text>
          </div>) }
        </div>
        <div>
          <div>
            <Text strong underline>{ t('details.parent_companies') }</Text>
          </div>
          { selectedDatum.parent_companies.map(n => <div>
            <div>
              <Text strong>{n.name}</Text>
            </div>
            { n.address ? <>
              <div>
                <Text>{ t('details.address') }: {n.address.address1}</Text>
              </div>
              <div>
                <Text>{ t('details.city') }: {n.address.city}</Text>
              </div>
              <div>
                <Text>{ t('details.state') }: { country_subdivisions.find(m => m.id === n.address.country_subdivision_id).name }</Text>
              </div>
              <div>
                <Text>{ t('details.country') }: { countries.find(m => m.id === n.address.country_id).name }</Text>
              </div>
            </> : <></> }
            { n.public_contact ? <>
              <div>
                <Text>{ t('details.public_contact') }: {n.public_contact.name} ({n.public_contact.position})</Text>
              </div>
              <div>
                <Text> - {n.public_contact.contact_info.email} ({n.public_contact.contact_info.phone}{ n.public_contact.contact_info.extension ? ` x ${n.public_contact.contact_info.extension}` : '' })</Text>
              </div>
            </> : <></> }
              <div>
                <Text>{ t('details.ownership') }: {n.ownership_percentage}%</Text>
              </div>
          </div>) }
        </div>
        { selectedDatum.facility_reports?.length ? <>
          <div>
            <Title level={3}>{ t('details.annualReports') }</Title>
          </div>
          <div>
            { selectedDatum.facility_reports.sort((a, b) => a.year < b.year ? 1 : a.year > b.year ? -1 : 0).map(r => {
                return <Card title={`${r.year} - ${r.report_type}`} className='!mb-2'>
                  <Text strong underline>Gasses</Text>
                  <div>
                    {r.facility_report_gasses.map(g => {
                      const gas = gasses.find(n => n.id === g.gas_id)

                      if (!gas) return g.gas_id

                      return <>
                        <div>
                          <Text strong>{gas.name} ({gas.short_name}) </Text>
                        </div>
                        { g.quantity || g.quantity === 0 ? <div>
                          <Text>{formatFloat(g.quantity)} { mass_units.find(m => m.id === g.mass_unit_id).symbol }</Text>
                        </div> : <></> }
                        { g.quantity_co2e || g.quantity_co2e === 0 ? <div>
                          <Text> {formatFloat(g.quantity_co2e)} CO2e { mass_units.find(m => m.id === g.co2e_mass_unit_id).symbol }</Text>
                        </div> : <></> }
                      </>
                    })}
                  </div>
                </Card>
              })
            }
          </div>
        </> : <></> }
      </Space>
    </Sidebar> : <></> }
  </div>
}

function mapStateToProps(state) {
  const {
    mass_units,
    countries,
    country_subdivisions,
    naics,
    gasses,
    envirofactsGHGEmitterFacilityData,
    envirofactsParentCompanyInfoData,
    envirofactsGHGEmitterGasData,
    envirofactsGHGEmitterSubpartData,
    envirofactsTSubpartInformationData,
    envirofactsISubpartInformationData,
    envirofactsFSubpartInformationData,
    envirofactsLSubpartInformationData,
    envirofactsSSSubpartInformationData,
    canGhgrpDataPublishDate,
    facilityCompareIds,
    message,
    isLoading,
    isLoadingFor
  } = state
  return {
    mass_units,
    countries,
    country_subdivisions,
    naics,
    gasses,
    envirofactsGHGEmitterFacilityData,
    envirofactsParentCompanyInfoData,
    envirofactsGHGEmitterGasData,
    envirofactsGHGEmitterSubpartData,
    envirofactsTSubpartInformationData,
    envirofactsISubpartInformationData,
    envirofactsFSubpartInformationData,
    envirofactsSSSubpartInformationData,
    canGhgrpDataPublishDate,
    facilityCompareIds,
    message,
    isLoading,
    isLoadingFor
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchEnvirofactsEmitterData: () => {
      dispatch(FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_REQUESTED())
      dispatch(FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_REQUESTED())
      dispatch(FETCH_ENVIROFACTS_GHG_EMITTER_GAS_REQUESTED())
      dispatch(FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_REQUESTED())
      dispatch(FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_REQUESTED())
      dispatch(FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_REQUESTED())
      dispatch(FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_REQUESTED())
      dispatch(FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_REQUESTED())
    },
    fetchCANGHGRPPublishDate: () => {
      dispatch(FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_REQUESTED())
    },
    fetchMetadata: () => {
      dispatch(FETCH_MASS_UNITS_REQUESTED())
      dispatch(FETCH_COUNTRIES_REQUESTED())
      dispatch(FETCH_COUNTRY_SUBDIVISIONS_REQUESTED())
      dispatch(FETCH_NAICS_REQUESTED())
      dispatch(FETCH_GASSES_REQUESTED())
    },
    bulkInsertFacilityData: (data) => dispatch(BULK_INSERT_FACILITY_DATA_REQUESTED(data)),
    fetchFacilityCompareIds: (source) => dispatch(FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_REQUESTED(source))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataLoader);