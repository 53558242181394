import dayjs from 'dayjs'
import _, { isObject, transform } from 'lodash'
import { pipe } from 'lodash/fp'
import { TIMEZONE_ABBREVIATION } from './constants'

// Copied from Mangrove

export const displayOptional = (value, list) => {
  if (_.isNil(value) || value === '') return '-'
  else if (Array.isArray(value)) return value.length > 0 ? list : '-'

  return value.toString()
}

export const formatDateTime = (date) =>
  date
    ? formatDateWithTimezoneAbbr(dayjs.tz(new Date(date)).format('MMM D, YYYY HH:mm zzz'), dayjs.tz(new Date(date)))
    : '-'

export const formatDate = (date) =>
  date
    ? formatDateWithTimezoneAbbr(dayjs.tz(new Date(date)).format('MMM D, YYYY'), dayjs.tz(new Date(date)))
    : '-'

export const formatTimeRange = (startTime, endTime) => {
  if (startTime === null || endTime === null) return 'N/A'
  const startDate = dayjs.tz(new Date(startTime))
  const endDate = dayjs.tz(new Date(endTime))

  const formattedStartDate = startDate.format('MMM D, YYYY HH:mm')

  if (endDate.isSame(startDate, 'day') && endDate.isSame(startDate, 'time')) {
    return `${formattedStartDate} ${formatDateWithTimezoneAbbr(startDate.format('zzz'), startDate)}`
  }

  const formattedEndDate = endDate.isSame(startDate, 'day')
    ? formatDateWithTimezoneAbbr(endDate.format('HH:mm zzz'), endDate)
    : formatDateWithTimezoneAbbr(endDate.format('MMM D, YYYY HH:mm zzz'), endDate)

  return `${formattedStartDate} - ${formattedEndDate}`
}

export const parameterize = (str) =>
  str
    ?.toLowerCase()
    ?.replace(/ /g, '-')
    .replace(/[^a-z0-9-]/g, '')

export const isParameterized = (str) => str === parameterize(str)

export const filterOptionCaseInsensitive = (search, option) =>
  (option?.label ?? '')
    .toLowerCase()
    .replace(/[^a-z]/g, '')
    .includes(search.toLowerCase().replace(/[^a-z]/g, ''))

export const filterOptionSort = (optionA, optionB) =>
  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

export const logError = async (error, { componentStack }) => {
  console.log(error)
}

// Formatting utils
export const formatCurrency = (value, currency) => {
  if (!value) return null
  if (!currency) return formatFloat(value)

  const formattedCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(value)

  return `${formattedCurrency} ${currency}`
}

export const capitalizeFirstWord = (words) => {
  if (!words) return null

  return _.capitalize(_.lowerCase(words))
}

export const formatFloat = (value, { minimumFractionDigits, maximumFractionDigits } = {}) => {
  if (_.isNil(value)) return
  return (typeof value === 'number' ? value : Number(value)).toLocaleString([], {
    minimumFractionDigits: _.isNil(minimumFractionDigits) ? 2 : minimumFractionDigits,
    maximumFractionDigits: _.isNil(maximumFractionDigits) ? 5 : maximumFractionDigits,
  })
}

export const formatFloatOptional = pipe(formatFloat, displayOptional)

export const formatInteger = (value) => {
  if (_.isNil(value)) return
  return (typeof value === 'number' ? value : Number(value)).toLocaleString()
}

export const formatIntegerOptional = pipe(formatInteger, displayOptional)

export const getFileNameFromContentDispositionHeader = (response) => {
  const contentDisposition = response.headers.get('Content-Disposition')
  // Content-Disposition example
  // attachment; filename="upload-template-default-events.csv"; filename*=UTF-8''upload-template-default-events.csv
  let fileName = 'download.csv'
  if (contentDisposition) {
    const filenameMatchRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i
    const match = contentDisposition.match(filenameMatchRegex)
    if (match && match[1]) {
      fileName = decodeURIComponent(match[1])
    }
  }

  return fileName
}

export const findDeep = (obj, predicate) => {
  if (predicate(obj)) return obj
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === 'object') {
      const result = findDeep(obj[key], predicate)
      if (result) return result
    }
  }
  return null
}

export const setTimeToMidnightIfNoTimeSelected = (showDateTime, start, end) =>
  showDateTime ? [start, end] : [start.startOf('day'), end.endOf('day')]

export const formatDayjsFields = (obj) => {
  return transform(obj, function (result, value, key) {
    if (dayjs.isDayjs(value)) {
      result[key] = dayjs(value).format()
    } else if (isObject(value)) {
      result[key] = formatDayjsFields(value)
    } else {
      result[key] = value
    }
  })
}

export const formatDateInString = (str, dateFormat) => {
  // Regular expression pattern to match Date in YYYY-MM-DD format.
  const datePattern = /\b\d{4}-\d{2}-\d{2}\b/g
  let text = str
  const datesInString = typeof text === 'string' && text?.match(datePattern)

  if (datesInString) {
    datesInString.forEach((date) => {
      const formattedDate = dayjs(date).format(dateFormat)
      text = text.replace(date, formattedDate)
    })
  }

  return text
}

// format("z") does not return correct timezone abbreviation Berlin -> GMT+2 instead of CEST
// This function replaces full timezone name with abbreviation
export const formatDateWithTimezoneAbbr = (dateString, originalDate = null) => {
  const timezoneName = Object.keys(TIMEZONE_ABBREVIATION).find((tz) => dateString.includes(tz))

  if (timezoneName) {
    const abbr = TIMEZONE_ABBREVIATION[timezoneName]
    return dateString.replace(timezoneName, abbr)
  }

  // use original abbreviation if abbreviation is not found
  if (originalDate) {
    const abbr = originalDate.format('z')
    return dateString.replace(originalDate.format('zzz'), abbr)
  }

  return dateString
}

// New Utils
export const titleCase = (str) => {
   var splitStr = (str || '').toLowerCase().split(' ');
   for (var i = 0; i < splitStr.length; i++) {
       // You do not need to check if i is larger than splitStr length, as your for does that for you
       // Assign it back to the array
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
   }
   // Directly return the joined string
   return splitStr.join(' '); 
}

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export const formatDateNoTimezone = (date) => 
  date
    ? dayjs(date).format("MMM DD, YYYY")
    : '-'

export const isValidURL = (url) => {
    try {
        const urlObject = new URL(url);
        
        // Additional checks, if necessary.
        return true;
    } catch (error) {
        return false;
    }
}