import React from 'react'
import { Typography, theme } from 'antd'
import { useTranslation } from '../shared/hooks'

const { Title } = Typography


const Home = ({ session }) => {
  const { t } = useTranslation('pages.Home')
  
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <div className='p-6 w-full m-6 lg:mx-8' style={{ background: colorBgContainer, borderRadius: borderRadiusLG }}>
      <Title>{t('title')}</Title>
    </div>)
}

export default Home;

