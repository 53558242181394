import { titleCase } from '../shared/utils'

const matchDatum = (data, facility_id) => {
  const check = d => d.facility_identifications.findIndex(n => n.external_id === facility_id) !== -1

  return { datum: data.find(check), datumIndex: data.findIndex(check) }
}

const lastReportingYear = (datum) => {
  return datum.facility_reports.reduce((highest, n) => {
    return highest >= n.year ? highest : n.year
  }, 0)
}

const matchReport = (data, datum) => {
  const check = d => datum.year === d.year || datum.reporting_year === d.year

  return { report: data.find(check), reportIndex: data.findIndex(check) }
}

export const processEnvirofactsEmitterData = async (
  mass_units,
  countries,
  country_subdivisions,
  naics,
  gasses,
  envirofactsGHGEmitterFacilityData,
  envirofactsParentCompanyInfoData,
  envirofactsGHGEmitterGasData,
  envirofactsGHGEmitterSubpartData,
  envirofactsTSubpartInformationData,
  envirofactsISubpartInformationData,
  envirofactsFSubpartInformationData,
  envirofactsSSSubpartInformationData
) => {
  const nowUTC = new Date().toISOString().slice(0, 19).replace('T', ' ')

  const dataSource = {
    source: 'us_epa',
    currency_date: nowUTC,
    access_date: nowUTC
  }

  const defaultObject = {
    facility_identifications: [],
    location: {
      longitude: 0,
      latitude: 0
    },
    address: {},
    parent_companies: [],
    naics: [],
    facility_reports: [],
    data_source: { ...dataSource }
  }

  const defaultReport = {
    year: null,
    report_type: 'emission',
    facility_report_gasses: [],
    data_source: { ...dataSource }
  }

  const usaCountryID = countries.find(c => c.iso_code_3 === 'USA').id
  const tonnesMassUnitID = mass_units.find(m => m.name === 'Tonnes (Metric Tons)').id

  const data = []
  const naicsId = (code) => {
    let c = code
    let item = null
    while (!item && c.length) {
      item = naics.find(n => n.code === parseInt(c))
      c = c.substring(0, c.length - 1);
    }
    return item?.id
  }

  const gasId = (code, hasParent = false) => {
    let gas = gasses.find(g => g.short_name === code && g.type === 'fossil' && (!hasParent || g.parent_id))

    if (!gas) gas = gasses.find(g => g.name === code && g.type === 'fossil' && (!hasParent || g.parent_id))

    if (!gas) {
      switch(code) {
        case 'BIOCO2':
          gas = gasses.find(g => g.short_name === 'CO2' && g.type === 'biogenic' && !hasParent)
          break;
        case 'HFC-134A':
          gas = gasses.find(g => g.short_name === 'HFC-134a' && g.type === 'fossil' && hasParent)
          break;
        default:
          break;
      }
    }

    return gas?.id
  }

  const extractNaics = (datum) => {
    let datum_naics = []
    if (datum.primary_naics) datum_naics.push({ naics_id: naicsId(datum.primary_naics), facility_naics_primacy: 'primary' })
    if (datum.secondary_naics) datum_naics.push({ naics_id: naicsId(datum.secondary_naics), facility_naics_primacy: 'secondary' })
    if (datum.add_naics_code) {
      const extraNaicsCodes = datum.add_naics_code.split(",")
      extraNaicsCodes.forEach(n => datum_naics.push({ naics_id: naicsId(n), facility_naics_primacy: 'extra' }))
    }

    return datum_naics
  }

  console.log("=====")
  console.log("EMITTER FACILITY")

  const facilityData = [...envirofactsGHGEmitterFacilityData].sort((a, b) => a.year - b.year)

  facilityData.forEach(d => {
    const { datum, datumIndex } = matchDatum(data, d.facility_id)

    const datum_naics = extractNaics(d)

    let datumObject = {
      name: d.facility_name,
      facility_identifications: [{
        external_id: d.facility_id,
        id_type: 'us_epa_id'
      }],
      location: {
        longitude: d.longitude,
        latitude: d.latitude
      },
      address: {
        address1: titleCase(d.address1),
        address2: titleCase(d.address2),
        city: titleCase(d.city),
        country_subdivision_id: country_subdivisions.find(c => c.code === `US-${d.state}`)?.id,
        zip: d.zip,
        country_id: usaCountryID
      },
      facility_reports: [{
        ...defaultReport,
        year: d.year
      }]
    }

    if (datum_naics.length) datumObject.naics = datum_naics

    if (datum) {
      const annualReports = [...datum.facility_reports]
      annualReports.push({ ...defaultReport, year: d.year })

      if (lastReportingYear(datum) < d.year) {
        data[datumIndex] = {...datum, ...datumObject}
      }

      data[datumIndex] = {...data[datumIndex], facility_reports: annualReports}
    } else {

      data.push({
        ...defaultObject,
        ...datumObject
      })
    }
  })

  console.log("EMITTER GAS")

  envirofactsGHGEmitterGasData.forEach(d => {
    const { datum, datumIndex } = matchDatum(data, d.facility_id)

    if (datum) {
      const annualReports = [...datum.facility_reports]
      const { report, reportIndex } = matchReport(annualReports, d)

      if (report) {
        const quantity = parseFloat(d.co2e_emission)

        if (!isNaN(quantity)) {
          const reportGasses = [...report.facility_report_gasses]
          reportGasses.push({
            gas_id: gasId(d.gas_code),
            quantity_co2e: quantity,
            co2e_mass_unit_id: tonnesMassUnitID
          })
          report.facility_report_gasses = reportGasses

          annualReports[reportIndex] = report
          datum.facility_reports = annualReports
        }
      }
    }
  })

  console.log("PARENT COMPANY")

  const parentCompanyData = [...envirofactsParentCompanyInfoData].sort((a, b) => a.year - b.year)

  parentCompanyData.forEach(d => {
    const { datum, datumIndex } = matchDatum(data, d.facility_id)

    if (datum) {
      let parentCompanies = []

      parentCompanies.push({
        name: d.parent_company_name,
        ownership_percentage: parseFloat(d.parent_co_percent_own),
        address: {
          address1: d.parent_co_street_address,
          city: d.parent_co_city,
          country_subdivision_id: country_subdivisions.find(c => c.code === `US-${d.parent_co_state}`)?.id,
          zip: d.parent_co_zip,
          country_id: usaCountryID
        },
        data_source: { ...dataSource }
      })
      if (parentCompanies.length) {
        if (datum.parentCompaniesYear === d.year) {
          datum.parent_companies = [...datum.parent_companies, ...parentCompanies]
        } else if ((datum.parentCompaniesYear || 0) < d.year) {
          datum.parent_companies = parentCompanies
          datum.parentCompaniesYear = d.year
        }
      }
    }
  })

  data.forEach((d, i) => {
    const { parentCompaniesYear, ...keepAttrs } = d
    data[i] = { ...keepAttrs }

    if (d.parent_companies.findIndex(pc => pc.ownership_percentage !== 0 && !pc.ownership_percentage) !== -1) {
      const missingOwnershipCount = d.parent_companies.filter(pc => pc.ownership_percentage !== 0 && !pc.ownership_percentage).length
      const currentOwnership = d.parent_companies.reduce((sum, pc) => !pc.ownership_percentage ? sum : sum + pc.ownership_percentage, 0)
      const remainingOwnership = 100 - currentOwnership
      const ownershipPerMissing = remainingOwnership / missingOwnershipCount

      d.parent_companies.forEach((pc, j) => {
        if (!pc.ownership_percentage && pc.ownership_percentage !== 0) data[i].parent_companies[j].ownership_percentage = ownershipPerMissing
      })
    }
  })

  console.log("EMITTER SUBPART")

  envirofactsGHGEmitterSubpartData.forEach(d => {
    const { datum, datumIndex } = matchDatum(data, d.facility_id)

    if (datum) {
      const annualReports = [...datum.facility_reports]
      const { report, reportIndex } = matchReport(annualReports, d)

      if (report) {
        const quantity = parseFloat(d.ghg_quantity)

        if (!isNaN(quantity) && quantity > 0) {
          const gasIdentifier = gasId(d.gas_code)

          const reportGasIndex = report.facility_report_gasses.findIndex(g => g.id === gasIdentifier)

          if (reportGasIndex !== -1) {
            const currentGas = report.facility_report_gasses[reportGasIndex].quantity || 0

            report.facility_report_gasses[reportGasIndex].quantity = currentGas + quantity
            report.facility_report_gasses[reportGasIndex].mass_unit_id = tonnesMassUnitID
          }
        }

        annualReports[reportIndex] = report
        datum.facility_reports = annualReports
      }
    }
  })

  //Subparts I, L, T, F, O, SS

  //T: t_subpart_level_information
  //I: mv_ef_i_emissions_by_ghg
  //L: ef_l_gasinfo
  //SS: ss_subpart_level_information
  //F: f_subpart_level_information
  // US GAS Subpart Names
  // Other: ['I', 'L']
  // Very_Short: ['T', 'I', 'L']
  // HFE: ['I', 'L']
  // HFC: ['T', 'I', 'O', 'L']
  // PFC: ['SS', 'I', 'F', 'L']
  // US GAS Subpart Types
  // Other: ['E']
  // Very_Short: ['E']
  // HFE: ['E']
  // PFC: ['E']
  // HFC: ['E']

  console.log("SUBPARTS")
  console.log("-T")

  envirofactsTSubpartInformationData.forEach(d => {
    const { datum, datumIndex } = matchDatum(data, d.facility_id)

    if (datum) {
      const annualReports = [...datum.facility_reports]
      const { report, reportIndex } = matchReport(annualReports, d)

      if (report) {
        const quantity = parseFloat(d.ghg_quantity)

        if (!isNaN(quantity) && quantity > 0) {
          const gasIdentifier = gasId(d.ghg_name, true)

          if (gasIdentifier) {
            const reportGasIndex = report.facility_report_gasses.findIndex(g => g.id === gasIdentifier)

            if (reportGasIndex !== -1) {
              const currentGas = report.facility_report_gasses[reportGasIndex].quantity || 0

              report.facility_report_gasses[reportGasIndex].quantity = currentGas + quantity
              report.facility_report_gasses[reportGasIndex].mass_unit_id = tonnesMassUnitID
            } else {
              report.facility_report_gasses.push({
                gas_id: gasIdentifier,
                quantity,
                mass_unit_id: tonnesMassUnitID
              })
            }
          }
        }

        annualReports[reportIndex] = report
        datum.facility_reports = annualReports
      }
    }
  })

  console.log("-I")

  envirofactsISubpartInformationData.forEach(d => {
    const { datum, datumIndex } = matchDatum(data, d.facility_id)

    if (datum) {
      const annualReports = [...datum.facility_reports]
      const { report, reportIndex } = matchReport(annualReports, d)

      if (report) {
        const quantity = parseFloat(d.ghgasquantity)

        if (!isNaN(quantity) && quantity > 0) {
          let gasIdentifier = gasId(d.ghgasname, true)

          if (!gasIdentifier) gasIdentifier = gasId(d.ghgasname.split(" ")[0], true)

          if (gasIdentifier) {
            const reportGasIndex = report.facility_report_gasses.findIndex(g => g.id === gasIdentifier)

            if (reportGasIndex !== -1) {
              const currentGas = report.facility_report_gasses[reportGasIndex].quantity || 0

              report.facility_report_gasses[reportGasIndex].quantity = currentGas + quantity
              report.facility_report_gasses[reportGasIndex].mass_unit_id = tonnesMassUnitID
            } else {
              report.facility_report_gasses.push({
                gas_id: gasIdentifier,
                quantity,
                mass_unit_id: tonnesMassUnitID
              })
            }
          }
        }

        annualReports[reportIndex] = report
        datum.facility_reports = annualReports
      }
    }
  })

  console.log("-F")

  envirofactsFSubpartInformationData.forEach(d => {
    const { datum, datumIndex } = matchDatum(data, d.facility_id)

    if (datum) {
      const annualReports = [...datum.facility_reports]
      const { report, reportIndex } = matchReport(annualReports, d)

      if (report) {
        const quantity = parseFloat(d.ghg_quantity)

        if (!isNaN(quantity) && quantity > 0) {
          let gasName = d.ghg_name.split(" ")[0]

          const gasIdentifier = gasId(gasName, true)

          if (gasIdentifier) {
            const reportGasIndex = report.facility_report_gasses.findIndex(g => g.id === gasIdentifier)

            if (reportGasIndex !== -1) {
              const currentGas = report.facility_report_gasses[reportGasIndex].quantity || 0

              report.facility_report_gasses[reportGasIndex].quantity = currentGas + quantity
              report.facility_report_gasses[reportGasIndex].mass_unit_id = tonnesMassUnitID
            } else {
              report.facility_report_gasses.push({
                gas_id: gasIdentifier,
                quantity,
                mass_unit_id: tonnesMassUnitID
              })
            }
          }
        }

        annualReports[reportIndex] = report
        datum.facility_reports = annualReports
      }
    }
  })

  console.log("-SS")

  envirofactsSSSubpartInformationData.forEach(d => {
    const { datum, datumIndex } = matchDatum(data, d.facility_id)

    if (datum) {
      const annualReports = [...datum.facility_reports]
      const { report, reportIndex } = matchReport(annualReports, d)

      if (report) {
        const quantity = parseFloat(d.ghg_quantity)

        if (!isNaN(quantity) && quantity > 0) {
          let gasName = d.gas_name.split(" ")[0]

          const gasIdentifier = gasId(gasName, true)

          if (gasIdentifier) {
            const reportGasIndex = report.facility_report_gasses.findIndex(g => g.id === gasIdentifier)

            if (reportGasIndex !== -1) {
              const currentGas = report.facility_report_gasses[reportGasIndex].quantity || 0

              report.facility_report_gasses[reportGasIndex].quantity = currentGas + quantity
              report.facility_report_gasses[reportGasIndex].mass_unit_id = tonnesMassUnitID
            } else {
              report.facility_report_gasses.push({
                gas_id: gasIdentifier,
                quantity,
                mass_unit_id: tonnesMassUnitID
              })
            }
          }
        }

        annualReports[reportIndex] = report
        datum.facility_reports = annualReports
      }
    }
  })

  return data
}