import { Drawer, Button, Flex, Typography, Card, Space, Table, Tag, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from '../shared/hooks'
import FacilityNaicsList from './FacilityNaicsList'
import AddressText from './AddressText'
import DataSource from './DataSource'
import { formatFloat, formatPhoneNumber } from '../shared/utils'
import i18n from 'i18next'

const { Title, Text } = Typography

const FacilityDrawer = ({ gasses, facility, open, onClose }) => {
  const { t } = useTranslation('components.FacilityDrawer')

  const facilityReportColumns = [
    {
      title: t('facility_report.column.gas'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        let tooltipTitle = record.tooltip_name

        if (record.tooltip_cas_number) {
          tooltipTitle = tooltipTitle ? `${tooltipTitle} (${record.tooltip_cas_number})` : record.tooltip_cas_number
        }

        return <Tooltip title={tooltipTitle}>
          <Text>{text}</Text>
          { record.gas_type === 'biogenic' ? <Tag className='!ml-1' color='success'>{i18n.t(`constants.gas_type.${record.gas_type}`)}</Tag> : <></> }
        </Tooltip>
      }
    },
    {
      title: `${t('facility_report.column.quantity')} (tCO2e)`,
      dataIndex: 'quantity_co2e',
      key: 'quantity_co2e',
      render: (text) => formatFloat(text)
    },
    {
      title: `${t('facility_report.column.quantity')} (t)`,
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text) => formatFloat(text)
    }
  ]

  const facilityReportGassesMapping = (facilityReportGas, facilityReportGasses) => {
    if (!gasses) return

    const gas = gasses.find(g => g.id === facilityReportGas.gas_id)

    const returnObject = {
      id: facilityReportGas.id,
      name: gas.short_name || gas.name,
      tooltip_name: gas.short_name ? gas.name : null,
      tooltip_cas_number: gas.cas_number,
      gas_type: gas.type,
      quantity_co2e: facilityReportGas.quantity_co2e,
      quantity: facilityReportGas.quantity,
    }

    if (facilityReportGasses.length) {
      const childrenFacilityReportGasses = [...facilityReportGasses].filter(m => gasses.find(g => g.id === m.gas_id).parent_id === gas.id)

      if (childrenFacilityReportGasses.length) returnObject.children = childrenFacilityReportGasses.map(facilityReportGassesMapping)
    }

    return returnObject
  }

  return <Drawer
      title={
        <Flex vertical>
          <Flex justify="space-between">
            <Title className="m-0" level={3}>
              {facility.name}
            </Title>
            <Button type='text' size='small' onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Flex>
          <Flex justify="space-between">
            <Text type="secondary" className='font-normal'>
              <FacilityNaicsList facility_naics={facility.facility_naics} showPrimacy />
            </Text>
            <DataSource data_source={facility.data_source} textClassName='ml-auto mt-auto font-normal min-w-fit' />
          </Flex>
        </Flex>}
      placement='left'
      size='large'
      closeIcon={false}
      onClose={onClose}
      open={open}
    >
      { facility.facility_identifications.length ? facility.facility_identifications.map((n, i) => <div key={i}>
        <Text strong>{i18n.t(`constants.facility_identification_type.${n.id_type}`)}: </Text>
        <Text>{n.external_id}</Text>
      </div>)
      : <></> }
      <div>
        <Text strong>{t('coordinates')}: </Text>
        <Text>{facility.location.latitude}, {facility.location.longitude}</Text>
      </div>
      <div>
        <Text strong>{t('address')}: </Text>
        <Text><AddressText address={facility.address} /></Text>
      </div>
      { facility.parent_companies.length ? <>
        <Title className="!mb-0 mt-2" level={4}>
          Parent Companies
        </Title>
        <Space className='grow w-full' direction='vertical'>{
          [...facility.parent_companies].sort((a, b) => b.ownership_percentage - a.ownership_percentage).map((n, i) => {
            return <Card key={i} className='w-full' size='small' title={n.company.name}>
              <div>
                <Text strong>{t('parent_company.ownership')}: </Text>
                <Text>{n.ownership_percentage}%</Text>
              </div>
              { n.company.address?.address1 ? <div>
                <Text strong>{t('address')}: </Text>
                <Text><AddressText address={n.company.address} /></Text>
              </div> : <></> }
              { [...n.company.public_contacts].filter(pc => pc.name).length ? <>
                <div>
                  <Text strong underline>{t('parent_company.public_contacts')}</Text>
                </div>
                { [...n.company.public_contacts].filter(pc => pc.name).map((pc, j) => <div key={j}>
                  <Text strong>{pc.name}</Text>
                  <Text> ({pc.position})</Text>
                  { pc.contact_info.map(ci => {
                    let contactInfoText = ` -`

                    if (ci.email) contactInfoText = `${contactInfoText} ${ci.email}` 

                    if (ci.phone) {
                      contactInfoText = `${contactInfoText} (${formatPhoneNumber(ci.phone)}`

                      if (ci.extension) contactInfoText = `${contactInfoText}, ${t('parent_company.phone_extension')} ${ci.extension}`
                    }

                    contactInfoText = `${contactInfoText})`

                    return contactInfoText
                  }) }
                </div>) }
              </> : <></> }
          </Card>
          })
        }</Space>
      </>
      : <></> }
      <Title className="!mb-0 mt-2" level={4}>
        Facility Reports
      </Title>
      { facility.facility_reports.length ? <Space className='grow w-full' direction='vertical'>
        { [...facility.facility_reports].sort((a, b) => (a.year < b.year ? 1 : a.year > b.year ? -1 : 0)).map((n, i) => {
          const reportTypeTag = <Tag color='default'>{i18n.t(`constants.facility_report_type.${n.report_type}`)}</Tag>
          const facilityReportTitle = <Flex justify='space-between' className='align-middle'>
            <Text>{n.year} - {formatFloat(n.facility_report_gasses.reduce((sum, n) => { return sum + n.quantity_co2e }, 0))} tCO2e</Text>
            {reportTypeTag}
          </Flex>

          return <Card key={i} className='w-full ovwerflow !rounded-none' size='small' title={facilityReportTitle} styles={{ body: { padding: 0 }}}>
            <Table 
              rowKey='id'
              className='w-full'
              size='small'
              pagination={false}
              columns={facilityReportColumns}
              dataSource={[...n.facility_report_gasses].filter(m => gasses && !gasses.find(g => g.id === m.gas_id).parent_id).map(m => facilityReportGassesMapping(m, n.facility_report_gasses))} />
          </Card>})
        }
      </Space>
      : <></> }
    </Drawer>
}

function mapStateToProps(state) {
  const {
    gasses
  } = state
  return {
    gasses
  }
}

export default connect(mapStateToProps)(FacilityDrawer);          