import { createAction } from '@reduxjs/toolkit'

export const FETCH_USERS_REQUESTED = createAction('FETCH_USERS_REQUESTED')
export const FETCH_USERS_FAILED = createAction('FETCH_USERS_FAILED')
export const FETCH_USERS_SUCCEEDED = createAction('FETCH_USERS_SUCCEEDED')

export const CREATE_USER_ROLE_REQUESTED = createAction('CREATE_USER_ROLE_REQUESTED')
export const CREATE_USER_ROLE_FAILED = createAction('CREATE_USER_ROLE_FAILED')
export const CREATE_USER_ROLE_SUCCEEDED = createAction('CREATE_USER_ROLE_SUCCEEDED')

export const DELETE_USER_ROLE_REQUESTED = createAction('DELETE_USER_ROLE_REQUESTED')
export const DELETE_USER_ROLE_FAILED = createAction('DELETE_USER_ROLE_FAILED')
export const DELETE_USER_ROLE_SUCCEEDED = createAction('DELETE_USER_ROLE_SUCCEEDED')

export const FETCH_MASS_UNITS_REQUESTED = createAction('FETCH_MASS_UNITS_REQUESTED')
export const FETCH_MASS_UNITS_FAILED = createAction('FETCH_MASS_UNITS_FAILED')
export const FETCH_MASS_UNITS_SUCCEEDED = createAction('FETCH_MASS_UNITS_SUCCEEDED')

export const FETCH_COUNTRIES_REQUESTED = createAction('FETCH_COUNTRIES_REQUESTED')
export const FETCH_COUNTRIES_FAILED = createAction('FETCH_COUNTRIES_FAILED')
export const FETCH_COUNTRIES_SUCCEEDED = createAction('FETCH_COUNTRIES_SUCCEEDED')

export const FETCH_COUNTRY_SUBDIVISIONS_REQUESTED = createAction('FETCH_COUNTRY_SUBDIVISIONS_REQUESTED')
export const FETCH_COUNTRY_SUBDIVISIONS_FAILED = createAction('FETCH_COUNTRY_SUBDIVISIONS_FAILED')
export const FETCH_COUNTRY_SUBDIVISIONS_SUCCEEDED = createAction('FETCH_COUNTRY_SUBDIVISIONS_SUCCEEDED')

export const FETCH_NAICS_REQUESTED = createAction('FETCH_NAICS_REQUESTED')
export const FETCH_NAICS_FAILED = createAction('FETCH_NAICS_FAILED')
export const FETCH_NAICS_SUCCEEDED = createAction('FETCH_NAICS_SUCCEEDED')

export const FETCH_GASSES_REQUESTED = createAction('FETCH_GASSES_REQUESTED')
export const FETCH_GASSES_FAILED = createAction('FETCH_GASSES_FAILED')
export const FETCH_GASSES_SUCCEEDED = createAction('FETCH_GASSES_SUCCEEDED')

export const FETCH_INDUSTRY_CATEGORIES_REQUESTED = createAction('FETCH_INDUSTRY_CATEGORIES_REQUESTED')
export const FETCH_INDUSTRY_CATEGORIES_FAILED = createAction('FETCH_INDUSTRY_CATEGORIES_FAILED')
export const FETCH_INDUSTRY_CATEGORIES_SUCCEEDED = createAction('FETCH_INDUSTRY_CATEGORIES_SUCCEEDED')

export const FETCH_INDUSTRIES_REQUESTED = createAction('FETCH_INDUSTRIES_REQUESTED')
export const FETCH_INDUSTRIES_FAILED = createAction('FETCH_INDUSTRIES_FAILED')
export const FETCH_INDUSTRIES_SUCCEEDED = createAction('FETCH_INDUSTRIES_SUCCEEDED')

export const FETCH_FACILITY_REPORT_YEARS_REQUESTED = createAction('FETCH_FACILITY_REPORT_YEARS_REQUESTED')
export const FETCH_FACILITY_REPORT_YEARS_FAILED = createAction('FETCH_FACILITY_REPORT_YEARS_FAILED')
export const FETCH_FACILITY_REPORT_YEARS_SUCCEEDED = createAction('FETCH_FACILITY_REPORT_YEARS_SUCCEEDED')

export const FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_REQUESTED = createAction('FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_REQUESTED')
export const FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_FAILED = createAction('FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_FAILED')
export const FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_SUCCEEDED = createAction('FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_SUCCEEDED')

export const FETCH_MAP_DATA_REQUESTED = createAction('FETCH_MAP_DATA_REQUESTED')
export const FETCH_MAP_DATA_FAILED = createAction('FETCH_MAP_DATA_FAILED')
export const FETCH_MAP_DATA_SUCCEEDED = createAction('FETCH_MAP_DATA_SUCCEEDED')

export const FETCH_FACILITY_MAP_DATA_REQUESTED = createAction('FETCH_FACILITY_MAP_DATA_REQUESTED')
export const FETCH_FACILITY_MAP_DATA_FAILED = createAction('FETCH_FACILITY_MAP_DATA_FAILED')
export const FETCH_FACILITY_MAP_DATA_SUCCEEDED = createAction('FETCH_FACILITY_MAP_DATA_SUCCEEDED')

export const FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_REQUESTED = createAction('FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_REQUESTED')
export const FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_FAILED = createAction('FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_FAILED')
export const FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_SUCCEEDED = createAction('FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_SUCCEEDED')

export const FETCH_MAXIMUM_FACILITY_EMISSIONS_REQUESTED = createAction('FETCH_MAXIMUM_FACILITY_EMISSIONS_REQUESTED')
export const FETCH_MAXIMUM_FACILITY_EMISSIONS_FAILED = createAction('FETCH_MAXIMUM_FACILITY_EMISSIONS_FAILED')
export const FETCH_MAXIMUM_FACILITY_EMISSIONS_SUCCEEDED = createAction('FETCH_MAXIMUM_FACILITY_EMISSIONS_SUCCEEDED')

export const FETCH_FILTERED_FACILITY_IDS_REQUESTED = createAction('FETCH_FILTERED_FACILITY_IDS_REQUESTED')
export const FETCH_FILTERED_FACILITY_IDS_FAILED = createAction('FETCH_FILTERED_FACILITY_IDS_FAILED')
export const FETCH_FILTERED_FACILITY_IDS_SUCCEEDED = createAction('FETCH_FILTERED_FACILITY_IDS_SUCCEEDED')

export const UPDATE_PASSWORD_REQUESTED = createAction('UPDATE_PASSWORD_REQUESTED')
export const UPDATE_PASSWORD_FAILED = createAction('UPDATE_PASSWORD_FAILED')
export const UPDATE_PASSWORD_SUCCEEDED = createAction('UPDATE_PASSWORD_SUCCEEDED')

export const FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_REQUESTED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_REQUESTED')
export const FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_FAILED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_FAILED')
export const FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_SUCCEEDED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_SUCCEEDED')

export const FETCH_ENVIROFACTS_GHG_INJECTION_FACILITIES_REQUESTED = createAction('FETCH_ENVIROFACTS_GHG_INJECTION_FACILITIES_REQUESTED')
export const FETCH_ENVIROFACTS_GHG_INJECTION_FACILITIES_FAILED = createAction('FETCH_ENVIROFACTS_GHG_INJECTION_FACILITIES_FAILED')
export const FETCH_ENVIROFACTS_GHG_INJECTION_FACILITIES_SUCCEEDED = createAction('FETCH_ENVIROFACTS_GHG_INJECTION_FACILITIES_SUCCEEDED')

export const FETCH_ENVIROFACTS_GHG_SUPPLIER_FACILITIES_REQUESTED = createAction('FETCH_ENVIROFACTS_GHG_SUPPLIER_FACILITIES_REQUESTED')
export const FETCH_ENVIROFACTS_GHG_SUPPLIER_FACILITIES_FAILED = createAction('FETCH_ENVIROFACTS_GHG_SUPPLIER_FACILITIES_FAILED')
export const FETCH_ENVIROFACTS_GHG_SUPPLIER_FACILITIES_SUCCEEDED = createAction('FETCH_ENVIROFACTS_GHG_SUPPLIER_FACILITIES_SUCCEEDED')

export const FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_REQUESTED = createAction('FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_REQUESTED')
export const FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_FAILED = createAction('FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_FAILED')
export const FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_SUCCEEDED = createAction('FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_SUCCEEDED')

export const FETCH_ENVIROFACTS_FACILITIES_STOPPED_REPORTING_REQUESTED = createAction('FETCH_ENVIROFACTS_FACILITIES_STOPPED_REPORTING_REQUESTED')
export const FETCH_ENVIROFACTS_FACILITIES_STOPPED_REPORTING_FAILED = createAction('FETCH_ENVIROFACTS_FACILITIES_STOPPED_REPORTING_FAILED')
export const FETCH_ENVIROFACTS_FACILITIES_STOPPED_REPORTING_SUCCEEDED = createAction('FETCH_ENVIROFACTS_FACILITIES_STOPPED_REPORTING_SUCCEEDED')

export const FETCH_ENVIROFACTS_GHG_EMITTER_GAS_REQUESTED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_GAS_REQUESTED')
export const FETCH_ENVIROFACTS_GHG_EMITTER_GAS_FAILED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_GAS_FAILED')
export const FETCH_ENVIROFACTS_GHG_EMITTER_GAS_SUCCEEDED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_GAS_SUCCEEDED')

export const FETCH_ENVIROFACTS_GHG_SUPPLIER_GAS_REQUESTED = createAction('FETCH_ENVIROFACTS_GHG_SUPPLIER_GAS_REQUESTED')
export const FETCH_ENVIROFACTS_GHG_SUPPLIER_GAS_FAILED = createAction('FETCH_ENVIROFACTS_GHG_SUPPLIER_GAS_FAILED')
export const FETCH_ENVIROFACTS_GHG_SUPPLIER_GAS_SUCCEEDED = createAction('FETCH_ENVIROFACTS_GHG_SUPPLIER_GAS_SUCCEEDED')

export const FETCH_ENVIROFACTS_GHG_EMITTER_SECTOR_REQUESTED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_SECTOR_REQUESTED')
export const FETCH_ENVIROFACTS_GHG_EMITTER_SECTOR_FAILED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_SECTOR_FAILED')
export const FETCH_ENVIROFACTS_GHG_EMITTER_SECTOR_SUCCEEDED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_SECTOR_SUCCEEDED')

export const FETCH_ENVIROFACTS_GHG_SUPPLIER_SECTOR_REQUESTED = createAction('FETCH_ENVIROFACTS_GHG_SUPPLIER_SECTOR_REQUESTED')
export const FETCH_ENVIROFACTS_GHG_SUPPLIER_SECTOR_FAILED = createAction('FETCH_ENVIROFACTS_GHG_SUPPLIER_SECTOR_FAILED')
export const FETCH_ENVIROFACTS_GHG_SUPPLIER_SECTOR_SUCCEEDED = createAction('FETCH_ENVIROFACTS_GHG_SUPPLIER_SECTOR_SUCCEEDED')

export const FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_REQUESTED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_REQUESTED')
export const FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_FAILED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_FAILED')
export const FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_SUCCEEDED = createAction('FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_SUCCEEDED')

export const FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_REQUESTED = createAction('FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_REQUESTED')
export const FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_FAILED = createAction('FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_FAILED')
export const FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_SUCCEEDED = createAction('FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_SUCCEEDED')

export const FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_REQUESTED = createAction('FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_REQUESTED')
export const FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_FAILED = createAction('FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_FAILED')
export const FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_SUCCEEDED = createAction('FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_SUCCEEDED')

export const FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_REQUESTED = createAction('FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_REQUESTED')
export const FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_FAILED = createAction('FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_FAILED')
export const FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_SUCCEEDED = createAction('FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_SUCCEEDED')

export const FETCH_ENVIROFACTS_L_SUBPART_INFORMATION_REQUESTED = createAction('FETCH_ENVIROFACTS_L_SUBPART_INFORMATION_REQUESTED')
export const FETCH_ENVIROFACTS_L_SUBPART_INFORMATION_FAILED = createAction('FETCH_ENVIROFACTS_L_SUBPART_INFORMATION_FAILED')
export const FETCH_ENVIROFACTS_L_SUBPART_INFORMATION_SUCCEEDED = createAction('FETCH_ENVIROFACTS_L_SUBPART_INFORMATION_SUCCEEDED')

export const FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_REQUESTED = createAction('FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_REQUESTED')
export const FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_FAILED = createAction('FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_FAILED')
export const FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_SUCCEEDED = createAction('FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_SUCCEEDED')

export const BULK_INSERT_FACILITY_DATA_REQUESTED = createAction('BULK_INSERT_FACILITY_DATA_REQUESTED')
export const BULK_INSERT_FACILITY_DATA_FAILED = createAction('BULK_INSERT_FACILITY_DATA_FAILED')
export const BULK_INSERT_FACILITY_DATA_SUCCEEDED = createAction('BULK_INSERT_FACILITY_DATA_SUCCEEDED')

export const FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_REQUESTED = createAction('FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_REQUESTED')
export const FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_FAILED = createAction('FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_FAILED')
export const FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_SUCCEEDED = createAction('FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_SUCCEEDED')

export const CREATE_SEQUESTRATION_WELL_PROJECT_REQUESTED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_REQUESTED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_FAILED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_FAILED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_SUCCEEDED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_SUCCEEDED')

export const UPDATE_SEQUESTRATION_WELL_PROJECT_REQUESTED = createAction('UPDATE_SEQUESTRATION_WELL_PROJECT_REQUESTED')
export const UPDATE_SEQUESTRATION_WELL_PROJECT_FAILED = createAction('UPDATE_SEQUESTRATION_WELL_PROJECT_FAILED')
export const UPDATE_SEQUESTRATION_WELL_PROJECT_SUCCEEDED = createAction('UPDATE_SEQUESTRATION_WELL_PROJECT_SUCCEEDED')

export const FETCH_SEQUESTRATION_WELL_PROJECTS_REQUESTED = createAction('FETCH_SEQUESTRATION_WELL_PROJECTS_REQUESTED')
export const FETCH_SEQUESTRATION_WELL_PROJECTS_FAILED = createAction('FETCH_SEQUESTRATION_WELL_PROJECTS_FAILED')
export const FETCH_SEQUESTRATION_WELL_PROJECTS_SUCCEEDED = createAction('FETCH_SEQUESTRATION_WELL_PROJECTS_SUCCEEDED')

export const FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_REQUESTED = createAction('FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_REQUESTED')
export const FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_FAILED = createAction('FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_FAILED')
export const FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_SUCCEEDED = createAction('FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_SUCCEEDED')

export const CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_REQUESTED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_REQUESTED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_FAILED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_FAILED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_SUCCEEDED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_SUCCEEDED')

export const DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_REQUESTED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_REQUESTED')
export const DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_FAILED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_FAILED')
export const DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_SUCCEEDED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_SUCCEEDED')

export const CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_REQUESTED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_REQUESTED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_FAILED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_FAILED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_SUCCEEDED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_SUCCEEDED')

export const DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_REQUESTED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_REQUESTED')
export const DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_FAILED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_FAILED')
export const DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_SUCCEEDED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_SUCCEEDED')

export const CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_REQUESTED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_REQUESTED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_FAILED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_FAILED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_SUCCEEDED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_SUCCEEDED')

export const UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_REQUESTED = createAction('UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_REQUESTED')
export const UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_FAILED = createAction('UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_FAILED')
export const UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_SUCCEEDED = createAction('UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_SUCCEEDED')

export const FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_REQUESTED = createAction('FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_REQUESTED')
export const FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_FAILED = createAction('FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_FAILED')
export const FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_SUCCEEDED = createAction('FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_SUCCEEDED')

export const CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED')

export const REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED = createAction('REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED')
export const REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED = createAction('REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED')
export const REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED = createAction('REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED')

export const ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED = createAction('ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED')
export const ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED = createAction('ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED')
export const ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED = createAction('ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED')

export const CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED')

export const DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED = createAction('DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED')
export const DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED = createAction('DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED')
export const DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED = createAction('DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED')

export const DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED')
export const DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED')
export const DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED')

export const CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_REQUESTED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_REQUESTED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_FAILED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_FAILED')
export const CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_SUCCEEDED = createAction('CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_SUCCEEDED')

export const DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_REQUESTED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_REQUESTED')
export const DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_FAILED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_FAILED')
export const DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_SUCCEEDED = createAction('DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_SUCCEEDED')

export const PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_REQUESTED = createAction('PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_REQUESTED')
export const PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_FAILED = createAction('PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_FAILED')
export const PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_SUCCEEDED = createAction('PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_SUCCEEDED')

export const UPDATE_COMPANY_REQUESTED = createAction('UPDATE_COMPANY_REQUESTED')
export const UPDATE_COMPANY_FAILED = createAction('UPDATE_COMPANY_FAILED')
export const UPDATE_COMPANY_SUCCEEDED = createAction('UPDATE_COMPANY_SUCCEEDED')