import React, { useEffect } from 'react';
import './App.css';
import { connect } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { StyleProvider } from '@ant-design/cssinjs'
import { ErrorBoundary } from 'react-error-boundary'
import { logError } from './shared/utils'
import { ConfigProvider, theme, notification } from 'antd'
import { ErrorFallback } from './shared/components'
import { initializeTimeZoneAndDataGrid } from './shared/initializer'
import UnauthorizedLayout from './layouts/Unauthorized'
import Landing from './pages/Landing'
import Login from './pages/Login'
import AuthorizedLayout from './layouts/Authorized'
import Home from './pages/Home'
import Account from './pages/Account'
import Admin from './pages/Admin'
import { PUBLIC_PAGES, SUPABASE } from './shared/constants'
import { SET_SESSION, SET_USER_ROLE, CLEAR_MESSAGE } from './shared/redux/actions'
import { useJwt } from 'react-jwt'

initializeTimeZoneAndDataGrid()

function App({ session, message, setSession, setUserRole, clearMessage }) {
  const [notificationApi, renderNotification] = notification.useNotification()

  useEffect(() => {
    SUPABASE.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = SUPABASE.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [setSession])

  const { decodedToken, isExpired } = useJwt(session?.access_token);

  useEffect(() => {
    setUserRole(decodedToken?.user_role)
  }, [session, decodedToken?.user_role, setUserRole])

  const loggedOutNeedsRedirect = !session && session !== 0 && !PUBLIC_PAGES.includes(window.location.pathname);

  // Show notification if there is a message
  useEffect(() => {
    if (!message.type) return
    notificationApi[message.type]({
      message: message.title,
      description: message.description,
      placement: 'bottomRight',
      onClose: clearMessage,
      duration: message.type === 'error' ? 0 : 5,
    })
  }, [message.title, message.description, message.type])

  return(
      <BrowserRouter>
          <StyleProvider hashPriority="high">
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
              <ConfigProvider
                theme={{
                  token: { ...theme.defaultAlgorithm, ...{
                    fontFamily: 'Inter, sans-serif'
                  }},
                  components: {
                    Layout: {
                      headerBg: '#08214B'
                    },
                    Menu: {
                      darkItemBg: '#08214B',
                      darkItemSelectedBg: '#F2F4F733',
                      itemBg: 'transparent',
                      itemColor: '#98A2B3',
                      horizontalItemSelectedColor: '#ffffff',
                      itemHoverColor: '#cccccc',
                      collapsedWidth: 48
                    },
                    Typography: {
                      colorTextDescription: '#475467'
                    }
                  }
                }}
              >
                {renderNotification}
                <Routes>
                  <Route element={<AuthorizedLayout />} >
                    <Route path="/home" element={<Home />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/admin" element={<Admin />} />
                  </Route>
                  <Route element={<UnauthorizedLayout />} >
                    <Route path="/" element={<Landing />} />
                  </Route>
                  <Route path="/login" element={<Login />} />
                </Routes>
                { loggedOutNeedsRedirect && <Navigate to="/login" /> }
              </ConfigProvider>
            </ErrorBoundary>
          </StyleProvider>
      </BrowserRouter>
  )
}

function mapStateToProps(state) {
  const { session, message } = state
  return {
    session,
    message
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setSession: (session) => dispatch(SET_SESSION(session)),
    setUserRole: (user_role) => dispatch(SET_USER_ROLE(user_role)),
    clearMessage: () => dispatch(CLEAR_MESSAGE())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
