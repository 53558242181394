import React from 'react'
import { Typography, Button } from 'antd'

const { Title } = Typography

export default () => (
  <div className="w-full h-full flex flex-col items-center justify-center">
    <Title level={3}>There's been an unexpected error. Our team has been notified.</Title>
    <Button className="px-1" type="link" onClick={() => (window.location = '/nexus')}>
      Take me home.
    </Button>
  </div>
)