const matchDatum = (data, facility_id) => {
  const check = d => d.facility_identifications.findIndex(n => n.external_id === facility_id && n.id_type === 'can_ghgrp_id') !== -1

  return { datum: data.find(check), datumIndex: data.findIndex(check) }
}

const lastReportingYear = (datum) => {
  return datum.facility_reports.reduce((highest, n) => {
    return highest >= n.year ? highest : n.year
  }, 0)
}

export const processCanGHGRPData = async (
  mass_units,
  countries,
  country_subdivisions,
  naics,
  gasses,
  rawData,
  publishDate
) => {
  const nowUTC = new Date().toISOString().slice(0, 19).replace('T', ' ')

  const dataSource = {
    source: 'can_ghgrp',
    currency_date: publishDate.replace('T', ' '),
    access_date: nowUTC
  }

  const defaultObject = {
    data_source: { ...dataSource }
  }

  const defaultReport = {
    year: null,
    report_type: 'emission',
    data_source: { ...dataSource }
  }

  const canadaCountryID = countries.find(c => c.iso_code_3 === 'CAN').id
  const tonnesMassUnitID = mass_units.find(m => m.name === 'Tonnes (Metric Tons)').id

  const duplicate_naics = []
  const naics_codes = naics.map(n => n.code)

  const naicsId = (code) => {
    let c = code
    let item = null
    let count = 0
    while (!item && c.length) {
      item = naics.find(n => n.code === parseInt(c))
      c = c.substring(0, c.length - 1);
      count++
    }

    return item?.id
  }

  const gasId = (code, hasParent = false) => {
    let gas = gasses.find(g => g.short_name === code && g.type === 'fossil' && (!hasParent || g.parent_id))

    if (!gas) gas = gasses.find(g => g.name === code && g.type === 'fossil' && (!hasParent || g.parent_id))

    if (!gas) {
      switch(code) {
        default:
          break;
      }
    }

    return gas?.id
  }

  const gasLookup = {
    "C2F6": gasses.find(g => g.short_name === 'PFC-116' && g.type === 'fossil' && g.parent_id),
    "C3F8": gasses.find(g => g.short_name === 'PFC-218' && g.type === 'fossil' && g.parent_id),
    "C4F8": gasses.find(g => g.name === 'Perfluorocyclobutane' && g.type === 'fossil' && g.parent_id),
    "C4F10": gasses.find(g => g.short_name === 'PFC-3-1-10' && g.type === 'fossil' && g.parent_id),
    "C5F12": gasses.find(g => g.short_name === 'PFC-4-1-12' && g.type === 'fossil' && g.parent_id),
    "C6F14": gasses.find(g => g.short_name === 'PFC-5-1-14' && g.type === 'fossil' && g.parent_id),
    "CF4": gasses.find(g => g.short_name === 'PFC-14' && g.type === 'fossil' && g.parent_id),
    "CH4": gasses.find(g => g.short_name === 'CH4' && g.type === 'fossil' && !g.parent_id),
    "CO2": gasses.find(g => g.short_name === 'CO2' && g.type === 'fossil' && !g.parent_id),
    "HFC": gasses.find(g => g.short_name === 'HFC' && g.type === 'fossil' && !g.parent_id),
    "HFC-23": gasses.find(g => g.short_name === 'HFC-23' && g.type === 'fossil' && g.parent_id),
    "HFC-32": gasses.find(g => g.short_name === 'HFC-32' && g.type === 'fossil' && g.parent_id),
    "HFC-41": gasses.find(g => g.short_name === 'HFC-41' && g.type === 'fossil' && g.parent_id),
    "HFC-43-10mee": gasses.find(g => g.short_name === 'HFC-43-10mee' && g.type === 'fossil' && g.parent_id),
    "HFC-125": gasses.find(g => g.short_name === 'HFC-125' && g.type === 'fossil' && g.parent_id),
    "HFC-134": gasses.find(g => g.short_name === 'HFC-134' && g.type === 'fossil' && g.parent_id),
    "HFC-134a": gasses.find(g => g.short_name === 'HFC-134a' && g.type === 'fossil' && g.parent_id),
    "HFC-143": gasses.find(g => g.short_name === 'HFC-143' && g.type === 'fossil' && g.parent_id),
    "HFC-143a": gasses.find(g => g.short_name === 'HFC-143a' && g.type === 'fossil' && g.parent_id),
    "HFC-152a": gasses.find(g => g.short_name === 'HFC-152a' && g.type === 'fossil' && g.parent_id),
    "HFC-227ea": gasses.find(g => g.short_name === 'HFC-227ea' && g.type === 'fossil' && g.parent_id),
    "HFC-236fa": gasses.find(g => g.short_name === 'HFC-236fa' && g.type === 'fossil' && g.parent_id),
    "HFC-245ca": gasses.find(g => g.short_name === 'HFC-245ca' && g.type === 'fossil' && g.parent_id),
    "N2O": gasses.find(g => g.short_name === 'N2O' && g.type === 'fossil' && !g.parent_id),
    "PFC": gasses.find(g => g.short_name === 'PFC' && g.type === 'fossil' && !g.parent_id),
    "SF6": gasses.find(g => g.short_name === 'SF6' && g.type === 'fossil' && !g.parent_id)
  }

  const data = []

  rawData.forEach(r => {
    const ghgrpIDParam = "GHGRP ID No. / No d'identification du PDGES"
    const yearParam = "Reference Year / Année de référence"

    const facility_report = {
      ...defaultReport,
      year: r[yearParam]
    }

    const facility_report_gasses = []

    Object.keys(gasLookup).forEach(g => {
      let quantity = 0
      let quantity_co2e = 0

      if (["CO2", "HFC", "PFC"].findIndex(n => n === g) === -1) {
        quantity = parseFloat(r[`${g} (tonnes)`])
        quantity_co2e = parseFloat(r[`${g} (tonnes CO2e / tonnes éq. CO2)`])
      } else if (["HFC", "PFC"].findIndex(n => n === g) !== -1) {
        quantity_co2e = parseFloat(r[`${g} Total (tonnes CO2e / tonnes éq. CO2)`])
      } else if (g === "CO2") {
        quantity = parseFloat(r[`${g} (tonnes)`])
        quantity_co2e = parseFloat(r[`${g} (tonnes)`])
      }

      if (quantity || quantity_co2e) {
        const facility_report_gas = {
          gas_id: gasLookup[g].id
        }

        if (quantity) {
          facility_report_gas.quantity = quantity
          facility_report_gas.mass_unit_id = tonnesMassUnitID
        }

        if (quantity_co2e) {
          facility_report_gas.quantity_co2e = quantity_co2e
          facility_report_gas.co2e_mass_unit_id = tonnesMassUnitID
        }

        facility_report_gasses.push(facility_report_gas)
      }
    })

    facility_report.facility_report_gasses = facility_report_gasses

    const { datum, datumIndex } = matchDatum(data, r[ghgrpIDParam])

    const latitudePath = "Latitude"
    const longitudePath = "Longitude"
    const facilityNamePath = "Facility Name / Nom de l'installation"
    const facilityAddressPath = "Facility Location / Emplacement de l'installation"
    const facilityCityPath = "Facility City or District or Municipality / Ville ou District ou Municipalité de l'installation"
    const facilityZipPath = "Facility Postal Code / Code postal de l'installation"
    const facilityProvincePath = "Facility Province or Territory / Province ou territoire de l'installation"
    const naicsCodePath = "Facility NAICS Code / Code SCIAN de l'installation"
    const parentCompanyNamePath = "Reporting Company Legal Name / Dénomination sociale de la société déclarante"

    const publicContactNamePath = "Public Contact Name / Nom du responsable des renseignements au public"
    const publicContactPositionPath = "Public Contact Position / Poste ou Titre du responsable des renseignements au public"
    const publicContactPhonePath = "Public Contact Telephone / Numéro de téléphone du responsable des renseignements au public"
    const publicContactExtensionPath = "Public Contact Extension / Poste téléphonique du responsable des renseignements au public"
    const publicContactEmailPath = "Public Contact Email / Adresse électronique du responsable des renseignements au public"

    const facilityAddress = {
      address1: r[facilityAddressPath],
      city: r[facilityCityPath],
      country_id: canadaCountryID,
      zip: r[facilityZipPath]
    }

    const country_subdivision_id = country_subdivisions.find(n => n.country_id === canadaCountryID && n.name === r[facilityProvincePath])?.id
    if (country_subdivision_id) facilityAddress.country_subdivision_id = country_subdivision_id

    const publicContact = {
      name: r[publicContactNamePath],
      position: r[publicContactPositionPath],
      contact_info: {}
    }

    if (r[publicContactPhonePath] && r[publicContactPhonePath] !== '') publicContact.contact_info.phone = r[publicContactPhonePath]
    if (r[publicContactExtensionPath] && r[publicContactExtensionPath] !== '') publicContact.contact_info.extension = r[publicContactExtensionPath]
    if (r[publicContactEmailPath] && r[publicContactEmailPath] !== '') publicContact.contact_info.email = r[publicContactEmailPath]

    const parentCompany = {
      name: r[parentCompanyNamePath],
      ownership_percentage: 100,
      data_source: { ...dataSource }
    }

    if (r[publicContactNamePath] !== '') parentCompany.public_contact = publicContact

    let newObject = {
      ...defaultObject,
      name: r[facilityNamePath],
      facility_identifications: [{
        id_type: 'can_ghgrp_id',
        external_id: r[ghgrpIDParam].trim()
      }],
      location: {
        longitude: parseFloat(r[longitudePath]),
        latitude: parseFloat(r[latitudePath])
      },
      address: facilityAddress,
      parent_companies: [parentCompany],
      naics: [{
        naics_id: naicsId(r[naicsCodePath])
      }],
      facility_reports: [facility_report]
    }

    const npriIDParam = "Facility NPRI ID / Numéro d'identification de l'INRP"

    if (r[npriIDParam] !== '0' && r[npriIDParam]) newObject.facility_identifications.push({
        id_type: 'can_npri_id',
        external_id: r[npriIDParam].trim()
      })

    if (datum) {
      const annualReports = [...datum.facility_reports]
      annualReports.push(facility_report)

      if (lastReportingYear(datum) < r[yearParam]) {
        data[datumIndex] = {...datum, ...newObject}
      }

      data[datumIndex] = {...data[datumIndex], facility_reports: annualReports}
    } else {
      data.push({
        ...defaultObject,
        ...newObject
      })
    }
  })

  return data
}