import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Typography, Space, Row, Col, Statistic, Alert, Divider, theme, Button, Flex, Switch, Tooltip } from 'antd'
import { useTranslation } from '../shared/hooks'
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { formatInteger } from '../shared/utils'
import { PieChart, Pie, Legend, Tooltip as RechartsTooltip, ResponsiveContainer, Cell, CartesianGrid, BarChart, XAxis, Bar } from 'recharts';
import { CHART_COLORS, MOBILE_SCREEN_WIDTH, LAYER_COLORS, LAYER_ICONS } from '../shared/constants'
import GetProBadge from './GetProBadge'
import FacilityDrawer from './FacilityDrawer'
import FacilityNaicsList from './FacilityNaicsList'
import DataSource from './DataSource'
import OverviewDrawer from './OverviewDrawer'
import OverviewSubheading from './OverviewSubheading'
import {
  FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_REQUESTED
} from '../redux/actions'
import IconLabel from './IconLabel'

const { Title, Text } = Typography

const animationDuration = 1000

const PRO = false

const FacilityOverview = ({
    fetchNaicsAverageAnnualBiogenicEmissionsPercentage,
    mass_units,
    countries,
    country_subdivisions,
    naics,
    gasses,
    facility,
    naicsAverageAnnualBiogenicPercentages,
    isLoadingFor
  }) => {
  const { t } = useTranslation('components.FacilityOverview')
  const [latestEmissions, setLatestEmissions] = useState(0)
  const [latestReport, setLatestReport] = useState(0)
  const [pieChartData, setPieChartData] = useState([])
  const [barChartData, setBarChartData] = useState([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [useBiogenicEstimates, setUseBiogenicEstimates] = useState(false)
  const [biogenicEstimatesActive, setBiogenicEstimatesActive] = useState(false)
  const [sourceDoesntReportBiogenic, setSourceDoesntReportBiogenic] = useState(false)

  useEffect(() => {
    if (sourceDoesntReportBiogenic !== (facility?.data_source?.source === 'can_ghgrp')) setSourceDoesntReportBiogenic(facility?.data_source?.source === 'can_ghgrp')
  }, [facility?.data_source?.source, sourceDoesntReportBiogenic])

  useEffect(() => {
    if (biogenicEstimatesActive !== (sourceDoesntReportBiogenic && useBiogenicEstimates)) setBiogenicEstimatesActive(sourceDoesntReportBiogenic && useBiogenicEstimates)
  }, [sourceDoesntReportBiogenic, useBiogenicEstimates, biogenicEstimatesActive])

  function handleWindowSizeChange() {
      setScreenWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = screenWidth <= MOBILE_SCREEN_WIDTH;

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const {
    token: { 
      Typography: {
        colorTextDescription
      }
    }
  } = theme.useToken();

  const facilityNAICS = facility.facility_naics || []

  const naicsMissingAverage = () => facilityNAICS.filter(fn => !naicsAverageAnnualBiogenicPercentages[fn.naics_id] && naicsAverageAnnualBiogenicPercentages[fn.naics_id] !== 0).map(fn => fn.naics_id)

  useEffect(() => {
    if (sourceDoesntReportBiogenic && !isLoadingFor.FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_REQUESTED) {
      naicsMissingAverage().forEach(n => fetchNaicsAverageAnnualBiogenicEmissionsPercentage(n))
    }
  })

  const averageNAICSBiogenicEmissionsPercentage = (y) => {
    if (naicsMissingAverage().length) {
      return 0
    } else {
      const naicsAverages = [...facilityNAICS].filter(fn => {
        return naicsAverageAnnualBiogenicPercentages[fn.naics_id].findIndex(bp => bp.year === y) !== -1
      }).map(fn => naicsAverageAnnualBiogenicPercentages[fn.naics_id].find(bp => bp.year === y).biogenic_percentage)
      return naicsAverages.length ? naicsAverages.reduce((sum, n) => sum + n, 0)/naicsAverages.length : 0
    }
  }

  useEffect(() => {
    if (facility?.mapSummaryLoaded) {
      const emissionsByGasType = (fr, gas_type) => (fr.facility_report_gasses || []).reduce((sum, frg) => sum + ((!frg.parent && gasses.find(g => g.id === frg.gas_id).type === gas_type) ? frg.quantity_co2e : 0), 0)
      const calculateFossilEmissions = (fr) => emissionsByGasType(fr, 'fossil')
      const calculateBiogenicEmissions = (fr) => {
        if (biogenicEstimatesActive) {
          return emissionsByGasType(fr, 'fossil') * (averageNAICSBiogenicEmissionsPercentage(fr.year) / (100 - averageNAICSBiogenicEmissionsPercentage(fr.year)))
        } else {
          return emissionsByGasType(fr, 'biogenic')
        }
      }

      const latestReport = facility.facility_reports.reduce((latest, fr) => latest?.year > fr.year ? latest : fr, null)

      const latestFossilEmissions = calculateFossilEmissions(latestReport)

      let latestBiogenicEmissions = calculateBiogenicEmissions(latestReport)

      setLatestEmissions(latestFossilEmissions + latestBiogenicEmissions)

      setLatestReport(latestReport)

      setPieChartData([
        { name: t('label.fossil'), value: latestFossilEmissions },
        { name: t('label.biogenic'), value: latestBiogenicEmissions }
      ])

      setBarChartData([...facility.facility_reports].sort((a, b) => a.year > b.year ? 1 : a.year < b.year ? -1 : 0).map(fr => ({
        name: fr.year,
        fossil: calculateFossilEmissions(fr),
        biogenic: calculateBiogenicEmissions(fr)
      })))
    }
  }, [facility, gasses, t, biogenicEstimatesActive])

  const loading = !facility?.mapSummaryLoaded

  return <OverviewDrawer 
    loading={loading}
    header={loading ? <></> : <>
      <IconLabel title={t('badge_title')} icon_name={LAYER_ICONS.emitters.icon_name} icon_type={LAYER_ICONS.emitters.icon_type} color={LAYER_COLORS.emitters} />
      <Title className='!text-white !my-0' level={3}>{facility.name}</Title>
      { facility.parent_companies.length ?
          <Text className='!text-white' >{ [...facility.parent_companies].sort((a, b) => b.ownership_percentage - a.ownership_percentage).map((n, i) => {
            return `${i !== 0 ? ' / ' : ''}${n.company.name} (${n.ownership_percentage}%)`
          }) }</Text>
        : <></> }
      { facility.facility_naics.length ? <div className='align-middle'>
        <Text className='!text-white'>{t('label.industry')}: </Text>
        <FacilityNaicsList className='!text-white' tooltipColor='#FFFFFFEE' tooltipTextColor='black' facility_naics={facility.facility_naics} />
      </div>
      : <></> }
      { PRO 
        ? <>
          <Button type='primary' onClick={showDrawer}>{t('label.view_facility')} <ArrowRightOutlined /></Button>
          <FacilityDrawer facility={facility} open={openDrawer} onClose={closeDrawer} />
        </>
        : <></> }
    </>}
    body={loading ? <></> : <>
      <OverviewSubheading text={t('label.emissions').toUpperCase()} className='p-4' />
      <Row gutter={[12, 0]} className='!mx-2'>
        <Col span={12}>
          <div className='shadow border p-4 h-full'>
            <Statistic title={biogenicEstimatesActive ? t('label.annual_emissions_latest_estimated') : t('label.annual_emissions_latest')} value={Math.round(latestEmissions)} formatter={formatInteger} />
            <Text className='!mt-0' type='secondary'>{t('label.latest_report')}: {latestReport.year}</Text>
          </div>
        </Col>
        <Col span={12}>
          <div className='w-full h-full shadow border'>
            <ResponsiveContainer height="100%">
              <PieChart width={200} height={200}>
                <Pie
                  dataKey="value"
                  data={pieChartData}
                  innerRadius={30}
                  outerRadius={50}
                  animationDuration={animationDuration}
                  animationBegin={0}
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
                  ))}
                </Pie>
                { !isMobile ? <Legend align="right" verticalAlign="middle" layout="vertical" iconType='circle' iconSize="8"/> : <></> }
                <RechartsTooltip separator=': ' formatter={(value, name, props) => formatInteger(Math.round(value)) + " tCO2e"} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Col>
      </Row>
      <div className='p-4'>
        <Text type='secondary' strong>{t('label.past_emissions')}</Text>
        <div className='h-36'>
          <ResponsiveContainer height="100%">
            <BarChart
              data={barChartData}
            >
              <XAxis dataKey="name" hide />
              <CartesianGrid vertical={false} />
              <RechartsTooltip formatter={(value, name, props) => [formatInteger(Math.round(value)) + " tCO2e", t(`label.${name}`)]}  itemStyle={{ padding: 0 }}  />
              <Bar dataKey="fossil" stackId="a" fill={CHART_COLORS[0]} animationDuration={animationDuration} />
              <Bar dataKey="biogenic" stackId="a" fill={CHART_COLORS[1]} animationDuration={animationDuration} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <Flex justify='space-between'>
          <DataSource data_source={facility.data_source} textClassName='!text-xs' />
          { sourceDoesntReportBiogenic ? <div className='align-middle'>
            <Text className='!text-xs mr-1'>{t('label.biogenic_estimates')}</Text>
              <Tooltip title={<div>
                <div className='font-bold'>{t('biogenic_emissions_estimate.title')}</div>
                <div>{t('biogenic_emissions_estimate.description')}</div>
              </div>}><InfoCircleOutlined className='!text-xs mr-1'/></Tooltip>
            <Switch
              value={useBiogenicEstimates}
              onChange={(value) => setUseBiogenicEstimates(value)}
              size='small'
              loading={isLoadingFor.FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_REQUESTED} />
          </div> : <></> }
        </Flex>
        <Divider />
        <Alert
          className='!p-4'
          type='success'
          message={<Text strong>{t('get_pro.title')}:</Text>}
          action={<GetProBadge className='ml-auto' />}
          description={<ul className='list-disc pl-6' style={{ color: colorTextDescription }}>
            <li> 
              <Text type='secondary'>{t('get_pro.bullet_1')}</Text>
            </li>
            <li> 
              <Text type='secondary'>{t('get_pro.bullet_2')}</Text>
            </li>
            <li> 
              <Text type='secondary'>{t('get_pro.bullet_3')}</Text>
            </li>
          </ul>} />
      </div>
    </>} />
}

function mapStateToProps(state) {
  const {
    mass_units,
    countries,
    country_subdivisions,
    naics,
    gasses,
    naicsAverageAnnualBiogenicPercentages,
    isLoadingFor
  } = state
  return {
    mass_units,
    countries,
    country_subdivisions,
    naics,
    gasses,
    naicsAverageAnnualBiogenicPercentages,
    isLoadingFor
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchNaicsAverageAnnualBiogenicEmissionsPercentage: (naics_id) => dispatch(FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_REQUESTED(naics_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacilityOverview);