import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation, useForm } from '../shared/hooks'
import { Typography,
  Flex,
  Button,
  Drawer,
  Input,
  DatePicker,
  Select,
  Space,
  Tooltip,
  Descriptions,
  Table,
  Popconfirm,
  Upload
} from 'antd'
import { CloseOutlined,
  InfoCircleOutlined,
  EditOutlined,
  PlusSquareOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  StopOutlined,
  EyeOutlined,
  UploadOutlined
} from '@ant-design/icons'
import {
  SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_TYPES,
  SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_PHASES,
  SEQUESTRATION_WELL_PROJECT_DOCUMENT_PURPOSES,
  SEQUESTRATION_WELL_PROJECT_MRV_PLAN_STATUSES
} from '../shared/constants'
import i18n from 'i18next'
import {
  FETCH_SEQUESTRATION_WELL_PROJECTS_REQUESTED,
  CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_REQUESTED,
  DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_REQUESTED,
  CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_REQUESTED,
  DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_REQUESTED,
  CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_REQUESTED,
  UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_REQUESTED,
  FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_REQUESTED,
  CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED,
  REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED,
  ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED,
  CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED,
  DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED,
  DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED,
  CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_REQUESTED,
  DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_REQUESTED,
  PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_REQUESTED
} from '../redux/actions'
import { formatDateNoTimezone, isValidURL } from '../shared/utils'
import AddressText from './AddressText'
import SequestrationWellFormDrawer from './SequestrationWellFormDrawer'
import CompanyAdminInfoDrawer from './CompanyAdminInfoDrawer'
import dayjs from 'dayjs'

const { Title, Text, Link } = Typography

const tableTextIdEllipsisRender = (text) => <Tooltip title={text}><Text>{text.slice(0, 6)}...</Text></Tooltip>

const SequestrationWellProjectAdminInfoDrawer = ({
  sequestrationWellProjectViewDocument,
  sequestrationWellProjectCompanies,
  isLoadingFor,
  fetchSequestrationWellProjects,
  fetchSequestrationWellProjectCompanies,
  createSequestrationWellProjectExternalId,
  deleteSequestrationWellProjectExternalId,
  createSequestrationWellProjectSequestrationWell,
  deleteSequestrationWellProjectSequestrationWell,
  createSequestrationWellProjectProjectDeveloper,
  updateSequestrationWellProjectProjectDeveloperId,
  createSequestrationWellProjectPartner,
  removeSequestrationWellProjectPartner,
  addSequestrationWellProjectPartner,
  createSequestrationWellProjectDocument,
  downloadSequestrationWellProjectDocument,
  deleteSequestrationWellProjectDocument,
  createSequestrationWellProjectMRVPlan,
  deleteSequestrationWellProjectMRVPlan,
  patchSequestrationWellProjectShowOnMap,
  onClose,
  open,
  sequestrationWellProject,
  setCompanyId
}) => {
  const [formDrawerOpen, setFormDrawerOpen] = useState(false)
  const [formSequestrationWellProject, setFormSequestrationWellProject] = useState(null)
  const [addExternalIDState, setAddExternalIDState] = useState(false)
  const { form: externalIdForm, setForm: setExternalIdForm, handleChange: handleChangeExternalId } = useForm({})
  const [addSequestrationWellState, setAddSequestrationWellState] = useState(false)
  const { form: sequestrationWellForm, setForm: setSequestrationWellForm, handleChange: handleChangeSequestrationWell } = useForm({})
  const [addProjectDeveloperState, setAddProjectDeveloperState] = useState(false)
  const { form: projectDeveloperForm, setForm: setProjectDeveloperForm, handleChange: handleChangeProjectDeveloper } = useForm({})
  const [projectDeveloperSelectValue, setProjectDeveloperSelectValue] = useState(null)
  const [addPartnerState, setAddPartnerState] = useState(false)
  const { form: partnerForm, setForm: setPartnerForm, handleChange: handleChangePartner } = useForm({})
  const [partnerSelectValue, setPartnerSelectValue] = useState(null)
  const [addDocumentState, setAddDocumentState] = useState(false)
  const { form: documentForm, setForm: setDocumentForm, handleChange: handleChangeDocument } = useForm({})
  const [newDocumentFile, setNewDocumentFile] = useState(null)
  const [addMRVPlanState, setAddMRVPlanState] = useState(false)
  const { form: mrvPlanForm, setForm: setMRVPlanForm, handleChange: handleChangeMRVPlan } = useForm({})
  const { t } = useTranslation('components.SequestrationWellProjectAdminInfoDrawer')

  useEffect(() => {
    if (!sequestrationWellProjectCompanies && !isLoadingFor.FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_REQUESTED) fetchSequestrationWellProjectCompanies()
  }, [isLoadingFor.FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_REQUESTED, sequestrationWellProjectCompanies])

  useEffect(() => {
    if (sequestrationWellProjectViewDocument) {
      const fileURL = URL.createObjectURL(sequestrationWellProjectViewDocument);

      //Open the URL on new Window
      const fileWindow = window.open();
      fileWindow.location.href = fileURL; 
    }
  }, [sequestrationWellProjectViewDocument])

  const closeFormDrawer = () => {
    setFormDrawerOpen(false)
    fetchSequestrationWellProjects()
  }
  const openFormDrawer = () => setFormDrawerOpen(true)

  const onClickEdit = () => {
    if (sequestrationWellProject) {
      openFormDrawer()
      setFormSequestrationWellProject({
        ...sequestrationWellProject,
        active_date: sequestrationWellProject.active_date ? dayjs(sequestrationWellProject.active_date) : null,
        date_current_stage_reached: sequestrationWellProject.date_current_stage_reached ? dayjs(sequestrationWellProject.date_current_stage_reached) : null
      })
    }
  }

  const openExternalIdForm = () => {
    setExternalIdForm({
      external_id: null,
      id_type: null
    })
    setAddExternalIDState(true)
  }

  const closeExternalIdForm = () => {
    setAddExternalIDState(false)
  }

  const openSequestrationWellForm = () => {
    setSequestrationWellForm({
      name: null,
      permit_date: null,
      permit_id: null,
      well_phase: null
    })
    setAddSequestrationWellState(true)
  }

  const closeSequestrationWellForm = () => {
    setAddSequestrationWellState(false)
  }

  const openProjectDeveloperForm = () => {
    setProjectDeveloperForm({
      name: null,
      data_source: {
        source: 'manual_entry',
        access_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
        currency_date: new Date().toISOString().slice(0, 19).replace('T', ' ')
      }
    })
    setAddProjectDeveloperState(true)
  }

  const closeProjectDeveloperForm = () => {
    setAddProjectDeveloperState(false)
  }

  const openPartnerForm = () => {
    setPartnerForm({
      name: null,
      data_source: {
        source: 'manual_entry',
        access_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
        currency_date: new Date().toISOString().slice(0, 19).replace('T', ' ')
      }
    })
    setAddPartnerState(true)
  }

  const closePartnerForm = () => {
    setAddPartnerState(false)
  }

  const openDocumentForm = () => {
    setDocumentForm({
      name: null,
      description: null,
      document_purpose: null
    })
    setNewDocumentFile(null)
    setAddDocumentState(true)
  }

  const closeDocumentForm = () => {
    setAddDocumentState(false)
  }

  const openMRVPlanForm = () => {
    setMRVPlanForm({
      status: null,
      status_update_date: null,
      plan_id_number: null,
      mrv_swp_document_id: null
    })
    setAddMRVPlanState(true)
  }

  const closeMRVPlanForm = () => {
    setAddMRVPlanState(false)
  }

  useEffect(() => {
    closeExternalIdForm()
    closeSequestrationWellForm()
    closeProjectDeveloperForm()
    closePartnerForm()
    closeDocumentForm()
    closeMRVPlanForm()
    setPartnerSelectValue(null)
    setProjectDeveloperSelectValue(null)
  }, [open])

  const externalIdTypeOptions = SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_TYPES.map(s => ({
    value: s,
    label: i18n.t(`constants.sequestration_well_project_external_id_type.${s}`)
  }))


  const createExternalId = () => createSequestrationWellProjectExternalId({ 
    form: {
      ...externalIdForm,
      sequestration_well_project_id: sequestrationWellProject?.id
    },
    successCallback: () => {
      closeExternalIdForm()
      fetchSequestrationWellProjects()
    }
  })

  const deleteExternalId = (id) => deleteSequestrationWellProjectExternalId({ id, successCallback: () => fetchSequestrationWellProjects() })

  const externalIdentificationTableColumns = [
    {
      title: t('external_id.id'),
      dataIndex: 'id',
      key: 'id',
      render: tableTextIdEllipsisRender
    },
    {
      title: t('external_id.external_id'),
      dataIndex: 'external_id',
      key: 'external_id'
    },
    {
      title: t('external_id.id_type'),
      dataIndex: 'id_type',
      key: 'id_type'
    },
    {
      key: 'CRUD',
      render: (_, record) => <Popconfirm
        title={`Delete ${record.id}`}
        description="Are you sure to delete this external id?"
        onConfirm={() => deleteExternalId(record.id)}
        okText="Yes"
        cancelText="No"
      >
        <Link>{t('delete')}</Link>
      </Popconfirm>
    }
  ]

  const sequestrationWellPhaseOptions = SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_PHASES.map(s => ({
    value: s,
    label: i18n.t(`constants.sequestration_well_project_sequestration_well_phase.${s}`)
  }))

  const createSequestrationWell = () => createSequestrationWellProjectSequestrationWell({ 
    form: {
      ...sequestrationWellForm,
      sequestration_well_project_id: sequestrationWellProject?.id
    },
    successCallback: () => {
      closeSequestrationWellForm()
      fetchSequestrationWellProjects()
    }
  })

  const deleteSequestrationWell = (id) => deleteSequestrationWellProjectSequestrationWell({ id, successCallback: () => fetchSequestrationWellProjects() })

  const sequestrationWellTableColumns = [
    {
      title: t('sequestration_well.id'),
      dataIndex: 'id',
      key: 'id',
      render: tableTextIdEllipsisRender
    },
    {
      title: t('sequestration_well.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())
    },
    {
      title: t('sequestration_well.permit_id'),
      dataIndex: 'permit_id',
      key: 'permit_id'
    },
    {
      title: t('sequestration_well.permit_date'),
      dataIndex: 'permit_date',
      key: 'permit_date'
    },
    {
      title: t('sequestration_well.well_phase'),
      dataIndex: 'well_phase',
      key: 'well_phase'
    },
    {
      key: 'CRUD',
      render: (_, record) => <Popconfirm
        title={`Delete ${record.id}`}
        description="Are you sure to delete this sequestration well?"
        onConfirm={() => deleteSequestrationWell(record.id)}
        okText="Yes"
        cancelText="No"
      >
        <Link>{t('delete')}</Link>
      </Popconfirm>
    }
  ]

  const createProjectDeveloper = () => createSequestrationWellProjectProjectDeveloper({ 
    form: {
      ...projectDeveloperForm,
      sequestration_well_project_id: sequestrationWellProject?.id
    },
    successCallback: () => {
      closeProjectDeveloperForm()
      fetchSequestrationWellProjects()
      fetchSequestrationWellProjectCompanies()
    }
  })

  const sequestrationWellCompanyOptions = sequestrationWellProjectCompanies?.filter(c => c.id !== sequestrationWellProject?.project_developer?.id && sequestrationWellProject?.partners.findIndex(p => p.partner.id === c.id) === -1).map(c => ({
    value: c.id,
    label: c.name
  })).sort((a, b) => a.label.toUpperCase().localeCompare(b.label.toUpperCase()))

  const removePartner = (id) => removeSequestrationWellProjectPartner({ id, successCallback: () => fetchSequestrationWellProjects() })

  const partnersTableColumns = [
    {
      title: t('partner.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      key: 'CRUD',
      render: (_, record) => 
        <Flex justify='right'>
        <Popconfirm
          title={`Delete ${record.name}`}
          description="Are you sure to delete this partner?"
          onConfirm={() => removePartner(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button className='!text-red-600' type='text' icon={<StopOutlined />} />
        </Popconfirm>
      </Flex>
    }
  ]

  const createPartner = () => createSequestrationWellProjectPartner({ 
    form: {
      ...partnerForm,
      sequestration_well_project_id: sequestrationWellProject?.id
    },
    successCallback: () => {
      closePartnerForm()
      fetchSequestrationWellProjects()
      fetchSequestrationWellProjectCompanies()
    }
  })

  const documentPurposeOptions = SEQUESTRATION_WELL_PROJECT_DOCUMENT_PURPOSES.map(dp => ({
    value: dp,
    label: i18n.t(`constants.sequestration_well_project_document_purpose.${dp}`)
  }))

  const deleteDocument = (form) => deleteSequestrationWellProjectDocument({ form, successCallback: () => fetchSequestrationWellProjects() })

  const documentTableColumns = [
    {
      title: t('document.id'),
      dataIndex: 'id',
      key: 'id',
      render: tableTextIdEllipsisRender
    },
    {
      title: t('document.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('document.description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: t('document.document_purpose'),
      dataIndex: 'document_purpose',
      key: 'document_purpose'
    },
    {
      title: t('document.file_name'),
      dataIndex: 'file_name',
      key: 'file_name'
    },
    {
      title: '',
      key: 'view',
      render: (record) => <Button type='text' shape='circle' icon={<EyeOutlined />} onClick={() => downloadSequestrationWellProjectDocument({ sequestration_well_project_id: sequestrationWellProject.id, file_name: record.file_name })} />
    },
    {
      key: 'CRUD',
      render: (_, record) => <Popconfirm
          title={`Delete ${record.name}`}
          description="Are you sure to delete this document?"
          onConfirm={() => deleteDocument({ 
            id: record.id,
            file_name: record.file_name,
            sequestration_well_project_id: sequestrationWellProject.id
          })}
          okText="Yes"
          cancelText="No"
        >
          <Link>{t('delete')}</Link>
        </Popconfirm>
    }
  ]

  const createDocument = () => createSequestrationWellProjectDocument({
    form: {
      ...documentForm,
      file: newDocumentFile,
      sequestration_well_project_id: sequestrationWellProject?.id
    },
    successCallback: () => {
      closeDocumentForm()
      fetchSequestrationWellProjects()
    }
  })

  const mrvPlanStatusOptions = SEQUESTRATION_WELL_PROJECT_MRV_PLAN_STATUSES.map(s => ({
    value: s,
    label: i18n.t(`constants.sequestration_well_project_mrv_plan_status.${s}`)
  }))

  const deleteMRVPlan = (id) => deleteSequestrationWellProjectMRVPlan({ id, successCallback: () => fetchSequestrationWellProjects() })

  const mrvPlanTableColumns = [
    {
      title: t('mrv_plan.id'),
      dataIndex: 'id',
      key: 'id',
      render: tableTextIdEllipsisRender
    },
    {
      title: t('mrv_plan.plan_id_number'),
      dataIndex: 'plan_id_number',
      key: 'plan_id_number'
    },
    {
      title: t('mrv_plan.status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: t('mrv_plan.status_update_date'),
      dataIndex: 'status_update_date',
      key: 'status_update_date'
    },
    {
      title: t('mrv_plan.document'),
      key: 'document.name',
      render: (record) => record.document ? <Link onClick={() => downloadSequestrationWellProjectDocument({ sequestration_well_project_id: sequestrationWellProject.id, file_name: record.document.file_name })}>{ record.document.name }</Link> : <></>
    },
    {
      key: 'CRUD',
      render: (_, record) => <Popconfirm
          title={`Delete ${record.id}`}
          description="Are you sure to delete this mrv plan?"
          onConfirm={() => deleteMRVPlan(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Link>{t('delete')}</Link>
        </Popconfirm>
    }
  ]

  const createMRVPlan = () => createSequestrationWellProjectMRVPlan({
    form: {
      ...mrvPlanForm,
      sequestration_well_project_id: sequestrationWellProject?.id
    },
    successCallback: () => {
      closeMRVPlanForm()
      fetchSequestrationWellProjects()
    }
  })

  const setProjectShowOnMap = (value) => patchSequestrationWellProjectShowOnMap({
    form: {
      id: sequestrationWellProject?.id,
      show_on_map: value
    },
    successCallback: () => fetchSequestrationWellProjects()
  })

  const showProjectOnMap = () => setProjectShowOnMap(true)

  const hideProjectOnMap = () => setProjectShowOnMap(false)

  return <Drawer
      title={
        <Flex vertical>
          <Flex justify="space-between">
            <Title className="m-0" level={3}>
              {sequestrationWellProject?.name}
            </Title>
            <Flex>
              <Button size='small' className='mr-2' onClick={onClickEdit} type='primary' icon={<Tooltip placement='left' title={t('edit_tooltip')}><EditOutlined /></Tooltip>} />
              <Button type='text' size='small' onClick={onClose}>
                <CloseOutlined />
              </Button>
            </Flex>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className='font-normal'>{sequestrationWellProject?.id}</Text>
            { sequestrationWellProject?.show_on_map 
              ? <Button size='small' onClick={hideProjectOnMap} loading={isLoadingFor.PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_REQUESTED}>{t('hide')}</Button> 
              : <Button size='small' type='primary' onClick={showProjectOnMap} loading={isLoadingFor.PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_REQUESTED}>{t('publish')}</Button> }
          </Flex>
        </Flex>}
      size='large'
      closeIcon={false}
      onClose={onClose}
      open={open}>
        <Space direction='vertical'>
          <Text>{sequestrationWellProject?.description}</Text>
          <Descriptions columns={2} size='small' items={[
              {
                key: 'well_category',
                label: t('label.well_category'),
                children: sequestrationWellProject?.well_category ? i18n.t(`constants.sequestration_well_project_category.${sequestrationWellProject?.well_category}`) : ''
              },
              {
                key: 'permitting_body',
                label: t('label.permitting_body'),
                span: 2,
                children: sequestrationWellProject?.permitting_body ? i18n.t(`constants.sequestration_well_project_permitting_body.${sequestrationWellProject?.permitting_body}`) : ''
              },
              {
                key: 'total_capacity',
                label: t('label.total_capacity'),
                children: `${sequestrationWellProject?.total_capacity} ${t('label.total_capacity_unit')}`
              },
              {
                key: 'expected_annual_injection_rate',
                label: t('label.expected_annual_injection_rate'),
                span: 2,
                children: `${sequestrationWellProject?.expected_annual_injection_rate} ${t('label.expected_annual_injection_rate_unit')}`
              },
              {
                key: 'active_date',
                label: t('label.active_date'),
                children: formatDateNoTimezone(sequestrationWellProject?.active_date)
              },
              {
                key: 'lease_area',
                label: t('label.lease_area'),
                children: `${sequestrationWellProject?.lease_area} ${t('label.lease_area_unit')}`
              },
              {
                key: 'location',
                label: t('label.location'),
                children: `[${sequestrationWellProject?.location?.latitude}, ${sequestrationWellProject?.location?.longitude}]`
              },
              {
                key: 'project_stage',
                label: t('label.project_stage'),
                children: sequestrationWellProject?.project_stage ? i18n.t(`constants.sequestration_well_project_stage.${sequestrationWellProject?.project_stage}`) : ''
              },
              {
                key: 'date_current_stage_reached',
                label: t('label.date_current_stage_reached'),
                children: formatDateNoTimezone(sequestrationWellProject?.date_current_stage_reached),
                span: 2
              },
              {
                key: 'external_reference_url',
                label: <Flex align='center'>
                  {t('label.external_reference_url')}
                  <Tooltip className='mx-1' title={t('label.external_reference_url_tooltip')}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Flex>,
                span: 3,
                children: isValidURL(sequestrationWellProject?.external_reference_url)
                  ? <Link href={sequestrationWellProject?.external_reference_url} rel='noreferrer' target='_blank'>{sequestrationWellProject?.external_reference_url}</Link>
                  : <Text>{sequestrationWellProject?.external_reference_url}</Text>
              },
              {
                key: 'co2_sources',
                label: t('label.co2_sources'),
                span: 3,
                children: sequestrationWellProject?.co2_sources
              },
              {
                key: 'address',
                label: t('label.address'),
                span: 3,
                children: <AddressText address={sequestrationWellProject?.address} />
              },
            ]} />
            <Flex vertical className='gap-2'>
              <Title level={5}>{t('title.external_identification')}</Title>
              <Table
                rowKey={r => r.id}
                size='small'
                pagination={false}
                columns={externalIdentificationTableColumns}
                dataSource={sequestrationWellProject?.external_identifications} />
              { addExternalIDState ? <Flex className='gap-4'>
                <Input 
                  className='w-full'
                  name='external_id'
                  value={externalIdForm.external_id}
                  onChange={handleChangeExternalId('external_id')} 
                  placeholder={t('external_id.external_id_placeholder')} />
                <Select 
                  className='w-full'
                  name='id_type'
                  options={externalIdTypeOptions}
                  value={externalIdForm.id_type}
                  onChange={handleChangeExternalId('id_type')}
                  placeholder={t('external_id.id_type_placeholder')} />
                <Flex className='gap-2'>
                  <Button className='min-w-8' icon={<CloseOutlined />} onClick={closeExternalIdForm} />
                  <Button type='primary' className='min-w-8' icon={<SaveOutlined />} onClick={createExternalId} loading={isLoadingFor.CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_REQUESTED} />
                </Flex>
              </Flex> : <Button type='text' shape='circle' className='ml-auto' icon={<PlusCircleOutlined />} onClick={openExternalIdForm} /> }
            </Flex>
            <Flex vertical className='gap-2'>
              <Title level={5}>{t('title.wells')}</Title>
              <Table
                rowKey={r => r.id}
                size='small'
                columns={sequestrationWellTableColumns}
                dataSource={sequestrationWellProject?.sequestration_wells} />
              { addSequestrationWellState ? <Flex className='gap-4'>
                <Input 
                  className='w-full'
                  name='name'
                  value={sequestrationWellForm.name}
                  onChange={handleChangeSequestrationWell('name')} 
                  placeholder={t('sequestration_well.name_placeholder')} />
                <Input 
                  className='w-full'
                  name='permit_id'
                  value={sequestrationWellForm.permit_id}
                  onChange={handleChangeSequestrationWell('permit_id')} 
                  placeholder={t('sequestration_well.permit_id_placeholder')} />
                <DatePicker
                  className='w-full'
                  name='permit_date'
                  value={sequestrationWellForm.permit_date}
                  onChange={handleChangeSequestrationWell('permit_date')} />
                <Select 
                  className='w-full'
                  name='well_phase'
                  options={sequestrationWellPhaseOptions}
                  value={sequestrationWellForm.well_phase}
                  onChange={handleChangeSequestrationWell('well_phase')}
                  placeholder={t('sequestration_well.well_phase_placeholder')} />
                <Flex className='gap-2'>
                  <Button className='min-w-8' icon={<CloseOutlined />} onClick={closeSequestrationWellForm} />
                  <Button type='primary' className='min-w-8' icon={<SaveOutlined />} onClick={createSequestrationWell} loading={isLoadingFor.CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_REQUESTED} />
                </Flex>
              </Flex> : <Button type='text' shape='circle' className='ml-auto' icon={<PlusCircleOutlined />} onClick={openSequestrationWellForm} /> }
            </Flex>
            <Flex vertical className='gap-2'>
              <Title level={5}>{t('title.project_developer')}</Title>
              { sequestrationWellProject?.project_developer
                ? <Flex align='center'>
                  <Link onClick={() => setCompanyId(sequestrationWellProject.project_developer.id)}>{sequestrationWellProject.project_developer.name}</Link>
                  <Popconfirm
                    title={'Remove Project Developer'}
                    description="Are you sure to remove this project developer?"
                    onConfirm={() => updateSequestrationWellProjectProjectDeveloperId({ form: { 
                        id: sequestrationWellProject.id,
                        project_developer_id: null
                      },
                      successCallback: () => {
                        fetchSequestrationWellProjects()
                      }
                    })}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className='!text-red-600 ml-1' type='text' icon={<StopOutlined />} />
                  </Popconfirm>
                </Flex>
                : addProjectDeveloperState
                  ? <Flex className='gap-4'>
                      <Input 
                        className='w-full'
                        name='name'
                        value={projectDeveloperForm.name}
                        onChange={handleChangeProjectDeveloper('name')} 
                        placeholder={t('project_developer.name_placeholder')} />
                      <Flex className='gap-2'>
                        <Button className='min-w-8' icon={<CloseOutlined />} onClick={closeProjectDeveloperForm} />
                        <Button type='primary' className='min-w-8' icon={<SaveOutlined />} onClick={createProjectDeveloper} loading={isLoadingFor.CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_REQUESTED} />
                      </Flex>
                    </Flex>
                  : <>
                    <Flex className='gap-2' align='center'>
                      <Select
                        options={sequestrationWellCompanyOptions}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        allowClear
                        value={projectDeveloperSelectValue}
                        onChange={(v) => setProjectDeveloperSelectValue(v)}
                        placeholder={t('project_developer.project_developer_placeholder')} />
                      <Button
                        type='primary'
                        icon={<SaveOutlined />}
                        loading={isLoadingFor.UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_REQUESTED}
                        onClick={() => updateSequestrationWellProjectProjectDeveloperId({ form: { 
                            id: sequestrationWellProject.id,
                            project_developer_id: projectDeveloperSelectValue
                          },
                          successCallback: () => {
                            fetchSequestrationWellProjects()
                            setProjectDeveloperSelectValue(null)
                            setPartnerSelectValue(null)
                          }
                        })} />
                      <Text>{t('or')}</Text>
                      <Button type='primary' className='w-fit' icon={<PlusSquareOutlined />} onClick={openProjectDeveloperForm}>{t('create_new')}</Button>
                    </Flex>
                  </> }
            </Flex>
            <Flex className='gap-2' vertical>
              <Title level={5}>{t('title.partners')}</Title>
              <Table
                rowKey={r => r.partner_id}
                size='small'
                pagination={false}
                columns={partnersTableColumns}
                onRow={(record, rowIndex) => ({
                    onClick: event => { // click row
                      if (setCompanyId) setCompanyId(record.partner_id)
                    }
                  })
                }
                dataSource={sequestrationWellProject?.partners.map(p => ({
                  id: p.id,
                  name: p.partner.name,
                  partner_id: p.partner.id
                }))} />
                { addPartnerState
                  ? <Flex className='gap-4'>
                      <Input 
                        className='w-full'
                        name='name'
                        value={partnerForm.name}
                        onChange={handleChangePartner('name')} 
                        placeholder={t('partner.name_placeholder')} />
                      <Flex className='gap-2'>
                        <Button className='min-w-8' icon={<CloseOutlined />} onClick={closePartnerForm} />
                        <Button type='primary' className='min-w-8' icon={<SaveOutlined />} onClick={createPartner} loading={isLoadingFor.CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED} />
                      </Flex>
                    </Flex>
                  : <>
                    <Flex className='gap-2' align='center'>
                      <Select
                        options={sequestrationWellCompanyOptions}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        allowClear
                        value={partnerSelectValue}
                        onChange={(v) => setPartnerSelectValue(v)}
                        placeholder={t('partner.partner_placeholder')} />
                      <Button
                        type='primary'
                        icon={<SaveOutlined />}
                        loading={isLoadingFor.ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED}
                        onClick={() => addSequestrationWellProjectPartner({ form: { 
                            id: sequestrationWellProject.id,
                            partner_id: partnerSelectValue
                          },
                          successCallback: () => {
                            fetchSequestrationWellProjects()
                            setPartnerSelectValue(null)
                            setProjectDeveloperSelectValue(null)
                          }
                        })} />
                      <Text>{t('or')}</Text>
                      <Button type='primary' className='w-fit' icon={<PlusSquareOutlined />} onClick={openPartnerForm}>{t('create_new')}</Button>
                    </Flex>
                  </> }
            </Flex>
            <Flex vertical className='gap-2'>
              <Title level={5}>{t('title.documents')}</Title>
              <Table
                rowKey={r => r.id}
                size='small'
                pagination={false}
                columns={documentTableColumns}
                dataSource={sequestrationWellProject?.documents} />
              { addDocumentState ? <Flex vertical className='gap-2'>
                <Flex className='gap-4' align='center'>
                  <Input
                    className='w-full'
                    name='name'
                    value={documentForm.name}
                    onChange={handleChangeDocument('name')} 
                    placeholder={t('document.name_placeholder')} />
                  <Select 
                    className='w-full'
                    name='document_purpose'
                    options={documentPurposeOptions}
                    value={documentForm.document_purpose}
                    onChange={handleChangeDocument('document_purpose')}
                    placeholder={t('document.document_purpose_placeholder')}
                    allowClear />
                  { newDocumentFile
                    ? <div className='w-fit'>
                      { newDocumentFile.name }
                    </div>
                    : <Upload
                        showUploadList={false}
                        beforeUpload={file => {
                           setNewDocumentFile(file)

                            // Prevent upload
                            return false;
                        }}>
                      <Button className='w-full' icon={<UploadOutlined />}>{t('document.upload_file')}</Button>
                    </Upload> }
                </Flex>
                <Input.TextArea
                  className='w-full'
                  name='description'
                  value={documentForm.description}
                  onChange={handleChangeDocument('description')} 
                  placeholder={t('document.description_placeholder')} />
                <Flex justify='right' className='gap-2'>
                  <Button className='min-w-8' icon={<CloseOutlined />} onClick={closeDocumentForm} />
                  <Button type='primary' className='min-w-8' icon={<SaveOutlined />} onClick={createDocument} loading={isLoadingFor.CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED} />
                </Flex>
              </Flex> : <Button type='text' shape='circle' className='ml-auto' icon={<PlusCircleOutlined />} onClick={openDocumentForm} /> }
            </Flex>
            <Flex vertical className='gap-2'>
              <Title level={5}>{t('title.mrv_plans')}</Title>
              <Table
                rowKey={r => r.id}
                size='small'
                pagination={false}
                columns={mrvPlanTableColumns}
                dataSource={sequestrationWellProject?.mrv_plans} />
              { addMRVPlanState
                ? <Flex vertical className='gap-2'>
                  <Flex className='gap-4' align='center'>
                    <Input
                      className='w-full'
                      name='plan_id_number'
                      value={mrvPlanForm.plan_id_number}
                      onChange={handleChangeMRVPlan('plan_id_number')} 
                      placeholder={t('mrv_plan.plan_id_number_placeholder')} />
                    <Select 
                      className='w-full'
                      name='mrv_swp_document_id'
                      options={sequestrationWellProject?.documents.filter(d => d.document_purpose === 'mrv_plan' && sequestrationWellProject?.mrv_plans.findIndex(mp => mp.document?.id === d.id) === -1).map(d => ({ value: d.id, label: d.name }))}
                      value={mrvPlanForm.mrv_swp_document_id}
                      onChange={handleChangeMRVPlan('mrv_swp_document_id')}
                      placeholder={t('mrv_plan.document_placeholder')}
                      allowClear />
                  </Flex>
                  <Flex className='gap-4' align='center'> 
                    <Select 
                      className='w-full'
                      name='status'
                      options={mrvPlanStatusOptions}
                      value={mrvPlanForm.status}
                      onChange={handleChangeMRVPlan('status')}
                      placeholder={t('mrv_plan.status_placeholder')}
                      allowClear />
                    <DatePicker
                      className='w-full'
                      name='status_update_date'
                      value={mrvPlanForm.status_update_date}
                      placeholder={t('mrv_plan.status_update_date_placeholder')}
                      onChange={handleChangeMRVPlan('status_update_date')} />
                  </Flex>
                  <Flex justify='right' className='gap-2'>
                    <Button className='min-w-8' icon={<CloseOutlined />} onClick={closeMRVPlanForm} />
                    <Button type='primary' className='min-w-8' icon={<SaveOutlined />} onClick={createMRVPlan} loading={isLoadingFor.CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_REQUESTED} />
                  </Flex>
                </Flex>
                : <Button type='text' shape='circle' className='ml-auto' icon={<PlusCircleOutlined />} onClick={openMRVPlanForm} />}
            </Flex>
        </Space>
        <SequestrationWellFormDrawer
          onClose={closeFormDrawer}
          open={formDrawerOpen}
          sequestrationWellProject={formSequestrationWellProject} />
    </Drawer>
}

function mapStateToProps(state) {
  const {
    sequestrationWellProjectViewDocument,
    sequestrationWellProjectCompanies,
    isLoadingFor
  } = state
  return {
    sequestrationWellProjectViewDocument,
    sequestrationWellProjectCompanies,
    isLoadingFor
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchSequestrationWellProjects: () => dispatch(FETCH_SEQUESTRATION_WELL_PROJECTS_REQUESTED()),
    fetchSequestrationWellProjectCompanies: () => dispatch(FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_REQUESTED()),
    createSequestrationWellProjectExternalId: ({ form, successCallback }) => dispatch(CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_REQUESTED({ form, successCallback })),
    deleteSequestrationWellProjectExternalId: ({ id, successCallback }) => dispatch(DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_REQUESTED({ id, successCallback })),
    createSequestrationWellProjectSequestrationWell: ({ form, successCallback }) => dispatch(CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_REQUESTED({ form, successCallback })),
    deleteSequestrationWellProjectSequestrationWell: ({ id, successCallback }) => dispatch(DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_REQUESTED({ id, successCallback })),
    createSequestrationWellProjectProjectDeveloper: ({ form, successCallback }) => dispatch(CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_REQUESTED({ form, successCallback })),
    updateSequestrationWellProjectProjectDeveloperId: ({ form, successCallback }) => dispatch(UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_REQUESTED({ form, successCallback })),
    createSequestrationWellProjectPartner: ({ form, successCallback }) => dispatch(CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED({ form, successCallback })),
    removeSequestrationWellProjectPartner: ({ id, successCallback }) => dispatch(REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED({ id, successCallback })),
    addSequestrationWellProjectPartner: ({ form, successCallback }) => dispatch(ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_REQUESTED({ form, successCallback })),
    createSequestrationWellProjectDocument: ({ form, successCallback }) => dispatch(CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED({ form, successCallback })),
    downloadSequestrationWellProjectDocument: (params) => dispatch(DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED(params)),
    deleteSequestrationWellProjectDocument: ({ form, successCallback }) => dispatch(DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_REQUESTED({ form, successCallback })),
    createSequestrationWellProjectMRVPlan: ({ form, successCallback }) => dispatch(CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_REQUESTED({ form, successCallback })),
    deleteSequestrationWellProjectMRVPlan: ({ id, successCallback }) => dispatch(DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_REQUESTED({ id, successCallback })),
    patchSequestrationWellProjectShowOnMap: ({ form, successCallback }) => dispatch(PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_REQUESTED({ form, successCallback }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SequestrationWellProjectAdminInfoDrawer)