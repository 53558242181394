import { Typography } from 'antd'

const { Text } = Typography

const OverviewSubheading = ({ text, className }) => {
  return <div className={`pb-2 ${className}`}>
    <Text type='secondary' strong>{text}</Text>
  </div>
}

export default OverviewSubheading;
      