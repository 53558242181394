import React, { useState } from 'react'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { theme } from 'antd'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { SUPABASE } from '../shared/constants'

const Login = ({ session }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  if (session !== null && session !== 0) return <Navigate to="/" />

  return <div className='p-6 flex'>
    <div className='mx-auto my-6 lg:px-8 min-w-64 sm:min-w-96 h-fit' style={{ background: colorBgContainer, borderRadius: borderRadiusLG }} >
      <Auth supabaseClient={SUPABASE} appearance={{ theme: ThemeSupa }} showLinks={false} providers={[]}/>
    </div>
  </div>
}

function mapStateToProps(state) {
  const { session } = state
  return {
    session
 }
}

export default connect(mapStateToProps)(Login);
