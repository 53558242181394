import { Tag } from 'antd'
import i18n from 'i18next'
import dayjs from 'dayjs'

const SequestrationWellProjectStatusTag = ({ sequestrationWellProject, className }) => {
  const active = dayjs(sequestrationWellProject.active_date) <= dayjs()

  const text = active ? i18n.t('constants.sequestration_well_project_status.active') : i18n.t('constants.sequestration_well_project_status.planned')

  const color = active ? 'processing' : 'default'

  return <Tag align='center' color={color} className={className}>
    {text}
  </Tag>
}

export default SequestrationWellProjectStatusTag;