import { useState } from 'react'
import _ from 'lodash'

export default (initialFormState) => {
  const [form, setForm] = useState(initialFormState)

  const handleChange = (key) => (e) =>
    setForm((x) => {
      let obj = _.cloneDeep(x)
      if (e?.target?.type === 'checkbox') {
        _.set(obj, key, e.target ? e.target.checked : e)
        return obj
      }

      _.set(obj, key, e?.target ? e.target.value : e)
      return obj
    })

  const append = (key) => (e) =>
    setForm((x) => {
      let obj = _.cloneDeep(x)
      _.set(obj, key, [...(_.get(obj, key) || []), e])
      return obj
    })

  const filter = (key) => (predicate) =>
    setForm((x) => {
      let obj = _.cloneDeep(x)
      _.set(obj, key, _.get(obj, key).filter(predicate))
      return obj
    })

  return { form, handleChange, append, setForm, filter }
}