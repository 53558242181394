import { SUPABASE } from '../../shared/constants'

const sequestrationWellProjectQuery = `
      id,
      name,
      description,
      well_category,
      permitting_body,
      active_date,
      total_capacity,
      expected_annual_injection_rate,
      lease_area,
      project_stage,
      date_current_stage_reached,
      external_reference_url,
      co2_sources,
      show_on_map,
      location:location_id(
        id,
        latitude,
        longitude
      ),
      address:address_id(
        address1,
        address2,
        city,
        zip,
        country_id,
        country_subdivision_id
      ),
      external_identifications:swp_external_identifications(
        id,
        external_id,
        id_type
      ),
      sequestration_wells:swp_sequestration_wells(
        id,
        name,
        permit_date,
        permit_id,
        well_phase
      ),
      project_developer:project_developer_id(
        id,
        name
      ),
      partners:swp_partners(
        id,
        partner:partner_id(
          id,
          name
        )
      ),
      documents:swp_documents(
        id,
        name,
        description,
        document_purpose,
        file_name
      ),
      mrv_plans:swp_mrv_plans(
        id,
        plan_id_number,
        status,
        status_update_date,
        document:mrv_swp_document_id(
          id,
          name,
          file_name
        )
      )
    `

const calls = {
  FETCH_USERS: async () => await SUPABASE.from('users').select('id, username, user_roles( id, role )'),
  FETCH_MASS_UNITS: async () => await SUPABASE.from('mass_units').select(),
  FETCH_COUNTRIES: async () => await SUPABASE.from('countries').select(),
  FETCH_COUNTRY_SUBDIVISIONS: async () => {
    const pageSize = 1000

    const returnObject = {
      data: []
    }
    let start = 0
    let end = pageSize - 1
    let moreData = true

    while (moreData) {
      const d = await SUPABASE.from('country_subdivisions').select().range(start, end)

      returnObject.data = [...returnObject.data, ...d.data]

      if (d.data.length < pageSize) {
        moreData = false
      } else {
        start += pageSize
        end += pageSize
      }
    }

    return returnObject
  },
  FETCH_NAICS: async () => {
    const pageSize = 1000

    const returnObject = {
      data: []
    }
    let start = 0
    let end = pageSize - 1
    let moreData = true

    while (moreData) {
      const d = await SUPABASE.from('naics').select().range(start, end)

      returnObject.data = [...returnObject.data, ...d.data]

      if (d.data.length < pageSize) {
        moreData = false
      } else {
        start += pageSize
        end += pageSize
      }
    }

    return returnObject
  },
  FETCH_GASSES: async () => await SUPABASE.from('gasses').select(),
  FETCH_INDUSTRY_CATEGORIES: async () => await SUPABASE.from('industry_categories').select(),
  FETCH_INDUSTRIES: async () => await SUPABASE.from('industries').select(),
  FETCH_FACILITY_REPORT_YEARS: async () => await SUPABASE.from('facility_emission_report_years').select('year').order('year', { ascending: false }),
  FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE: async (action, listenerApi) => await SUPABASE.rpc('get_naics_average_annual_biogenic_percentage', { requested_naics_id: action.payload }),
  FETCH_FILTERED_FACILITY_IDS: async (action, listenerApi) => {
    const params = action.payload

    const pageSize = 1000

    const returnObject = {
      data: []
    }
    let start = 0
    let end = pageSize - 1
    let moreData = true

    while (moreData) {
      const d = await SUPABASE.rpc('get_emissions_facilities_filtered_ids', { 
        lower_bound_total: params?.lower_bound_total, 
        upper_bound_total: params?.upper_bound_total,
        report_year: params?.year,
        lower_bound_fossil: params?.lower_bound_fossil,
        upper_bound_fossil: params?.upper_bound_fossil,
        lower_bound_biogenic: params?.lower_bound_biogenic,
        upper_bound_biogenic: params?.upper_bound_biogenic,
        lower_bound_biogenic_percentage: params?.lower_bound_biogenic_percentage,
        upper_bound_biogenic_percentage: params?.upper_bound_biogenic_percentage,
        industry_ids: params?.industry_ids
      }).range(start, end)

      returnObject.data = [...returnObject.data, ...d.data]

      if (d.data.length < pageSize) {
        moreData = false
      } else {
        start += pageSize
        end += pageSize
        // moreData = false
      }
    }

    return returnObject
  },
  FETCH_MAP_DATA: async (action, listenerApi) => await SUPABASE.from('annual_facility_emissions_overview').select().eq('year', action.payload?.year).order('quantity_co2e', { ascending: false }).range(action.payload?.start || 0, action.payload?.end || 999),
  FETCH_FACILITY_MAP_DATA: async (action, listenerApi) => await SUPABASE.from('facilities').select(`
    id,
    name,
    facility_identifications(
      id,
      id_type,
      external_id
    ),
    address:address_id(
      id,
      address1,
      address2,
      city,
      zip,
      country_subdivision_id,
      country_id
    ),
    location:location_id(
      id,
      latitude,
      longitude
    ),
    data_source:data_source_id(
      id,
      source,
      currency_date,
      access_date
    ),
    facility_reports(
      id,
      year,
      report_type,
      data_source:data_source_id(
        id,
        source,
        currency_date,
        access_date
      ),
      facility_report_gasses(
        id,
        gas_id,
        quantity,
        mass_unit_id,
        quantity_co2e,
        co2e_mass_unit_id
      )
    ),
    facility_naics(
      id,
      naics_id,
      facility_naics_primacy
    ),
    parent_companies:company_facilities(
      company: companies!inner(
        id,
        name,
        address:address_id(
          id,
          address1,
          address2,
          city,
          zip,
          country_subdivision_id,
          country_id
        ),
        data_source:data_source_id(
          id,
          source,
          currency_date,
          access_date
        ),
        public_contacts(
          id,
          name,
          position,
          contact_info (
            id,
            email,
            extension,
            phone
          )
        )
      ),
      ownership_percentage
    )
  `).eq('id', action.payload),
  FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE: async (action, listenerApi) => {
    const facilityIdentificationTypeFilter = action.payload

    const pageSize = 1000

    const returnObject = {
      data: []
    }
    let start = 0
    let end = pageSize - 1
    let moreData = true

    while (moreData) {
      const d = await SUPABASE.from('facilities').select('id, facility_identifications!inner(external_id, id_type)').eq('facility_identifications.id_type', facilityIdentificationTypeFilter).range(start, end)

      returnObject.data = [...returnObject.data, ...d.data]

      if (d.data.length < pageSize) {
        moreData = false
      } else {
        start += pageSize
        end += pageSize
        // moreData = false
      }
    }

    return returnObject
  },
  FETCH_MAXIMUM_FACILITY_EMISSIONS: async (action, listenerApi) => await SUPABASE.rpc('get_maximum_emissions', { report_year: action.payload?.year, gas_type: action.payload?.gas_type }).single(),
  BULK_INSERT_FACILITY_DATA: async (action, listenerApi) => {
    const data = action.payload

    const chunkSize = 1000;
    let returnIds = []
    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize).map(d => ({
        id: d.id,
        name: d.name,
        address: d.address,
        location:  d.location,
        data_source: d.data_source,
        facility_naics: d.naics,
        facility_identifications: d.facility_identifications
      }));

      const returnData = await SUPABASE.rpc('upsert_facilities', { input_facilities: chunk })

      returnIds = [...returnIds, ...returnData.data]
    }

    data.forEach((d, i) => data[i].id = returnIds[i])

    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize).map(d => ({
        id: d.id,
        parent_companies: d.parent_companies
      }));

      await SUPABASE.rpc('update_facility_parent_companies', { input_facilities: chunk })
    }

    const facilityReportChunkSize = 200

    for (let i = 0; i < data.length; i += facilityReportChunkSize) {
      const chunk = data.slice(i, i + facilityReportChunkSize).map(d => ({
        id: d.id,
        facility_reports: d.facility_reports
      }));

      await SUPABASE.rpc('update_facility_facility_reports', { input_facilities: chunk })
    }

    return returnIds
  },
  UPDATE_PASSWORD: async (action, listenerApi) => await SUPABASE.auth.updateUser({ password: action.payload }),
  CREATE_SEQUESTRATION_WELL_PROJECT: async (action, listenerApi) => {
    const newSequestrationWellProject = action.payload.form

    return await SUPABASE.rpc('create_sequestration_well_project', {
      name: newSequestrationWellProject.name,
      description: newSequestrationWellProject.description,
      well_category: newSequestrationWellProject.well_category || null,
      permitting_body: newSequestrationWellProject.permitting_body || null,
      active_date: newSequestrationWellProject.active_date,
      total_capacity: newSequestrationWellProject.total_capacity,
      expected_annual_injection_rate: newSequestrationWellProject.expected_annual_injection_rate,
      lease_area: newSequestrationWellProject.lease_area,
      project_stage: newSequestrationWellProject.project_stage || null,
      date_current_stage_reached: newSequestrationWellProject.date_current_stage_reached,
      external_reference_url: newSequestrationWellProject.external_reference_url,
      co2_sources: newSequestrationWellProject.co2_sources,
      latitude: newSequestrationWellProject.location.latitude,
      longitude: newSequestrationWellProject.location.longitude,
      address1: newSequestrationWellProject.address.address1,
      address2: newSequestrationWellProject.address.address2,
      city: newSequestrationWellProject.address.city,
      zip: newSequestrationWellProject.address.zip,
      country_id: newSequestrationWellProject.address.country_id || null,
      country_subdivision_id: newSequestrationWellProject.address.country_subdivision_id || null
    })
  },
  UPDATE_SEQUESTRATION_WELL_PROJECT: async (action, listenerApi) => {
    const sequestrationWellProject = action.payload.form

    return await SUPABASE.rpc('update_sequestration_well_project', {
      id: sequestrationWellProject.id,
      name: sequestrationWellProject.name,
      description: sequestrationWellProject.description,
      well_category: sequestrationWellProject.well_category || null,
      permitting_body: sequestrationWellProject.permitting_body || null,
      active_date: sequestrationWellProject.active_date,
      total_capacity: sequestrationWellProject.total_capacity,
      expected_annual_injection_rate: sequestrationWellProject.expected_annual_injection_rate,
      lease_area: sequestrationWellProject.lease_area,
      project_stage: sequestrationWellProject.project_stage || null,
      date_current_stage_reached: sequestrationWellProject.date_current_stage_reached,
      external_reference_url: sequestrationWellProject.external_reference_url,
      co2_sources: sequestrationWellProject.co2_sources,
      latitude: sequestrationWellProject.location.latitude,
      longitude: sequestrationWellProject.location.longitude,
      address1: sequestrationWellProject.address.address1,
      address2: sequestrationWellProject.address.address2,
      city: sequestrationWellProject.address.city,
      zip: sequestrationWellProject.address.zip,
      country_id: sequestrationWellProject.address.country_id || null,
      country_subdivision_id: sequestrationWellProject.address.country_subdivision_id || null
    })
  },
  FETCH_SEQUESTRATION_WELL_PROJECTS: async () => await SUPABASE.from('sequestration_well_projects').select(sequestrationWellProjectQuery).order('name'),
  FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP: async () => await SUPABASE.from('sequestration_well_projects').select(sequestrationWellProjectQuery).eq('show_on_map', true).order('name'),
  CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID: async (action, listenerApi) => {
    const sequestrationWellProjectExternalId = action.payload.form

    if (sequestrationWellProjectExternalId.sequestration_well_project_id &&
      sequestrationWellProjectExternalId.external_id &&
      sequestrationWellProjectExternalId.id_type) {
      return await SUPABASE.from('swp_external_identifications').insert({
        sequestration_well_project_id: sequestrationWellProjectExternalId.sequestration_well_project_id,
        external_id: sequestrationWellProjectExternalId.external_id,
        id_type: sequestrationWellProjectExternalId.id_type
      }).select('id').single()
    } else {
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID: async (action, listenerApi) => {
    const sequestrationWellProjectExternalIdId = action.payload.id

    if (sequestrationWellProjectExternalIdId) {
      return await SUPABASE.from('swp_external_identifications').delete().eq('id', sequestrationWellProjectExternalIdId).select('id').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL: async (action, listenerApi) => {
    const sequestrationWellProjectSequestrationWell = action.payload.form

    if (sequestrationWellProjectSequestrationWell.sequestration_well_project_id) {
      return await SUPABASE.from('swp_sequestration_wells').insert({
        sequestration_well_project_id: sequestrationWellProjectSequestrationWell.sequestration_well_project_id,
        name: sequestrationWellProjectSequestrationWell.name,
        permit_id: sequestrationWellProjectSequestrationWell.permit_id,
        permit_date: sequestrationWellProjectSequestrationWell.permit_date,
        well_phase: sequestrationWellProjectSequestrationWell.well_phase
      }).select('id').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL: async (action, listenerApi) => {
    const sequestrationWellProjectSequestrationWellId = action.payload.id

    if (sequestrationWellProjectSequestrationWellId) {
      return await SUPABASE.from('swp_sequestration_wells').delete().eq('id', sequestrationWellProjectSequestrationWellId).select('id').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER: async (action, listenerApi) => {
    const sequestrationWellProjectProjectDeveloper = action.payload.form

    if (sequestrationWellProjectProjectDeveloper.sequestration_well_project_id &&
      sequestrationWellProjectProjectDeveloper.data_source.source &&
      sequestrationWellProjectProjectDeveloper.data_source.access_date &&
      sequestrationWellProjectProjectDeveloper.data_source.currency_date) {
      return await SUPABASE.rpc('create_sequestration_well_project_project_developer', {
        sequestration_well_project_id: sequestrationWellProjectProjectDeveloper.sequestration_well_project_id,
        name: sequestrationWellProjectProjectDeveloper.name,
        data_source_source: sequestrationWellProjectProjectDeveloper.data_source.source,
        data_source_access_date: sequestrationWellProjectProjectDeveloper.data_source.access_date,
        data_source_currency_date: sequestrationWellProjectProjectDeveloper.data_source.currency_date
      })
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID: async (action, listenerApi) => {
    const sequestrationWellProject = action.payload.form

    if (sequestrationWellProject.id) {
      return await SUPABASE.from('sequestration_well_projects').update({ project_developer_id: sequestrationWellProject.project_developer_id }).eq('id', sequestrationWellProject.id).select('project_developer_id').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES: async (action, listenerApi) => await SUPABASE.from('companies').select(`
    id,
    name,
    company_facilities ()
  `)
  .is('company_facilities', null),
  CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER: async (action, listenerApi) => {
    const sequestrationWellProjectPartner = action.payload.form

    if (sequestrationWellProjectPartner.sequestration_well_project_id &&
      sequestrationWellProjectPartner.data_source.source &&
      sequestrationWellProjectPartner.data_source.access_date &&
      sequestrationWellProjectPartner.data_source.currency_date) {
      return await SUPABASE.rpc('create_sequestration_well_project_partner', {
        sequestration_well_project_id: sequestrationWellProjectPartner.sequestration_well_project_id,
        name: sequestrationWellProjectPartner.name,
        data_source_source: sequestrationWellProjectPartner.data_source.source,
        data_source_access_date: sequestrationWellProjectPartner.data_source.access_date,
        data_source_currency_date: sequestrationWellProjectPartner.data_source.currency_date
      })
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER: async (action, listenerApi) => {
    const sequestrationWellProjectPartnerId = action.payload.id

    if (sequestrationWellProjectPartnerId) {
      return await SUPABASE.from('swp_partners').delete().eq('id', sequestrationWellProjectPartnerId).select('partner:partner_id(id)').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  ADD_SEQUESTRATION_WELL_PROJECT_PARTNER: async (action, listenerApi) => {
    const sequestrationWellProjectPartnerForm = action.payload.form

    if (sequestrationWellProjectPartnerForm.id && sequestrationWellProjectPartnerForm.partner_id) {
      return await SUPABASE.from('swp_partners').insert({
        sequestration_well_project_id: sequestrationWellProjectPartnerForm.id,
        partner_id: sequestrationWellProjectPartnerForm.partner_id
      }).select('partner:partner_id(id)').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT: async (action, listenerApi) => {
    const sequestrationWellProjectDocumentForm = action.payload.form

    if (sequestrationWellProjectDocumentForm.sequestration_well_project_id && sequestrationWellProjectDocumentForm.file) {
      const { data, error } = await SUPABASE.storage
        .from('swp_documents')
        .upload(`${sequestrationWellProjectDocumentForm.sequestration_well_project_id}/${sequestrationWellProjectDocumentForm.file.name}`, sequestrationWellProjectDocumentForm.file)

      if (error) throw Object.assign(
          new Error('File Upload Error'),
          { code: 400 }
        )

      return await SUPABASE.from('swp_documents').insert({
        sequestration_well_project_id: sequestrationWellProjectDocumentForm.sequestration_well_project_id,
        name: sequestrationWellProjectDocumentForm.name,
        description: sequestrationWellProjectDocumentForm.description,
        document_purpose: sequestrationWellProjectDocumentForm.document_purpose,
        file_name: sequestrationWellProjectDocumentForm.file.name
      }).select('id').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT: async (action, listenerApi) => {
    const params = action.payload

    return await SUPABASE.storage
      .from('swp_documents')
      .download(`${params.sequestration_well_project_id}/${params.file_name}`)
  },
  DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT: async (action, listenerApi) => {
    const sequestrationWellProjectDocumentForm = action.payload.form

    if (sequestrationWellProjectDocumentForm.sequestration_well_project_id &&
      sequestrationWellProjectDocumentForm.file_name &&
      sequestrationWellProjectDocumentForm.id) {
      const { data, error } = await SUPABASE.from('swp_documents').delete().eq('id', sequestrationWellProjectDocumentForm.id).select('id').single()

      if (error) throw Object.assign(
          new Error('File Deletion Error'),
          { code: 400 }
        )

      await SUPABASE.storage
        .from('swp_documents')
        .remove([`${sequestrationWellProjectDocumentForm.sequestration_well_project_id}/${sequestrationWellProjectDocumentForm.file_name}`])
      return { data, error }
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN: async (action, listenerApi) => {
    const sequestrationWellProjectMRVPlan = action.payload.form

    if (sequestrationWellProjectMRVPlan.sequestration_well_project_id) {
      return await SUPABASE.from('swp_mrv_plans').insert({
        sequestration_well_project_id: sequestrationWellProjectMRVPlan.sequestration_well_project_id,
        status: sequestrationWellProjectMRVPlan.status,
        status_update_date: sequestrationWellProjectMRVPlan.status_update_date,
        plan_id_number: sequestrationWellProjectMRVPlan.plan_id_number,
        mrv_swp_document_id: sequestrationWellProjectMRVPlan.mrv_swp_document_id
      }).select('id').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN: async (action, listenerApi) => {
    const sequestrationWellProjectMRVPlanId = action.payload.id

    if (sequestrationWellProjectMRVPlanId) {
      return await SUPABASE.from('swp_mrv_plans').delete().eq('id', sequestrationWellProjectMRVPlanId).select('id').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP: async (action, listenerApi) => {
    const sequestrationWellProject = action.payload.form

    if (sequestrationWellProject.id && (sequestrationWellProject.show_on_map === true || sequestrationWellProject.show_on_map === false)) {
      return await SUPABASE.from('sequestration_well_projects').update({ show_on_map: sequestrationWellProject.show_on_map }).eq('id', sequestrationWellProject.id).select('show_on_map').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  CREATE_USER_ROLE: async (action, listenerApi) => {
    const userRole = action.payload.form

    if (userRole.user_id && userRole.role) {
      return await SUPABASE.from('user_roles').insert({
        role: userRole.role,
        user_id: userRole.user_id
      }).select('role').single()
    } else {
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
  DELETE_USER_ROLE: async (action, listenerApi) => {
    const userRoleId = action.payload.id

    if (userRoleId) {
      return await SUPABASE.from('user_roles').delete().eq('id', userRoleId).select('role').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  }, 
  UPDATE_COMPANY: async (action, listenerApi) => {
    const company = action.payload.form

    if (company.id) {
      return await SUPABASE.from('companies').update({ 
        name: company.name
      }).eq('id', company.id).select('id').single()
    } else {
      
      throw Object.assign(
        new Error('Parameter Missing'),
        { code: 400 }
      )
    }
  },
}

export default calls