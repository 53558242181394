import { createClient } from '@supabase/supabase-js'

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY

export const SUPABASE = createClient(SUPABASE_URL, SUPABASE_KEY)

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

export const LOCAL_STORAGE_KEYS = {
  PREVIOUS_PATH: 'previousPath',
}

export const ACCOUNT_DATE_FORMAT = {
  mdy: 'MMM D, YYYY',
  dmy: 'D MMM, YYYY',
  ymd: 'YYYY, MMM D',
}

export const TIMEZONE_ABBREVIATION = {
  'Greenwich Mean Time': 'GMT',
  'Coordinated Universal Time': 'UTC',
  'Western European Time': 'WET',
  'Central European Time': 'CET',
  'Central European Summer Time': 'CEST',
  'Central European Standard Time': 'CET',
  'Eastern European Time': 'EET',
  'Eastern European Summer Time': 'EEST',
  'Moscow Standard Time': 'MSK',
  'Moscow Daylight Time': 'MSD',
  'Arabian Standard Time': 'AST',
  'Arabian Daylight Time': 'ADT',
  'Iran Standard Time': 'IRST',
  'Iran Daylight Time': 'IRDT',
  'India Standard Time': 'IST',
  'Japan Standard Time': 'JST',
  'Korea Standard Time': 'KST',
  'China Standard Time': 'CST',
  'Australian Western Standard Time': 'AWST',
  'Australian Central Standard Time': 'ACST',
  'Australian Eastern Standard Time': 'AEST',
  'Australian Central Daylight Time': 'ACDT',
  'Australian Eastern Daylight Time': 'AEDT',
  'New Zealand Standard Time': 'NZST',
  'New Zealand Daylight Time': 'NZDT',
  'Pacific Standard Time': 'PST',
  'Pacific Daylight Time': 'PDT',
  'Mountain Standard Time': 'MST',
  'Mountain Daylight Time': 'MDT',
  'Central Standard Time': 'CST',
  'Central Daylight Time': 'CDT',
  'Eastern Standard Time': 'EST',
  'Eastern Daylight Time': 'EDT',
  'Atlantic Standard Time': 'AST',
  'Atlantic Daylight Time': 'ADT',
  'Newfoundland Standard Time': 'NST',
  'Newfoundland Daylight Time': 'NDT',
  'British Summer Time': 'BST',
  'Eastern European Standard Time': 'EET',
}

export const PUBLIC_PAGES = [
  "/",
  "/login"
]

export const SIDE_MENU_BG_COLOR = '#272A30'

export const SIDE_MENU_BG_SELECTED_COLOR = '#131821'

export const CHART_COLORS = [
  '#101828',
  '#57BECE'
  ]

export const MOBILE_SCREEN_WIDTH = 768

export const SEQUESTRATION_WELL_PROJECT_CATEGORIES = [
  'us_class_ii',
  'us_class_vi',
  'can_ab_class_3'
]

export const SEQUESTRATION_WELL_PROJECT_STAGES = [
  'announced',
  'permit_application',
  'issued_permit',
  'authorized_injection',
  'authorized_site_closure',
  'closed',
  'rejected',
  'withdrawn'
]

export const SEQUESTRATION_WELL_PROJECT_PERMITTING_BODIES = [
  'us_epa',
  'us_wy_deq',
  'us_nd_dmr',
  'us_tx_rrc',
  'us_la_dnr',
  'can_ab_aer',
  'can_nrc'
]

export const SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_TYPES = [
  "us_epa_id",
  "us_wy_deq_id",
  "us_nd_dmr_id",
  "us_tx_rrc_id",
  "us_la_dnr_id",
  "can_ab_aer_id",
  "can_nrc_id"
]

export const SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_PHASES = [
  'pre_construction',
  'pre_operation',
  'injection',
  'post_injection'
]

export const SEQUESTRATION_WELL_PROJECT_DOCUMENT_PURPOSES = [
  'mrv_plan',
  'permit_application',
  'issued_permit',
  'well_report'
]

export const SEQUESTRATION_WELL_PROJECT_MRV_PLAN_STATUSES = [
  'submitted',
  'approved',
  'rejected',
  'withdrawn'
]

export const LAYER_COLORS = {
  emitters: '#CE6C57',
  wells: '#57BECE'
}

export const LAYER_ICONS = {
  emitters: {
    icon_name: 'FireIcon',
    icon_type: 'solid'
  },
  wells: {
    icon_name: 'ArrowDownTrayIcon',
    icon_type: 'solid'
  }
}

export const USER_ROLES = [
  'admin'
]
