import { formatDateInString, getFileNameFromContentDispositionHeader } from '../utils'
import { API_CALL_FINISHED, API_CALL_STARTED } from './actions'
import { ACCOUNT_DATE_FORMAT } from '../constants'

export const setMessage = (state, action) => {
  state.message.title = action.payload.title
  state.message.description = action.payload.description
  state.message.type = 'success'
}

export const handleError = (state, action) => {
  state.message.title =
    action?.customTitle ?? `Could not ${action.type.toLowerCase().split('_').slice(0, -1).join(' ')}`
  state.message.description = formatDateInString(
    action.payload,
    ACCOUNT_DATE_FORMAT[state.account?.numeric_date_format],
  )
  state.message.type = 'error'
  state.message.action = action.type
}

export const handleCustomError = (title) => (state, action) => {
  handleError(state, { ...action, customTitle: title })
}

export const clearMessage = (state) => {
  state.message.title = null
  state.message.type = null
  state.message.description = null
  state.message.action = null
}

export const executeAPICalls = (API_CALLS, listenerMiddleware, supabaseCall) =>
  Object.entries(API_CALLS).forEach(([actionName, requestFunction]) => {
    listenerMiddleware.startListening({
      type: `${actionName}_REQUESTED`,
      effect: async (action, listenerApi) => {
        try {
          listenerApi.dispatch(API_CALL_STARTED(`${actionName}_REQUESTED`))
          const response = await requestFunction(action, listenerApi)
          const status = supabaseCall ? (response.status || response.error?.status) : response.statusCode
          if (status >= 400) {
            const body = supabaseCall ? { errors: [{ message: response.error.message }] } : await response.json() 
            throw new Error(body?.errors?.map(({ message }) => message).join(', '))
          }

          let payload
          if (status === 204) {
            payload = action.payload
          } else {
            if (supabaseCall) {
              payload = response.data
            } else if (Array.isArray(response)) {
              // TODO - handle array responses
              payload = null
            } else {
              const contentType = response.headers.get('content-type')
              if (contentType.includes('application/json')) {
                payload = await response.json()
              } else if (contentType.includes('text/csv')) {
                const newResponse = new Response(response.response.body)

                const blob = await newResponse.blob()

                payload = { blob, filename: getFileNameFromContentDispositionHeader(response) }
              } else {
                throw new Error('Unsupported content type: ' + contentType)
              }
            }
          }

          if (action.payload && action.payload.successCallback) {
            action.payload.successCallback()
          }

          listenerApi.dispatch({
            type: `${actionName}_SUCCEEDED`,
            payload: payload,
            requested_payload: action.payload,
          })
        } catch (error) {
          listenerApi.dispatch({
            type: `${actionName}_FAILED`,
            payload: error.message,
          })
        } finally {
          listenerApi.dispatch(API_CALL_FINISHED(`${actionName}_REQUESTED`))
        }
      },
    })
  })