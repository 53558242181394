import i18n from 'i18next'
import { useTranslation } from '../shared/hooks'
import { Typography, Tooltip } from 'antd'
import { formatDate } from '../shared/utils'

const { Text } = Typography

const DataSource = ({ data_source, textClassName }) => {
  const { t } = useTranslation('components.DataSource')

  return <Tooltip title={`${t('accessed')}: ${formatDate(data_source.access_date)}`}>
    <Text className={textClassName} type='secondary'>({t('source')}: {i18n.t(`constants.data_source.${data_source.source}`)})</Text>
  </Tooltip>
}

export default DataSource;