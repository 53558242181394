import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation, useForm } from '../shared/hooks'
import {
  Typography,
  Flex,
  Button,
  Drawer,
  Popconfirm,
  Table,
  Select
} from 'antd'
import { 
  CloseOutlined,
  PlusCircleOutlined,
  SaveOutlined
} from '@ant-design/icons'
import { 
  FETCH_USERS_REQUESTED,
  CREATE_USER_ROLE_REQUESTED,
  DELETE_USER_ROLE_REQUESTED
} from '../redux/actions'
import {
  USER_ROLES
} from '../shared/constants'
import i18n from 'i18next'

const { Title, Text, Link } = Typography

const UserAdminDrawer = ({
  session,
  isLoadingFor,
  fetchUsers,
  createUserRoleRequest,
  deleteUserRoleRequest,
  user,
  open,
  onClose
}) => {
  const [addUserRoleState, setAddUserRoleState] = useState(false)
  const { form: userRoleForm, setForm: setUserRoleForm, handleChange: handleChangeUserRole } = useForm({})
  const { t } = useTranslation('components.UserAdminDrawer')

  useEffect(() => {
    closeUserRoleForm()
  }, [open])

  const openUserRoleForm = () => {
    setUserRoleForm({
      role: null
    })
    setAddUserRoleState(true)
  }

  const closeUserRoleForm = () => {
    setAddUserRoleState(false)
  }

  const userRoleOptions = [...USER_ROLES].filter(ur => user?.user_roles?.findIndex(uur => uur.role === ur) === -1).map(ur => ({
    value: ur,
    label: i18n.t(`constants.user_role.${ur}`)
  }))


  const createUserRole = () => createUserRoleRequest({ 
    form: {
      ...userRoleForm,
      user_id: user?.id
    },
    successCallback: () => {
      closeUserRoleForm()
      fetchUsers()
    }
  })

  const deleteUserRole = (id) => deleteUserRoleRequest({ id, successCallback: () => fetchUsers() })

  const userRoleTableColumns = [
    {
      title: t('user_role.id'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('user_role.role'),
      dataIndex: 'role',
      key: 'role',
      render: (text) => i18n.t(`constants.user_role.${text}`)
    },
    {
      key: 'CRUD',
      render: (_, record) => session.user.id !== user.id || record.role !== 'admin' ? <Popconfirm
        title={`Delete ${record.id}`}
        description="Are you sure to delete this user role?"
        onConfirm={() => deleteUserRole(record.id)}
        okText="Yes"
        cancelText="No"
      >
        <Link>{t('delete')}</Link>
      </Popconfirm> : <></>
    }
  ]

  return <Drawer
      title={
        <Flex justify="space-between">
          <Title className="m-0" level={3}>
            {user?.username}
          </Title>
          <Flex align='center'>
            <Button type='text' size='small' onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Flex>
        </Flex>
      }
      size='large'
      closeIcon={false}
      onClose={onClose}
      open={open}>
      <Flex vertical className='gap-2'>
        <Title level={5}>{t('title.user_roles')}</Title>
        <Table
          rowKey={r => r.id}
          size='small'
          pagination={false}
          columns={userRoleTableColumns}
          dataSource={user?.user_roles} />
        { addUserRoleState ? <Flex className='gap-4'>
          <Select 
            className='w-full'
            name='role'
            options={userRoleOptions}
            value={userRoleForm.role}
            onChange={handleChangeUserRole('role')} 
            placeholder={t('user_role.role_placeholder')}/>
          <Flex className='gap-2'>
            <Button className='min-w-8' icon={<CloseOutlined />} onClick={closeUserRoleForm} />
            <Button type='primary' className='min-w-8' icon={<SaveOutlined />} onClick={createUserRole} loading={isLoadingFor.CREATE_USER_ROLE_REQUESTED} />
          </Flex>
        </Flex> : <Button type='text' shape='circle' className='ml-auto' icon={<PlusCircleOutlined />} onClick={openUserRoleForm} /> }
      </Flex>
    </Drawer>
}

function mapStateToProps(state) {
  const { session, isLoadingFor } = state
  return {
    session,
    isLoadingFor
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchUsers: () => dispatch(FETCH_USERS_REQUESTED()),
    createUserRoleRequest: ({ form, successCallback }) => dispatch(CREATE_USER_ROLE_REQUESTED({ form, successCallback })),
    deleteUserRoleRequest: ({ id, successCallback }) => dispatch(DELETE_USER_ROLE_REQUESTED({ id, successCallback }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdminDrawer)