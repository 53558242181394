import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const Sidebar = ({ children, className, style = {}, startExpanded = false, forceExpanded, collapseButtonPosition = 'top-right', expandButton, collapseButton, onExpand, onCollapse, showCollapseButton, direction = 'vertical', collapseButtonSize = 'default', collapseButtonType = null, collapseButtonClassName = '' }) => {
  const [expanded, setExpanded] = useState(startExpanded)

  useEffect(() => {
    if (expanded) {
      if (expandButton?.current) expandButton.current.classList.add('!hidden')
    } else {
      if (collapseButton?.current) collapseButton.current.classList.add('!hidden')
    }
  }, [])

  const expand = () => {
    setExpanded(true)
    if (expandButton?.current) expandButton.current.classList.add('!hidden')
    if (collapseButton?.current) collapseButton.current.classList.remove('!hidden')
    if (onExpand) onExpand()
  }

  const collapse = () => {
    setExpanded(false)
    if (expandButton?.current) expandButton.current.classList.remove('!hidden')
    if (collapseButton?.current) collapseButton.current.classList.add('!hidden')
    if (onCollapse) onCollapse()
  }

  if (forceExpanded !== undefined && forceExpanded !== null && forceExpanded !== expanded) setExpanded(forceExpanded)

  if (expandButton?.current) expandButton.current.onclick = expand
  if (collapseButton?.current) collapseButton.current.onclick = collapse

  let collapseButtonWrapperClassName = 'top-1 right-1'
  switch(collapseButtonPosition) {
    case 'top-left':
      collapseButtonWrapperClassName = 'top-1 left-1'
      break;
    case 'bottom-right':
      collapseButtonWrapperClassName = 'bottom-1 right-1'
      break;
    case 'bottom-left':
      collapseButtonWrapperClassName = 'bottom-1 left-1'
      break;
    default:
      break;
  }

  return (
    <div className={`relative ${direction === 'vertical' ? 'max-h-full' : ''} overflow-hidden ${className} ${expanded ? '' : 'hidden'}`} style={style}>
      <div className='absolute left-0 right-0 top-0 bottom-0 flex flex-col'>
        {children}
      </div>
      { showCollapseButton ? <div className={`absolute ${collapseButtonWrapperClassName}`}>
        <Button onClick={collapse} icon={<CloseOutlined />} size={collapseButtonSize} type={collapseButtonType} className={`absolute ${collapseButtonClassName}`} />
      </div> : '' }
    </div>)
}

export default Sidebar;
