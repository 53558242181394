import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout, Menu, theme, Button } from 'antd'
import logo from '../assets/images/atlas_branding_knockout.svg'
import { SUPABASE, MOBILE_SCREEN_WIDTH } from '../shared/constants'
import { useTranslation } from '../shared/hooks'

const { Content, Header } = Layout;

const AuthorizedLayout = ({ user_role }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const navigate = useNavigate();
  const { t } = useTranslation('layouts.Authorized')

  const {
    token: { 
      colorPrimary,
      Menu: {
        darkItemSelectedBg
      }
    }
  } = theme.useToken();

  async function logout() {
    const { error } = await SUPABASE.auth.signOut()
    navigate("/")
  }

  function handleWindowSizeChange() {
      setScreenWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = screenWidth <= MOBILE_SCREEN_WIDTH;

  // if (user_role === 'admin') navigationLinks.push({
  //   key: '/admin',
  //   label: <> <SolutionOutlined /> { t('navigation.admin') } </>
  // })

  // const navigationItems = navigationLinks.map(n => {
  //   return <Menu.Item key={n.key} onClick={() => navigate(n.key)} className={n.className}>
  //     {n.label}
  //   </Menu.Item>
  // })

  // const items = [...navigationItems, ...[]];
  
  return (
    <Layout className='!min-h-screen flex flex-col'>
      <Header className={`flex items-center ${isMobile ? '!px-2' : '!px-4'}`}>
        <a className='h-full py-4 cursor-pointer flex' href='/' >
          <img className='object-cover h-3/4 m-auto' src={logo} alt= { t('alt.logo') }  />
        </a>
        { /* <Menu
          theme='dark'
          mode="horizontal"
          selectedKeys={[window.location.pathname]}
          style={{ flex: 1, minWidth: 0 }}>
         {items}
        </Menu> */ }
        <Button type='primary' className='ml-auto' key='logout' onClick={logout}>
           { t('navigation.logout') }
        </Button>
      </Header>
      <Content className="grow flex">
        <Outlet />
      </Content>
    </Layout>
  );
}

function mapStateToProps(state) {
  const { user_role } = state
  return {
    user_role
 }
}

export default connect(mapStateToProps)(AuthorizedLayout);