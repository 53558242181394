import { Space, Flex } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const OverviewDrawer = ({ header, body, loading }) => {
  if (loading) {
    return <div className='m-auto w-fit h-fit'>
      <LoadingOutlined className='text-8xl' />
    </div> 
  }

  return  <div className='flex flex-col max-h-full'>
        <Space direction='vertical' className='bg-[#0C131F] px-4 py-6'>
          { header }
        </Space>
        <div className='overflow-y-scroll'>
          { body }
        </div>
      </div>
}

export default OverviewDrawer;