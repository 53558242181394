import { useTranslation } from '../shared/hooks'
import { Typography, Flex, Button, Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons';

const { Title } = Typography

const CompanyInfoDrawer = ({
  onClose,
  open,
  company,
  placement
}) => {
  const { t } = useTranslation('components.CompanyInfoDrawer')

  return <Drawer
    placement={placement}
    title={
      <Flex justify="space-between">
        <Title className="m-0" level={3}>
          {company?.name}
        </Title>
        <Button type='text' size='small' onClick={onClose}>
          <CloseOutlined />
        </Button>
      </Flex>
    }
    size='large'
    closeIcon={false}
    onClose={onClose}
    open={open}>
  </Drawer>
}

export default CompanyInfoDrawer