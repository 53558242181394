import { Tag } from 'antd'
import i18n from 'i18next'

const SequestrationWellProjectStageTag = ({ project_stage }) => {
  let color = 'default'
  // switch (project_stage) {
  //   case 'permit_application':
  //     color='processing'
  //     break;
  //   case 'issued_permit':
  //   case 'authorized_injection':
  //   case 'authorized_site_closure':
  //     color='success'
  //     break;
  //   case 'withdrawn':
  //     color = 'warning'
  //     break;
  //   case 'rejected':
  //     color = 'error'
  //     break;
  //   default:
  //     break;
  // }

  return <Tag align='center' color={color}>
    {i18n.t(`constants.sequestration_well_project_stage.${project_stage}`)}
  </Tag>
}

export default SequestrationWellProjectStageTag;
