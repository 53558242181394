import { Flex, Typography } from 'antd'
import * as SolidHeroIcons from '@heroicons/react/24/solid'

const { Text } = Typography

const IconLabel = ({ title, color, icon_name = '', icon_type = 'solid', className }) => {
  const titleText = <Text className='font-extrabold' style={{ color }}>{title}</Text>
  if (!icon_name) return titleText

  const HeroIcon = SolidHeroIcons[icon_name]

  return <Flex align='center' className={`!inline-flex ${className}`}>
    <Flex className='rounded-full border-white border-[1px] border-solid mr-2 p-[2px] size-4 h-auto' style={{ background: color }}>
      <HeroIcon className='text-white' />
    </Flex>
    {titleText}
  </Flex>
}

export default IconLabel;