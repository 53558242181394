import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout, Button, Flex, Popover } from 'antd'
import logo from '../assets/images/atlas_branding_knockout.svg'
import { useTranslation } from '../shared/hooks'
import GetProBadge from '../components/GetProBadge'
import { green } from '@ant-design/colors';
import { MOBILE_SCREEN_WIDTH } from '../shared/constants'

const { Header, Content } = Layout;

const LoggedOutLayout = ({ session }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { t } = useTranslation('layouts.Unauthorized')
  const navigate = useNavigate()

  function handleWindowSizeChange() {
      setScreenWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = screenWidth <= MOBILE_SCREEN_WIDTH;

  return (
    <Layout className='!min-h-screen'>
      <Header className={`flex justify-between ${isMobile ? '!px-2' : '!px-4'}`}>
        <a className='h-full py-4 cursor-pointer flex' href='/' >
          <img className='object-cover h-3/4 m-auto' src={logo} alt= { t('alt.logo') }  />
        </a>
        { session ? 
          <Flex align='center'>
            <Button type='primary' onClick={() => navigate('/account')} >{t('account')}</Button>
          </Flex> :
          <Flex className='gap-2' align='center'>
            { isMobile ? <></> : <Popover arrow={false} content={<iframe className='size-96' src='https://www.mangrovesystems.com/atlas-emailsignup' />} trigger='click'>
              <Button type='text' className='!text-white'>{ t('subscribe') }</Button>
            </Popover> }
            <GetProBadge className={isMobile ? '!px-2' : '' } badgeText={t('get_pro_license')} tooltipPlacement='left' backgroundColor={green[6]} />
          </Flex>
        }
      </Header>
      <Content className="grow flex">
        <Outlet />
      </Content>
    </Layout>
  );
}

function mapStateToProps(state) {
  const { session } = state
  return {
    session
 }
}

export default connect(mapStateToProps)(LoggedOutLayout);