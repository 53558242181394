import React from 'react'
import { connect } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { Button, Typography } from 'antd'
import logo from '../assets/images/logo_blue.png'
import { useTranslation } from '../shared/hooks'
import CarbonMap from '../components/CarbonMap'


const { Title } = Typography;

const Landing = ({ session }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('pages.Landing')

  return (
    <>
      <CarbonMap />
    </>)
}

function mapStateToProps(state) {
  const { session } = state
  return {
    session
 }
}

export default connect(mapStateToProps)(Landing);
