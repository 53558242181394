import { useState } from 'react'
import { Typography, Flex, Button, Divider, Alert, Row, Col, Statistic, Space, Tag, Tooltip, theme } from 'antd'
import { useTranslation } from '../shared/hooks'
import { LAYER_COLORS, LAYER_ICONS } from '../shared/constants'
import { ArrowRightOutlined } from '@ant-design/icons'
import OverviewDrawer from './OverviewDrawer'
import OverviewSubheading from './OverviewSubheading'
import IconLabel from './IconLabel'
import GetProBadge from './GetProBadge'
import i18n from 'i18next'
import { formatFloat, isValidURL, formatDateNoTimezone } from '../shared/utils'
import SequestrationWellProjectStageTag from './SequestrationWellProjectStageTag'
import SequestrationWellProjectStatusTag from './SequestrationWellProjectStatusTag'
import SequestrationWellProjectInfoDrawer from './SequestrationWellProjectInfoDrawer'

const { Title, Text, Link } = Typography

const animationDuration = 1000

const PRO = false;

const SequestrationWellProjectOverview = ({
    sequestrationWellProject
  }) => {
  const [fakeInjectionRate, setFakeInjectionRate] = useState((Math.random()*900) + 100)
  const [openDrawer, setOpenDrawer] = useState(false)
  const { t } = useTranslation('components.SequestrationWellProjectOverview')

  const {
    token: { 
      Typography: {
        colorTextDescription
      }
    }
  } = theme.useToken();

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  return <>
    <OverviewDrawer
      header={<>
        <IconLabel title={t('badge_title').toUpperCase()} icon_name={LAYER_ICONS.wells.icon_name} icon_type={LAYER_ICONS.wells.icon_type} color={LAYER_COLORS.wells} />
        <Title className='!text-white !my-0' level={3}>{sequestrationWellProject.name}</Title>
        <Flex vertical>
          { sequestrationWellProject.well_category ? <Text className='!text-white'>{i18n.t(`constants.sequestration_well_project_category.${sequestrationWellProject.well_category}`)}</Text> : <></> }
          { sequestrationWellProject.project_developer ? <Text className='!text-white'>{sequestrationWellProject.project_developer?.name}</Text> : <></> }
        </Flex>
        { PRO ? <Button onClick={showDrawer} type='primary'>{t('label.view_well_project')} <ArrowRightOutlined /></Button> : <></> }
      </>}
      body={<Flex className='p-4' vertical>
        <OverviewSubheading text={t('heading.storage_metrics').toUpperCase()} />
        <Row gutter={[12, 0]}>
          <Col span={12}>
            <div className='shadow border p-4 h-full'>
              <Statistic title={t('label.total_capacity')} value={sequestrationWellProject.total_capacity} formatter={(v) => sequestrationWellProject.total_capacity ? formatFloat(v, { minimumFractionDigits: 1 }) : '-'} valueStyle={{ 'margin-top': 'auto' }} className='h-full flex-col flex' />
            </div>
          </Col>
          <Col span={12}>
            <div className='shadow border p-4 h-full' >
              <Statistic 
                title={t('label.expected_injection_rate')}
                value={Math.round(fakeInjectionRate)}
                formatter={(text) => <GetProBadge body={<span className='!text-transparent' style={{ 'text-shadow': '0 0 12px rgba(0,0,0,1)' }}>{formatFloat(text)}</span>} />} />
            </div>
          </Col>
        </Row>
        <Divider className='!mb-2' />
        <OverviewSubheading text={t('heading.details').toUpperCase()} />
        <Space direction='vertical'>
          <Flex>
            <Text type='secondary'>{t('label.num_wells')}: {sequestrationWellProject.sequestration_wells.length}</Text>
            { PRO && sequestrationWellProject.sequestration_wells.length ? <Link onClick={showDrawer} className='ml-2'>View Wells</Link> : <></> }
          </Flex>
          { sequestrationWellProject.lease_area ? <Text type='secondary'>{t('label.lease_area')}: {sequestrationWellProject.lease_area}</Text> : <></> }
          { sequestrationWellProject.external_reference_url ? <Flex>
            <Text type='secondary' className='mr-2'>{t('label.source_url')}: </Text>
            { isValidURL(sequestrationWellProject?.external_reference_url)
              ? <Link href={sequestrationWellProject.external_reference_url} rel='noreferrer' target='_blank'>{t('label.link')}</Link>
              : <Text>{sequestrationWellProject.external_reference_url}</Text> }
          </Flex> : <></> }
        </Space>
        <Divider className='!mb-2' />
        <OverviewSubheading text={t('heading.project_status').toUpperCase()} />
        <Space direction='vertical'>
          <Flex align='center'>
            <Text type='secondary' className='mr-2' >{t('label.status')}: </Text>
            <SequestrationWellProjectStatusTag sequestrationWellProject={sequestrationWellProject} />
          </Flex>
          { sequestrationWellProject.active_date ? <Text type='secondary'>{t('label.active_since')}: {formatDateNoTimezone(sequestrationWellProject.active_date)}</Text> : <></> }
          { sequestrationWellProject.project_stage ? <Flex align='center'>
            <Text type='secondary' className='mr-2'>{t('label.permitting_stage')}: </Text>
            <Tooltip title={`${t('label.since')}: ${formatDateNoTimezone(sequestrationWellProject.date_current_stage_reached)}`}><><SequestrationWellProjectStageTag project_stage={sequestrationWellProject.project_stage} /></></Tooltip>
          </Flex> : <></> }
          { sequestrationWellProject.permitting_body ? <Text type='secondary'>{t('label.permitting_body')}: {i18n.t(`constants.sequestration_well_project_permitting_body.${sequestrationWellProject.permitting_body}`)}</Text> : <></> }
        </Space>
        <Divider className='!mb-4' />
        <Alert
          className='!p-4'
          type='success'
          message={<Text strong>{t('get_pro.title')}:</Text>}
          action={<GetProBadge className='ml-auto' />}
          description={<ul className='list-disc pl-6' style={{ color: colorTextDescription }}>
            <li> 
              <Text type='secondary'>{t('get_pro.bullet_1')}</Text>
            </li>
            <li> 
              <Text type='secondary'>{t('get_pro.bullet_2')}</Text>
            </li>
            <li> 
              <Text type='secondary'>{t('get_pro.bullet_3')}</Text>
            </li>
          </ul>} />
      </Flex>} />
      { PRO ? <SequestrationWellProjectInfoDrawer
        open={openDrawer}
        onClose={closeDrawer}
        sequestrationWellProject={sequestrationWellProject}
        placement='left' /> : <></> }
    </>
}

export default SequestrationWellProjectOverview;