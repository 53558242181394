import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  FETCH_COUNTRIES_REQUESTED,
  FETCH_COUNTRY_SUBDIVISIONS_REQUESTED
} from '../redux/actions'
import { Table, Input, Menu, Typography, theme } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from '../shared/hooks'
import { MOBILE_SCREEN_WIDTH, LAYER_COLORS, LAYER_ICONS } from '../shared/constants'
import { formatInteger } from '../shared/utils'
import GetProBadge from '../components/GetProBadge'
import IconLabel from './IconLabel'
import SequestrationWellProjectStatusTag from './SequestrationWellProjectStatusTag'
import dayjs from 'dayjs'

const { Text } = Typography

const ellipsisTooltip = (text) => <Text ellipsis={{ tooltip: text }}>{text}</Text>

const AddressText = ({ countries, country_subdivisions, isLoadingFor, fetchCountries, fetchCountrySubdivisions, layers, rowClick, selectedFeatureId, data, emissionsFilteredYear, clearSelectedFeatureId }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [selectedLayerId, setSelectedLayerId] = useState((layers || [])[0]?.id)
  const [search, setSearch] = useState('')
  const { t } = useTranslation('components.MapFeatureList')

  const {
    token: { 
      colorBgContainer,
      borderRadiusLG,
      colorTextQuaternary,
      Menu: {
        darkItemBg
      }
    }
  } = theme.useToken();

  useEffect(() => {
    if (!countries.length && !isLoadingFor.FETCH_COUNTRIES_REQUESTED) fetchCountries()
  }, [isLoadingFor.FETCH_COUNTRIES_REQUESTED, countries])

  useEffect(() => {
    if (!country_subdivisions.length && !isLoadingFor.FETCH_COUNTRY_SUBDIVISIONS_REQUESTED) fetchCountrySubdivisions()
  }, [isLoadingFor.FETCH_COUNTRY_SUBDIVISIONS_REQUESTED, country_subdivisions])

  function handleWindowSizeChange() {
      setScreenWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = screenWidth <= MOBILE_SCREEN_WIDTH;

  const onSearch = (value) => setSearch(value)

  const selectedLayerData = data.find(n => selectedLayerId === n.id)?.data
  const selectedLayerFeatures = (selectedLayerData?.features || []).sort((a, b) => a.properties.quantity_co2e < b.properties.quantity_co2e ? 1 : a.properties.quantity_co2e > b.properties.quantity_co2e ? -1 : 0)

  const countryText = (record) => [country_subdivisions.find(cs => cs.id === record.country_subdivision_id)?.name, countries.find(c => c.id === record.country_id)?.name].filter(s => s).join(', ')

  let columns = []
  let mappedData = []
  switch (selectedLayerId) {
    case 'emitters':
      columns = [
        {
          title: t('emitters.name'),
          dataIndex: 'name',
          key: 'name',
          render: ellipsisTooltip
        },
        {
          title: <Text ellipsis>{t('emitters.annual_emissions_co2e')} ({emissionsFilteredYear})</Text>,
          dataIndex: 'emissions',
          key: 'emissions',
          render: (text) => formatInteger(Math.round(text))
        },
        ... !isMobile ? [{
          title: t('emitters.location'),
          render: (text, record) => ellipsisTooltip(countryText(record)),
          key: 'location'
        }] : [],
        {
          title: <Text ellipsis>{t('emitters.capture_project')}</Text>,
          render: () => <GetProBadge type='badge' />,
        }
      ]
      
      mappedData = selectedLayerFeatures.filter(n => n.properties.name.toUpperCase().includes(search.toUpperCase())).map(n => ({
          id: n.properties.id,
          name: n.properties.name,
          emissions: n.properties.quantity_co2e || 0,
          country_id: n.properties.country_id,
          country_subdivision_id: n.properties.country_subdivision_id
        }))
      break;
    case 'wells':
      columns = [
        {
          title: t('wells.name'),
          dataIndex: 'name',
          key: 'name',
          render: ellipsisTooltip
        },
        ... !isMobile ? [{
          title: t('wells.project_developer'),
          dataIndex: 'project_developer',
          key: 'project_developer',
          render: ellipsisTooltip
        }] : [],
        {
          title: t('wells.num_wells'),
          dataIndex: 'num_wells',
          key: 'num_wells',
          render: (text, record) => formatInteger(text)
        },
        ... !isMobile ? [{
          title: t('wells.location'),
          render: (text, record) => ellipsisTooltip(countryText(record)),
          key: 'location'
        }] : [],
        {
          title: t('wells.status'),
          dataIndex: 'status',
          render: (text, record) => <SequestrationWellProjectStatusTag sequestrationWellProject={record}/>,
        },
        ... !isMobile ? [{
          title: t('wells.partners'),
          render: (text, record) => <GetProBadge type='badge' />
        }] : []
      ]
      
      mappedData = selectedLayerFeatures.filter(n => n.properties.name.toUpperCase().includes(search.toUpperCase())).map(n => ({
          id: n.properties.id,
          name: n.properties.name,
          project_developer: n.properties.project_developer?.name,
          num_wells: n.properties.sequestration_wells?.length || 0,
          country_id: n.properties.address.country_id,
          country_subdivision_id: n.properties.address.country_subdivision_id,
          status: dayjs(n.properties.active_date) <= dayjs(),
          active_date: n.properties.active_date
        }))
      break;
    default:
      break;
  }

  return <>
    <div style={{ background: darkItemBg }} className='flex' >
      <Menu mode='horizontal' className='w-full' items={layers.map(n => ({ 
        key: n.id,
        label: <IconLabel title={t(`layer_names.${n.id}`).toUpperCase()} icon_name={LAYER_ICONS[n.id].icon_name} icon_type={LAYER_ICONS[n.id].icon_type} color={LAYER_COLORS[n.id]} />,
        icon: <LoadingOutlined className={(isLoadingFor.FETCH_MAP_DATA_REQUESTED && n.id === 'emitters') || (n.id === 'wells' && isLoadingFor.FETCH_SEQUESTRATION_WELL_PROJECTS_REQUESTED) ? '' : '!hidden'} />
      }))}
      selectedKeys={[selectedLayerId]}
      onClick={(item) => setSelectedLayerId(item.key)} />
      <Input.Search placeholder={t('search')} onSearch={onSearch} className='ml-auto my-auto max-w-96 mr-2 !rounded-none' />
    </div>
    <div className='max-h-full h-full'>
      <Table
        size='small'
        className='[&>div>div>div>div>div>table>thead>tr>th]:!py-[2px]'
        rowClassName={() => { return '[&>*]:!py-[2px]' }}
        style={{ borderRadius: borderRadiusLG }}
        rowSelection={{
          selectedRowKeys: [selectedFeatureId],
          renderCell: () => <></>,
          hideSelectAll: true
        }}
        rowKey={'id'}
        columns={columns}
        dataSource={mappedData}
        scroll={{ y: 135 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              const newFeatureId = selectedFeatureId !== record.id
              clearSelectedFeatureId()

              if (newFeatureId && rowClick) rowClick(selectedLayerId, record.id)
            }, // click row
          };
        }}
        />
    </div>
  </>
}

function mapStateToProps(state) {
  const {
    countries,
    country_subdivisions,
    isLoadingFor
  } = state
  return {
    countries,
    country_subdivisions,
    isLoadingFor
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchCountries: () => dispatch(FETCH_COUNTRIES_REQUESTED()),
    fetchCountrySubdivisions: () => dispatch(FETCH_COUNTRY_SUBDIVISIONS_REQUESTED())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressText);