import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from '../shared/hooks'
import { Typography, Flex, Button, Drawer, Input, Form, DatePicker, Select, InputNumber, Space } from 'antd'
import { CloseOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useForm } from '../shared/hooks'
import {
  SEQUESTRATION_WELL_PROJECT_CATEGORIES,
  SEQUESTRATION_WELL_PROJECT_STAGES,
  SEQUESTRATION_WELL_PROJECT_PERMITTING_BODIES
} from '../shared/constants'
import i18n from 'i18next'
import {
  FETCH_COUNTRIES_REQUESTED,
  FETCH_COUNTRY_SUBDIVISIONS_REQUESTED,
  CREATE_SEQUESTRATION_WELL_PROJECT_REQUESTED,
  UPDATE_SEQUESTRATION_WELL_PROJECT_REQUESTED
} from '../redux/actions'

const { Title } = Typography

const initialFormState = (sequestrationWellProject) => ({
  ...sequestrationWellProject
})

const SequestrationWellFormDrawer = ({ 
  countries,
  country_subdivisions,
  isLoadingFor,
  fetchCountries,
  fetchCountrySubdivisions,
  createSequestrationWellProject,
  updateSequestrationWellProject,
  onClose,
  open,
  sequestrationWellProject
}) => {
  const { form, setForm, handleChange } = useForm({})
  const [countryOptions, setCountryOptions] = useState([])
  const [countrySubdivisionOptions, setCountrySubdivisionOptions] = useState([])

  useEffect(() => {
    setForm(initialFormState(sequestrationWellProject))
  }, [open])

  useEffect(() => {
    if (!countries.length && !isLoadingFor.FETCH_COUNTRIES_REQUESTED) fetchCountries()
  }, [isLoadingFor.FETCH_COUNTRIES_REQUESTED, countries])

  useEffect(() => {
    if (!country_subdivisions.length && !isLoadingFor.FETCH_COUNTRY_SUBDIVISIONS_REQUESTED) fetchCountrySubdivisions()
  }, [isLoadingFor.FETCH_COUNTRY_SUBDIVISIONS_REQUESTED, country_subdivisions])

  useEffect(() => {
    if (countries.length) setCountryOptions([...countries]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(country => ({
        label: country.name,
        value: country.id
      }))
    )
  }, [countries])

  useEffect(() => {
    if (country_subdivisions.length && form.address?.country_id) setCountrySubdivisionOptions([...country_subdivisions]
      .filter(countrySubdivision => countrySubdivision.country_id === form.address?.country_id)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(countrySubdivision => ({
        label: countrySubdivision.name,
        value: countrySubdivision.id
      }))
    )
  }, [country_subdivisions, form.address?.country_id])

  const { t } = useTranslation('components.SequestrationWellFormDrawer')

  const wellCategoryOptions = SEQUESTRATION_WELL_PROJECT_CATEGORIES.map(wc => ({
    value: wc,
    label: i18n.t(`constants.sequestration_well_project_category.${wc}`)
  }))

  const permittingBodyOptions = SEQUESTRATION_WELL_PROJECT_PERMITTING_BODIES.map(pb => ({
    value: pb,
    label: i18n.t(`constants.sequestration_well_project_permitting_body.${pb}`)
  }))

  const projectStageOptions = SEQUESTRATION_WELL_PROJECT_STAGES.map(s => ({
    value: s,
    label: i18n.t(`constants.sequestration_well_project_stage.${s}`)
  }))

  return <Drawer
      title={<Flex justify="space-between">
          <Title className="m-0" level={3}>
            {sequestrationWellProject?.id || t('title_new')}
          </Title>
          <Button type='text' size='small' onClick={onClose}>
            <CloseOutlined />
          </Button>
        </Flex>}
      size='large'
      closeIcon={false}
      onClose={onClose}
      open={open}>
      <Form layout='vertical'>
        <Form.Item label={t('form.name')} >
          <Input
            name='name'
            value={form.name}
            onChange={handleChange('name')} />
        </Form.Item>
        <Form.Item label={t('form.description')} >
          <Input.TextArea
            name='description'
            value={form.description}
            onChange={handleChange('description')} />
        </Form.Item>
        <Form.Item label={t('form.well_category')} >
          <Select
            name='well_category'
            options={wellCategoryOptions}
            value={form.well_category}
            onChange={handleChange('well_category')}
            placeholder={t('form.well_category_placeholder')}
            allowClear />
        </Form.Item>
        <Form.Item label={t('form.permitting_body')} >
          <Select
            name='permitting_body'
            options={permittingBodyOptions}
            value={form.permitting_body}
            onChange={handleChange('permitting_body')}
            placeholder={t('form.permitting_body_placeholder')}
            allowClear />
        </Form.Item>
        <Form.Item label={t('form.active_date')} >
          <DatePicker
            className='!w-full'
            name='active_date'
            value={form.active_date}
            onChange={handleChange('active_date')} />
        </Form.Item>
        <Form.Item label={t('form.total_capacity')} >
          <InputNumber
            className='!w-full'
            suffix={t('form.total_capacity_unit')}
            name='total_capacity'
            value={form.total_capacity}
            min={0}
            onChange={handleChange('total_capacity')} />
        </Form.Item>
        <Form.Item label={t('form.expected_annual_injection_rate')} >
          <InputNumber
            className='!w-full'
            suffix={t('form.expected_annual_injection_rate_unit')}
            name='expected_annual_injection_rate'
            value={form.expected_annual_injection_rate}
            min={0}
            onChange={handleChange('expected_annual_injection_rate')} />
        </Form.Item>
        <Form.Item label={t('form.lease_area')} >
          <InputNumber
            className='!w-full'
            suffix={t('form.lease_area_unit')}
            name='lease_area'
            value={form.lease_area}
            min={0}
            onChange={handleChange('lease_area')} />
        </Form.Item>
        <Form.Item label={t('form.project_stage')} >
          <Select
            name='project_stage'
            options={projectStageOptions}
            value={form.project_stage}
            onChange={handleChange('project_stage')}
            placeholder={t('form.project_stage_placeholder')}
            allowClear />
        </Form.Item>
        <Form.Item label={t('form.date_current_stage_reached')} >
          <DatePicker
            className='!w-full'
            name='date_current_stage_reached'
            value={form.date_current_stage_reached}
            onChange={handleChange('date_current_stage_reached')} />
        </Form.Item>
        <Form.Item
          label={t('form.external_reference_url')}
          tooltip={t('form.external_reference_url_tooltip')} >
          <Input
            name='external_reference_url'
            value={form.external_reference_url}
            onChange={handleChange('external_reference_url')} />
        </Form.Item>
        <Form.Item
          label={t('form.location')}
          tooltip={t('form.location_tooltip')}>
          <Flex className='gap-4'>
            <InputNumber
              className='!w-full'
              name='location.latitude'
              value={form.location?.latitude}
              min={-90}
              max={90}
              onChange={handleChange('location.latitude')}
              placeholder={t('form.latitude_placeholder')} />
            <InputNumber
              className='!w-full'
              name='location.longitude'
              value={form.location?.longitude}
              min={-180}
              max={180}
              onChange={handleChange('location.longitude')}
              placeholder={t('form.longitude_placeholder')} />
          </Flex>
        </Form.Item>
        <Form.Item
          label={t('form.address1')} >
          <Input
            name='address.address1'
            value={form.address?.address1}
            onChange={handleChange('address.address1')} />
        </Form.Item>
        <Form.Item
          label={t('form.address2')} >
          <Input
            name='address.address2'
            value={form.address?.address2}
            onChange={handleChange('address.address2')} />
        </Form.Item>
        <Form.Item
          label={t('form.city')} >
          <Input
            name='address.city'
            value={form.address?.city}
            onChange={handleChange('address.city')} />
        </Form.Item>
        <Form.Item
          label={t('form.zip')} >
          <Input
            name='address.zip'
            value={form.address?.zip}
            onChange={handleChange('address.zip')} />
        </Form.Item>
        <Form.Item label={t('form.country')} >
          <Select
            name='country_id'
            options={countryOptions}
            showSearch
            optionFilterProp="label"
            value={form.address?.country_id}
            onChange={handleChange('address.country_id')}
            placeholder={t('form.country_placeholder')}
            allowClear />
        </Form.Item>
        <Form.Item label={t('form.country_subdivision')} >
          <Select
            disabled={!form.address?.country_id}
            name='country_subdivision_id'
            options={countrySubdivisionOptions}
            showSearch
            optionFilterProp="label"
            value={form.address?.country_subdivision_id}
            onChange={handleChange('address.country_subdivision_id')}
            placeholder={t('form.country_subdivision_placeholder')}
            allowClear />
        </Form.Item>
        <Form.Item label={t('form.co2_sources')} >
          <Input.TextArea
            name='co2_sources'
            value={form.co2_sources}
            onChange={handleChange('co2_sources')} />
        </Form.Item>
      </Form>
      <Space align="end" className="self-end">
        <Button
          type="primary"
          loading={false}
          icon={<SaveOutlined />}
          onClick={() => { 
            if (form.id) {
              updateSequestrationWellProject({ form, successCallback: onClose })
            } else {
              createSequestrationWellProject({ form, successCallback: onClose })
            }
          }}
        >
          {t('form.save')}
        </Button>
        <Button onClick={onClose}>{t('form.cancel')}</Button>
      </Space>
    </Drawer>
}

function mapStateToProps(state) {
  const {
    countries,
    country_subdivisions,
    isLoadingFor
  } = state
  return {
    countries,
    country_subdivisions,
    isLoadingFor
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchCountries: () => dispatch(FETCH_COUNTRIES_REQUESTED()),
    fetchCountrySubdivisions: () => dispatch(FETCH_COUNTRY_SUBDIVISIONS_REQUESTED()),
    createSequestrationWellProject: ({ form, successCallback }) => dispatch(CREATE_SEQUESTRATION_WELL_PROJECT_REQUESTED({ form, successCallback })),
    updateSequestrationWellProject: ({ form, successCallback }) => dispatch(UPDATE_SEQUESTRATION_WELL_PROJECT_REQUESTED({ form, successCallback }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SequestrationWellFormDrawer)