import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Typography, Table } from 'antd'
import { useTranslation } from '../shared/hooks'
import { FETCH_USERS_REQUESTED } from '../redux/actions'
import UserAdminDrawer from './UserAdminDrawer'
import i18n from 'i18next'

const { Title } = Typography

const UserList = ({ users, isLoadingFor, fetchUsers }) => {
  const [selectedUserId, setSelectedUserId] = useState(null)
  const { t } = useTranslation('components.UserList')

  const selectedUser = users?.find(u => u.id === selectedUserId)

  useEffect(() => {
    if (!users && !isLoadingFor.FETCH_USERS_REQUESTED) fetchUsers()
  }, [isLoadingFor.FETCH_USERS_REQUESTED])

  const columns = [
    {
      title: t('columns.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('columns.username'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: t('columns.roles'),
      dataIndex: 'roles',
      key: 'roles',
    }
  ]

  const tableSelectionProps = {
    type: 'radio',
    onChange: e => setSelectedUserId(e[0]),
    selectedRowKeys: [selectedUserId]
  }

  const data = users?.map(u => {
    return {
      id: u.id,
      username: u.username,
      roles: u.user_roles.map(n => i18n.t(`constants.user_role.${n.role}`)).reduce((sum, n) => sum === '' ? n : `${sum}, ${n}`, '')
    }
  })

  const clearSelectedUserId = () => setSelectedUserId(null)

  return <>
    <Title>{ t('title') }</Title>
    <Table 
      size='small'
      dataSource={data}
      columns={columns}
      rowKey={'id'} 
      rowSelection={tableSelectionProps} />
    <UserAdminDrawer
      onClose={clearSelectedUserId}
      open={selectedUserId}
      user={selectedUser} />
  </>
}

function mapStateToProps(state) {
  const { users, isLoadingFor } = state
  return {
    users, isLoadingFor
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchUsers: () => dispatch(FETCH_USERS_REQUESTED()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);