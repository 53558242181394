import { createReducer } from '@reduxjs/toolkit'

import {
  FETCH_USERS_FAILED,
  FETCH_USERS_SUCCEEDED,
  FETCH_MASS_UNITS_FAILED,
  FETCH_MASS_UNITS_SUCCEEDED,
  FETCH_COUNTRIES_FAILED,
  FETCH_COUNTRIES_SUCCEEDED,
  FETCH_COUNTRY_SUBDIVISIONS_FAILED,
  FETCH_COUNTRY_SUBDIVISIONS_SUCCEEDED,
  FETCH_NAICS_FAILED,
  FETCH_NAICS_SUCCEEDED,
  FETCH_GASSES_FAILED,
  FETCH_GASSES_SUCCEEDED,
  FETCH_INDUSTRY_CATEGORIES_FAILED,
  FETCH_INDUSTRY_CATEGORIES_SUCCEEDED,
  FETCH_INDUSTRIES_FAILED,
  FETCH_INDUSTRIES_SUCCEEDED,
  FETCH_MAP_DATA_FAILED,
  FETCH_MAP_DATA_SUCCEEDED,
  FETCH_FACILITY_MAP_DATA_FAILED,
  FETCH_FACILITY_MAP_DATA_SUCCEEDED,
  FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_FAILED,
  FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_SUCCEEDED,
  FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_FAILED,
  FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_SUCCEEDED,
  FETCH_ENVIROFACTS_GHG_INJECTION_FACILITIES_FAILED,
  FETCH_ENVIROFACTS_GHG_INJECTION_FACILITIES_SUCCEEDED,
  FETCH_ENVIROFACTS_GHG_SUPPLIER_FACILITIES_FAILED,
  FETCH_ENVIROFACTS_GHG_SUPPLIER_FACILITIES_SUCCEEDED,
  FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_FAILED,
  FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_SUCCEEDED,
  FETCH_ENVIROFACTS_FACILITIES_STOPPED_REPORTING_FAILED,
  FETCH_ENVIROFACTS_FACILITIES_STOPPED_REPORTING_SUCCEEDED,
  FETCH_ENVIROFACTS_GHG_EMITTER_GAS_FAILED,
  FETCH_ENVIROFACTS_GHG_EMITTER_GAS_SUCCEEDED,
  FETCH_ENVIROFACTS_GHG_SUPPLIER_GAS_FAILED,
  FETCH_ENVIROFACTS_GHG_SUPPLIER_GAS_SUCCEEDED,
  FETCH_ENVIROFACTS_GHG_EMITTER_SECTOR_FAILED,
  FETCH_ENVIROFACTS_GHG_EMITTER_SECTOR_SUCCEEDED,
  FETCH_ENVIROFACTS_GHG_SUPPLIER_SECTOR_FAILED,
  FETCH_ENVIROFACTS_GHG_SUPPLIER_SECTOR_SUCCEEDED,
  FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_FAILED,
  FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_SUCCEEDED,
  FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_FAILED,
  FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_SUCCEEDED,
  FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_FAILED,
  FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_SUCCEEDED,
  FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_FAILED,
  FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_SUCCEEDED,
  FETCH_ENVIROFACTS_L_SUBPART_INFORMATION_FAILED,
  FETCH_ENVIROFACTS_L_SUBPART_INFORMATION_SUCCEEDED,
  FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_FAILED,
  FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_SUCCEEDED,
  BULK_INSERT_FACILITY_DATA_FAILED,
  BULK_INSERT_FACILITY_DATA_SUCCEEDED,
  FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_FAILED,
  FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_SUCCEEDED,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_SUCCEEDED,
  FETCH_MAXIMUM_FACILITY_EMISSIONS_FAILED,
  FETCH_MAXIMUM_FACILITY_EMISSIONS_SUCCEEDED,
  FETCH_FILTERED_FACILITY_IDS_FAILED,
  FETCH_FILTERED_FACILITY_IDS_SUCCEEDED,
  FETCH_FACILITY_REPORT_YEARS_FAILED,
  FETCH_FACILITY_REPORT_YEARS_SUCCEEDED,
  FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_FAILED,
  FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_SUCCEEDED,
  CREATE_SEQUESTRATION_WELL_PROJECT_FAILED,
  CREATE_SEQUESTRATION_WELL_PROJECT_SUCCEEDED,
  UPDATE_SEQUESTRATION_WELL_PROJECT_FAILED,
  UPDATE_SEQUESTRATION_WELL_PROJECT_SUCCEEDED,
  FETCH_SEQUESTRATION_WELL_PROJECTS_FAILED,
  FETCH_SEQUESTRATION_WELL_PROJECTS_SUCCEEDED,
  FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_FAILED,
  FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_SUCCEEDED,
  CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_FAILED,
  CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_SUCCEEDED,
  DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_FAILED,
  DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_SUCCEEDED,
  CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_FAILED,
  CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_SUCCEEDED,
  DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_FAILED,
  DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_SUCCEEDED,
  CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_FAILED,
  CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_SUCCEEDED,
  UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_FAILED,
  UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_SUCCEEDED,
  FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_FAILED,
  FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_SUCCEEDED,
  CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED,
  CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED,
  REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED,
  REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED,
  ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED,
  ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED,
  CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED,
  CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED,
  DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED,
  DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED,
  DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED,
  DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED,
  CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_FAILED,
  CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_SUCCEEDED,
  DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_FAILED,
  DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_SUCCEEDED,
  PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_FAILED,
  PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_SUCCEEDED,
  CREATE_USER_ROLE_FAILED,
  CREATE_USER_ROLE_SUCCEEDED,
  DELETE_USER_ROLE_FAILED,
  DELETE_USER_ROLE_SUCCEEDED,
  UPDATE_COMPANY_FAILED,
  UPDATE_COMPANY_SUCCEEDED
} from './actions'

import { clearMessage, handleError, setMessage } from '../shared/redux'

import {
  CLEAR_MESSAGE,
  SET_MESSAGE,
  API_CALL_FINISHED,
  API_CALL_STARTED,
  SET_SESSION,
  SET_USER_ROLE
} from '../shared/redux/actions'

import i18n from 'i18next';

export const initialState = {
  // UI
  isLoadingFor: {},
  isLoading: false,
  message: {
    title: null,
    description: null,
    type: null,
    action: null,
  },
  // Account
  account: null,
  //Admin
  users: null,
  sequestrationWellProjects: null,
  sequestrationWellProjectCompanies: null,
  sequestrationWellProjectViewDocument: null,
  //Session
  session: 0,
  //Metadata
  mass_units: [],
  countries: [],
  country_subdivisions: [],
  naics: [],
  gasses: [],
  industry_categories: [],
  industries: [],
  facilityReportYears: [],
  //MapData
  mapData: {
    data: []
  },
  mapSequestrationWellProjects: null,
  maximumFacilityEmissions: {
    total: 0,
    fossil: 0,
    biogenic: 0
  },
  filteredFacilityIds: [],
  naicsAverageAnnualBiogenicPercentages: {},
  //For Comparing Data Insertion
  facilityCompareIds: null,
  //Envirofacts
  //- Facility Data
  envirofactsGHGEmitterFacilityData: [],
  envirofactsGHGInjectionFacilityData: [],
  envirofactsGHGSupplierFacilityData: [],
  envirofactsParentCompanyInfoData: [],
  envirofactsFacilitiesStoppedReportingData: [],
  //- Gas Data
  envirofactsGHGEmitterGasData: [],
  envirofactsGHGSupplierGasData: [],
  //- Sector Data
  envirofactsGHGEmitterSectorData: [],
  envirofactsGHGSupplierSectorData: [],
  //- Subpart Data
  envirofactsGHGEmitterSubpartData: [],
  envirofactsTSubpartInformationData: [],
  envirofactsISubpartInformationData: [],
  envirofactsFSubpartInformationData: [],
  envirofactsLSubpartInformationData: [],
  envirofactsSSSubpartInformationData: [],
  //CAN GHGRP
  //- Metadata
  canGhgrpDataPublishDate: '',
  canGhgrpFacilityReportData: []
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_MESSAGE, setMessage)
    .addCase(CLEAR_MESSAGE, clearMessage)

    .addCase(API_CALL_STARTED, (state, action) => {
      state.isLoading = true
      state.isLoadingFor[action.payload] = true
    })

    .addCase(API_CALL_FINISHED, (state, action) => {
      state.isLoadingFor[action.payload] = false
      state.isLoading = Object.values(state.isLoadingFor).some((loading) => loading)
    })

    .addCase(SET_SESSION, (state, action) => {
      state.session = action.payload
    })

    .addCase(SET_USER_ROLE, (state, action) => {
      state.user_role = action.payload
    })

    .addCase(FETCH_USERS_FAILED, handleError)
    .addCase(FETCH_USERS_SUCCEEDED, (state, action) => {
      state.users = action.payload
    })

    .addCase(CREATE_USER_ROLE_FAILED, handleError)
    .addCase(CREATE_USER_ROLE_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.user_role_created', { role: i18n.t(`constants.user_role.${action.payload.role}`) }),
        },
      })
    })

    .addCase(DELETE_USER_ROLE_FAILED, handleError)
    .addCase(DELETE_USER_ROLE_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.user_role_deleted', { role: i18n.t(`constants.user_role.${action.payload.role}`) }),
        },
      })
    })

    // Metadata
    .addCase(FETCH_MASS_UNITS_FAILED, handleError)
    .addCase(FETCH_MASS_UNITS_SUCCEEDED, (state, action) => {
      state.mass_units = action.payload
    })

    .addCase(FETCH_COUNTRIES_FAILED, handleError)
    .addCase(FETCH_COUNTRIES_SUCCEEDED, (state, action) => {
      state.countries = action.payload
    })

    .addCase(FETCH_COUNTRY_SUBDIVISIONS_FAILED, handleError)
    .addCase(FETCH_COUNTRY_SUBDIVISIONS_SUCCEEDED, (state, action) => {
      state.country_subdivisions = action.payload
    })

    .addCase(FETCH_NAICS_FAILED, handleError)
    .addCase(FETCH_NAICS_SUCCEEDED, (state, action) => {
      state.naics = action.payload
    })

    .addCase(FETCH_GASSES_FAILED, handleError)
    .addCase(FETCH_GASSES_SUCCEEDED, (state, action) => {
      state.gasses = action.payload
    })

    .addCase(FETCH_INDUSTRY_CATEGORIES_FAILED, handleError)
    .addCase(FETCH_INDUSTRY_CATEGORIES_SUCCEEDED, (state, action) => {
      state.industry_categories = action.payload
    })

    .addCase(FETCH_INDUSTRIES_FAILED, handleError)
    .addCase(FETCH_INDUSTRIES_SUCCEEDED, (state, action) => {
      state.industries = action.payload
    })

    .addCase(FETCH_FACILITY_REPORT_YEARS_FAILED, handleError)
    .addCase(FETCH_FACILITY_REPORT_YEARS_SUCCEEDED, (state, action) => {
      state.facilityReportYears = action.payload.map(n => n.year)
    })

    .addCase(FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_FAILED, handleError)
    .addCase(FETCH_NAICS_AVERAGE_ANNUAL_BIOGENIC_EMISSIONS_PERCENTAGE_SUCCEEDED, (state, action) => {
      const naicsAverageAnnualBiogenicPercentages = { ...state.naicsAverageAnnualBiogenicPercentages }

      naicsAverageAnnualBiogenicPercentages[action.requested_payload] = action.payload

      state.naicsAverageAnnualBiogenicPercentages = naicsAverageAnnualBiogenicPercentages
    })

    .addCase(FETCH_MAP_DATA_FAILED, handleError)
    .addCase(FETCH_MAP_DATA_SUCCEEDED, (state, action) => {
      const mapData = {
        moreData: action.payload.length === 1000
      }

      if (action.requested_payload?.start) {
        mapData.data = [...state.mapData.data, ...action.payload]
      } else {
        mapData.data = action.payload
      }

      state.mapData = mapData
    })

    .addCase(FETCH_FACILITY_MAP_DATA_FAILED, handleError)
    .addCase(FETCH_FACILITY_MAP_DATA_SUCCEEDED, (state, action) => {
      const index = state.mapData.data.findIndex(n => n.id === action.payload[0].id)

      if (index !== -1) {
        const mapData = { ...state.mapData }
        mapData.data = state.mapData.data.map((item, i) => {
          if (i !== index) {
            // This isn't the item we care about - keep it as-is
            return item
          }

          // Otherwise, this is the one we want - return an updated value
          return {
            ...item,
            ...action.payload[0],
            mapSummaryLoaded: true
          }
        })
        state.mapData = mapData
      }
    })

    .addCase(FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_FAILED, handleError)
    .addCase(FETCH_FACILITY_IDS_BY_EXTERNAL_ID_TYPE_SUCCEEDED, (state, action) => {
      state.facilityCompareIds = action.payload
    })

    .addCase(FETCH_MAXIMUM_FACILITY_EMISSIONS_FAILED, handleError)
    .addCase(FETCH_MAXIMUM_FACILITY_EMISSIONS_SUCCEEDED, (state, action) => {
      const maximumFacilityEmissions = {...state.maximumFacilityEmissions}
      const gas_type = action.requested_payload?.gas_type

      if (gas_type) {
        maximumFacilityEmissions[gas_type] = action.payload.quantity_co2e
      } else {
        maximumFacilityEmissions.total = action.payload.quantity_co2e
      }

      state.maximumFacilityEmissions = maximumFacilityEmissions
    })

    .addCase(FETCH_FILTERED_FACILITY_IDS_FAILED, handleError)
    .addCase(FETCH_FILTERED_FACILITY_IDS_SUCCEEDED, (state, action) => {
      state.filteredFacilityIds = action.payload.map(n => n.id)
    })

    //Envirofacts
    .addCase(FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES_SUCCEEDED, (state, action) => {
      state.envirofactsGHGEmitterFacilityData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_GHG_INJECTION_FACILITIES_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_GHG_INJECTION_FACILITIES_SUCCEEDED, (state, action) => {
      state.envirofactsGHGInjectionFacilityData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_GHG_SUPPLIER_FACILITIES_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_GHG_SUPPLIER_FACILITIES_SUCCEEDED, (state, action) => {
      state.envirofactsGHGSupplierFacilityData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_PARENT_COMPANY_INFO_SUCCEEDED, (state, action) => {
      state.envirofactsParentCompanyInfoData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_FACILITIES_STOPPED_REPORTING_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_FACILITIES_STOPPED_REPORTING_SUCCEEDED, (state, action) => {
      state.envirofactsFacilitiesStoppedReportingData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_GHG_EMITTER_GAS_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_GHG_EMITTER_GAS_SUCCEEDED, (state, action) => {
      state.envirofactsGHGEmitterGasData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_GHG_SUPPLIER_GAS_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_GHG_SUPPLIER_GAS_SUCCEEDED, (state, action) => {
      state.envirofactsGHGSupplierGasData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_GHG_EMITTER_SECTOR_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_GHG_EMITTER_SECTOR_SUCCEEDED, (state, action) => {
      state.envirofactsGHGEmitterSectorData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_GHG_SUPPLIER_SECTOR_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_GHG_SUPPLIER_SECTOR_SUCCEEDED, (state, action) => {
      state.envirofactsGHGSupplierSectorData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART_SUCCEEDED, (state, action) => {
      state.envirofactsGHGEmitterSubpartData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_T_SUBPART_INFORMATION_SUCCEEDED, (state, action) => {
      state.envirofactsTSubpartInformationData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_I_SUBPART_INFORMATION_SUCCEEDED, (state, action) => {
      state.envirofactsISubpartInformationData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_F_SUBPART_INFORMATION_SUCCEEDED, (state, action) => {
      state.envirofactsFSubpartInformationData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_L_SUBPART_INFORMATION_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_L_SUBPART_INFORMATION_SUCCEEDED, (state, action) => {
      state.envirofactsLSubpartInformationData = action.payload
    })

    .addCase(FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_FAILED, handleError)
    .addCase(FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION_SUCCEEDED, (state, action) => {
      state.envirofactsSSSubpartInformationData = action.payload
    })

    .addCase(BULK_INSERT_FACILITY_DATA_FAILED, handleError)
    .addCase(BULK_INSERT_FACILITY_DATA_SUCCEEDED, (state, action) => {
      console.log("SUCCESS")
    })

    .addCase(FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_FAILED, handleError)
    .addCase(FETCH_CAN_GHGRP_DATA_PUBLISH_DATE_SUCCEEDED, (state, action) => {
      state.canGhgrpDataPublishDate = action.payload.publishDate
    })

    .addCase(UPDATE_PASSWORD_FAILED, handleError)
    .addCase(UPDATE_PASSWORD_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.password_updated'),
          description: i18n.t('notifications.messages.password_updated'),
        },
      })
    })

    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_FAILED, handleError)
    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_created'),
        },
      })
    })

    .addCase(UPDATE_SEQUESTRATION_WELL_PROJECT_FAILED, handleError)
    .addCase(UPDATE_SEQUESTRATION_WELL_PROJECT_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_updated', { id: action.payload }),
        },
      })
    })

    .addCase(FETCH_SEQUESTRATION_WELL_PROJECTS_FAILED, handleError)
    .addCase(FETCH_SEQUESTRATION_WELL_PROJECTS_SUCCEEDED, (state, action) => {
      state.sequestrationWellProjects = action.payload
    })

    .addCase(FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_FAILED, handleError)
    .addCase(FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_SUCCEEDED, (state, action) => {
      state.mapSequestrationWellProjects = action.payload
    })

    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_FAILED, handleError)
    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_external_id_created', { id: action.payload.id }),
        },
      })
    })

    .addCase(DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_FAILED, handleError)
    .addCase(DELETE_SEQUESTRATION_WELL_PROJECT_EXTERNAL_ID_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_external_id_deleted', { id: action.payload.id }),
        },
      })
    })

    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_FAILED, handleError)
    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_sequestration_well_created', { id: action.payload.id }),
        },
      })
    })

    .addCase(DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_FAILED, handleError)
    .addCase(DELETE_SEQUESTRATION_WELL_PROJECT_SEQUESTRATION_WELL_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_sequestration_well_deleted', { id: action.payload.id }),
        },
      })
    })

    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_FAILED, handleError)
    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_project_developer_created', { id: action.payload.id }),
        },
      })
    })

    .addCase(UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_FAILED, handleError)
    .addCase(UPDATE_SEQUESTRATION_WELL_PROJECT_PROJECT_DEVELOPER_ID_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_project_developer_id_updated', { id: action.payload.project_developer_id }),
        },
      })
    })

    .addCase(FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_FAILED, handleError)
    .addCase(FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_SUCCEEDED, (state, action) => {
      state.sequestrationWellProjectCompanies = action.payload
    })

    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED, handleError)
    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_partner_created', { id: action.payload.id }),
        },
      })
    })

    .addCase(REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED, handleError)
    .addCase(REMOVE_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_partner_removed', { id: action.payload.partner.id }),
        },
      })
    })

    .addCase(ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_FAILED, handleError)
    .addCase(ADD_SEQUESTRATION_WELL_PROJECT_PARTNER_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_partner_added', { id: action.payload.partner.id }),
        },
      })
    })

    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED, handleError)
    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_document_created', { id: action.payload.id }),
        },
      })
    })

    .addCase(DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED, handleError)
    .addCase(DOWNLOAD_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED, (state, action) => {
      state.sequestrationWellProjectViewDocument = action.payload
    })

    .addCase(DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_FAILED, handleError)
    .addCase(DELETE_SEQUESTRATION_WELL_PROJECT_DOCUMENT_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_document_deleted', { id: action.payload.id }),
        },
      })
    })

    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_FAILED, handleError)
    .addCase(CREATE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_mrv_plan_created', { id: action.payload.id }),
        },
      })
    })

    .addCase(DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_FAILED, handleError)
    .addCase(DELETE_SEQUESTRATION_WELL_PROJECT_MRV_PLAN_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_mrv_plan_deleted', { id: action.payload.id }),
        },
      })
    })

    .addCase(PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_FAILED, handleError)
    .addCase(PATCH_SEQUESTRATION_WELL_PROJECT_SHOW_ON_MAP_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.sequestration_well_project_show_on_map_updated', { show_on_map: action.payload.show_on_map }),
        },
      })
    })

    .addCase(UPDATE_COMPANY_FAILED, handleError)
    .addCase(UPDATE_COMPANY_SUCCEEDED, (state, action) => {
      setMessage(state, {
        payload: {
          title: i18n.t('notifications.titles.success'),
          description: i18n.t('notifications.messages.company_updated', { id: action.payload.id }),
        },
      })
    })
})