import { useState } from 'react'
import { Button, Tooltip, Badge, Flex, Typography } from 'antd'
import { useTranslation } from '../shared/hooks'
import { green } from '@ant-design/colors';

const { Text } = Typography

const GetProBadge = ({ className = '', badgeText, backgroundColor, type = 'button', body, tooltipPlacement, size, tooltipTitle }) => {
  const [hover, setHover] = useState(false)
  const { t } = useTranslation('components.GetProBadge')

  const text = badgeText || t('get_pro')
  const props = {
    style: { 
      background: hover ? '#73d13d' : backgroundColor || (type === 'badge' ? green[3] : green[5])
    },
    size,
    className
  }

  let badgeBody = body
  if (!body) {
    switch (type) {
      case 'button':
        badgeBody = <Button {...props} type='primary' >{text}</Button>
        break;
      case 'badge':
        props.style.color = green[8]
        props.className = `${props.className} [&>*]:!shadow-none`
        badgeBody = <Badge {...props} count={text} />
        break;
      default:
        break;
    }
  }

  const onMouseEnter = () => setHover(true)
  const onMouseLeave = () => setHover(false)

  return <Tooltip 
    title={tooltipTitle 
      ? <Flex vertical>
        <span className='font-bold'>{tooltipTitle}</span>
        <span>{t('available_with_pro_license')}</span>
      </Flex> 
      : t('available_with_pro_license') } 
    placement={tooltipPlacement} >
    <a onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} href='https://www.mangrovesystems.com/contact' rel='noreferrer' target='_blank' onClick={(e) => e.stopPropagation()}>
      {badgeBody}
    </a>
  </Tooltip>
}

export default GetProBadge