import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from '../shared/hooks'
import { Typography, Flex, Button, Tooltip, Table, Space, Popover, List } from 'antd'
import { AppstoreAddOutlined, EyeOutlined, CloseOutlined } from '@ant-design/icons';
import SequestrationWellFormDrawer from './SequestrationWellFormDrawer'
import SequestrationWellProjectAdminInfoDrawer from './SequestrationWellProjectAdminInfoDrawer'
import {
  FETCH_SEQUESTRATION_WELL_PROJECTS_REQUESTED,
  FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_REQUESTED
} from '../redux/actions'
import i18n from 'i18next'
import CompanyAdminInfoDrawer from './CompanyAdminInfoDrawer'

const { Title, Link, Text } = Typography

const showOnMapIcon = <EyeOutlined className='text-green-600' />
const hideOnMapIcon = <CloseOutlined className='text-red-600' />

const TABLE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id.toUpperCase().localeCompare(b.id.toUpperCase()),
    render: (text) => <Tooltip title={text}><Text>{text.slice(0, 6)}...</Text></Tooltip>
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())
  },
  {
    title: 'Project Developer',
    dataIndex: 'project_developer_name',
    key: 'project_developer_name',
    sorter: (a, b) => a.project_developer_name?.toUpperCase()?.localeCompare(b.project_developer_name?.toUpperCase())
  },
  {
    title: 'Permitting Body',
    dataIndex: 'permitting_body',
    key: 'permitting_body',
    render: (text) => text ? i18n.t(`constants.sequestration_well_project_permitting_body.${text}`) : '',
    sorter: (a, b) => (a.permitting_body || '').toUpperCase().localeCompare((b.permitting_body || '').toUpperCase())
  },
  {
    title: '# Wells',
    dataIndex: 'num_wells',
    key: 'num_wells',
    sorter: (a, b) => a.num_wells - b.num_wells
  },
  {
    title: 'Show',
    dataIndex: 'show_on_map',
    key: 'show_on_map',
    render: (record) => record ? showOnMapIcon : hideOnMapIcon,
    sorter: (a, b) => (a.show_on_map ? 0 : 1) - (b.show_on_map ? 0 : 1),
    filters: [
      {
        text: showOnMapIcon,
        value: true,
      },
      {
        text: hideOnMapIcon,
        value: false,
      }
    ],
    onFilter: (value, record) => record.show_on_map === value
  }
]

const COMPANY_TABLE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id.toUpperCase().localeCompare(b.id.toUpperCase()),
    render: (text) => <Tooltip title={text}><Text>{text.slice(0, 6)}...</Text></Tooltip>
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())
  },
  {
    title: '# Project Developer',
    dataIndex: 'num_project_developer',
    key: 'num_project_developer',
    sorter: (a, b) => a.num_project_developer - b.num_project_developer
  },
  {
    title: '# Partner',
    dataIndex: 'num_partner',
    key: 'num_partner',
    sorter: (a, b) => a.num_partner - b.num_partner
  }
]

const SEQUESTRATION_WELL_SOURCE_LINKS = [
  {
    label: 'EPA well MRV plans list',
    link: 'https://www.epa.gov/ghgreporting/subpart-rr-geologic-sequestration-carbon-dioxide'
  },
  {
    label: 'CATF Class VI Well tracker',
    link: 'https://www.catf.us/classviwellsmap/'
  },
  {
    label: 'EPA UIC Class VI Well Database',
    link: 'https://udr.epa.gov/ords/uicdr/r/uicdr_ext/uicdr-pub/repository'
  },
  {
    label: 'UIC Class VI Well applications tracker',
    link: 'https://awsedap.epa.gov/public/single/?appid=8c074297-7f9e-4217-82f0-fb05f54f28e7&sheet=51312158-636f-48d5-8fe6-a21703ca33a9&theme=horizon&bookmark=6218ffed-bb6e-42e4-a4f1-52d87e036a1b&opt=ctxmenu',
    description: 'Stats and progress of Class VI well permit applications'
  },
  {
    label: 'State of Louisiana Class VI Well applications tracker',
    link: 'https://www.dnr.louisiana.gov/index.cfm/page/1695'
  },
  {
    label: 'N Dakota Class VI Well applications/permit tracker',
    link: 'https://www.dmr.nd.gov/dmr/oilgas/ClassVI'
  },
  {
    label: 'Wyoming Class VI Well applications tracker',
    link: 'https://deq.wyoming.gov/water-quality/groundwater/uic/class-vi/'
  },
  {
    label: 'Texas UIC Database',
    link: 'https://www.rrc.texas.gov/resource-center/research/data-sets-available-for-download/#underground-injection-control-data',
    description: 'This one might not be publicly accessible'
  },
  {
    label: 'MIT EOR Database',
    link: 'https://sequestration.mit.edu/tools/projects/index_eor.html',
    description: 'As of 2016 - EOR projects in Class II well'
  },
  {
    label: 'Upcoming CCUS projects in Alberta',
    link: 'https://www.cer-rec.gc.ca/en/data-analysis/energy-markets/market-snapshots/2022/market-snapshot-new-projects-alberta-could-add-significant-carbon-storage-capacity-2030.html',
    description: <Flex vertical>
      <span>Note: only the projects marked “sequestration” indicate the existence of a well</span>
      <Flex vertical className='ml-4'>
        <span>- project status</span>
        <span>- startup year</span>
        <span>- sequestration capacity</span>
        <span>- carbon hub designation</span>
        <span>- project participants</span>
      </Flex>
    </Flex>
  },
  {
    label: 'Govt of Canada CCUS hub investments',
    link: 'https://natural-resources.canada.ca/science-and-data/funding-partnerships/opportunities/current-investments/21146'
  },
  {
    label: 'Alberta AER Well Decisions',
    link: 'https://webapps.aer.ca/pod'
  }
]

const WellDataCRUD = ({
    countries,
    country_subdivisions,
    sequestrationWellProjects,
    sequestrationWellProjectCompanies,
    isLoadingFor,
    fetchCountries,
    fetchCountrySubdivisions,
    fetchSequestrationWellProjects,
    fetchSequestrationWellProjectCompanies
}) => {
  const [formDrawerOpen, setFormDrawerOpen] = useState(false)
  const [formSequestrationWellProject, setFormSequestrationWellProject] = useState(null)
  const [selectedSequestrationWellProjectId, setSelectedSequestrationWellProjectId] = useState(null)
  const [selectedSequestrationWellProjectCompanyId, setSelectedSequestrationWellProjectCompanyId] = useState(null)
  const [processedCompanies, setProcessedCompanies] = useState(null)
  const { t } = useTranslation('components.WellDataCRUD')

  const selectedSequestrationWellProject = sequestrationWellProjects?.find(swp => swp.id === selectedSequestrationWellProjectId)

  const selectedSequestrationWellProjectCompany = processedCompanies?.find(swp => swp.id === selectedSequestrationWellProjectCompanyId)

  useEffect(() => {
    if (sequestrationWellProjects && sequestrationWellProjectCompanies) {
      setProcessedCompanies(sequestrationWellProjectCompanies.map(swpc => ({
        ...swpc,
        sequestrationWellProjects: sequestrationWellProjects.filter(swp => swp.partners.findIndex(p => p.partner.id === swpc.id) !== -1 || swp.project_developer?.id === swpc.id).map(swp => ({ 
          ...swp,
          role: swp.project_developer?.id === swpc.id ? 'project_developer' : 'partner'
        }))
      })))
    }
  }, [sequestrationWellProjects, sequestrationWellProjectCompanies])

  const closeFormDrawer = () => {
    setFormDrawerOpen(false)
    fetchSequestrationWellProjects()
    fetchSequestrationWellProjectCompanies()
  }
  const openFormDrawer = () => setFormDrawerOpen(true)

  const selectSequestrationWellProjectId = (id) => {
    setSelectedSequestrationWellProjectId(id)
    clearSelectedSequestrationWellProjectCompanyId()
  }

  const clearSelectedSequestrationWellProjectId = () => setSelectedSequestrationWellProjectId(null)

  const selectSequestrationWellProjectCompanyId = (id) => { 
    setSelectedSequestrationWellProjectCompanyId(id)
    clearSelectedSequestrationWellProjectId()
  }

  const clearSelectedSequestrationWellProjectCompanyId = () => setSelectedSequestrationWellProjectCompanyId(null)

  const onClickCreateNew = () => {
    openFormDrawer()
    setFormSequestrationWellProject({
      id: null,
      name: null,
      description: null,
      well_category: null,
      permitting_body: null,
      active_date: null,
      total_capacity: null,
      expected_annual_injection_rate: null,
      lease_area: null,
      project_stage: null,
      date_current_stage_reached: null,
      external_reference_url: null,
      co2_sources: null,
      location: {
        latitude: null,
        longitude: null
      },
      address: {
        address1: null,
        address2: null,
        city: null,
        zip: null,
        country_id: null,
        country_subdivision_id: null
      }
    })
  }

  useEffect(() => {
    if (!sequestrationWellProjects && !isLoadingFor.FETCH_COUNTRY_SUBDIVISIONS_REQUESTED) fetchSequestrationWellProjects()
  }, [isLoadingFor.FETCH_SEQUESTRATION_WELL_PROJECTS_REQUESTED, sequestrationWellProjects])

  const tableSelectionProps = {
    type: 'radio',
    onChange: (e) => selectSequestrationWellProjectId(e[0]),
    selectedRowKeys: [selectedSequestrationWellProjectId]
  }

  const companyTableSelectionProps = {
    type: 'radio',
    onChange: (e) => selectSequestrationWellProjectCompanyId(e[0]),
    selectedRowKeys: [selectedSequestrationWellProjectCompanyId]
  }

  return <div>
    <Title>{t('title')}</Title>
    <Space direction='vertical' className='w-full'>
      <Flex justify='right' className='gap-2'>
        <Popover
          placement='bottomLeft'
          arrow={false}
          overlayInnerStyle={ { padding: 0 } }
          content={
            <List
              bordered
              size='small'
              dataSource={SEQUESTRATION_WELL_SOURCE_LINKS}
              renderItem={(item) => (
                <List.Item>
                  <Tooltip title={item.description}><Link href={item.link} rel='noreferrer' target='_blank'>{item.label}</Link></Tooltip>
                </List.Item>
              )}/>
          }
          trigger="click">
          <Button>Sources</Button>
        </Popover>
        <Tooltip title={t('create_new')}>
          <Button type='primary' icon={<AppstoreAddOutlined />} onClick={onClickCreateNew} />
        </Tooltip>
      </Flex>
      <Table
        rowKey={r => r.id}
        size='small'
        loading={isLoadingFor.FETCH_SEQUESTRATION_WELL_PROJECTS_REQUESTED}
        columns={TABLE_COLUMNS}
        dataSource={sequestrationWellProjects?.map(swp => ({
          ...swp,
          num_wells: swp.sequestration_wells.length,
          project_developer_name: swp.project_developer?.name
        }))}
        rowSelection={tableSelectionProps}
        onRow={(record, rowIndex) => ({
            onClick: event => selectSequestrationWellProjectId(record.id) // click row
          })
        } />
      <Title>{t('title_companies')}</Title>
      <Table
        rowKey={r => r.id}
        size='small'
        loading={isLoadingFor.FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_REQUESTED}
        columns={COMPANY_TABLE_COLUMNS}
        dataSource={processedCompanies?.map(swpc => ({
          ...swpc,
          num_project_developer: swpc.sequestrationWellProjects.filter(p => p.role === 'project_developer').length,
          num_partner: swpc.sequestrationWellProjects.filter(p => p.role === 'partner').length
        }))}
        rowSelection={companyTableSelectionProps}
        onRow={(record, rowIndex) => ({
            onClick: event => selectSequestrationWellProjectCompanyId(record.id) // click row
          })
        } />
    </Space>
    <SequestrationWellProjectAdminInfoDrawer
      onClose={clearSelectedSequestrationWellProjectId}
      open={selectedSequestrationWellProjectId}
      sequestrationWellProject={selectedSequestrationWellProject}
      setCompanyId={selectSequestrationWellProjectCompanyId} />
    <SequestrationWellFormDrawer
      onClose={closeFormDrawer}
      open={formDrawerOpen}
      sequestrationWellProject={formSequestrationWellProject} />
    <CompanyAdminInfoDrawer 
      open={selectedSequestrationWellProjectCompany}
      onClose={clearSelectedSequestrationWellProjectCompanyId}
      company={selectedSequestrationWellProjectCompany}
      setSequestrationWellProjectId={selectSequestrationWellProjectId} />
  </div>
}

function mapStateToProps(state) {
  const {
    sequestrationWellProjects,
    sequestrationWellProjectCompanies,
    isLoadingFor
  } = state
  return {
    sequestrationWellProjects,
    sequestrationWellProjectCompanies,
    isLoadingFor
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchSequestrationWellProjects: () => dispatch(FETCH_SEQUESTRATION_WELL_PROJECTS_REQUESTED()),
    fetchSequestrationWellProjectCompanies: () => dispatch(FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_REQUESTED())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WellDataCRUD)