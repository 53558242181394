import { createBulkURLParams } from '../../shared/utils'

const envirofactsBaseURL = 'https://data.epa.gov/efservice'
const envirofactsParams = ''//'/Rows/0:499'
const canGHGRPBaseURL = 'https://pollution-waste.canada.ca/sradapi/v2/ghg'
const canGHGRPParams = ''//'/Rows/0:499'

const calls = {
  FETCH_ENVIROFACTS_GHG_EMITTER_FACILITIES: async () => await fetch(`${envirofactsBaseURL}/rlps_ghg_emitter_facilities${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_GHG_INJECTION_FACILITIES: async () => await fetch(`${envirofactsBaseURL}/rlps_ghg_injection_facilities${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_GHG_SUPPLIER_FACILITIES: async () => await fetch(`${envirofactsBaseURL}/rlps_ghg_supplier_facilities${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_PARENT_COMPANY_INFO: async () => await fetch(`${envirofactsBaseURL}/rlps_parent_company_info${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_FACILITIES_STOPPED_REPORTING: async () => await fetch(`${envirofactsBaseURL}/rlps_facilities_stopped_reporting${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_GHG_EMITTER_GAS: async () => await fetch(`${envirofactsBaseURL}/rlps_ghg_emitter_gas${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_GHG_SUPPLIER_GAS: async () => await fetch(`${envirofactsBaseURL}/rlps_ghg_supplier_gas${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_GHG_EMITTER_SECTOR: async () => await fetch(`${envirofactsBaseURL}/rlps_ghg_emitter_sector${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_GHG_SUPPLIER_SECTOR: async () => await fetch(`${envirofactsBaseURL}/rlps_ghg_supplier_sector${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_GHG_EMITTER_SUBPART: async () => await fetch(`${envirofactsBaseURL}/rlps_ghg_emitter_subpart${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_T_SUBPART_INFORMATION: async () => await fetch(`${envirofactsBaseURL}/t_subpart_level_information${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_I_SUBPART_INFORMATION: async () => await fetch(`${envirofactsBaseURL}/mv_ef_i_emissions_by_ghg${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_F_SUBPART_INFORMATION: async () => await fetch(`${envirofactsBaseURL}/f_subpart_level_information${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_L_SUBPART_INFORMATION: async () => await fetch(`${envirofactsBaseURL}/ef_l_gasinfo${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_ENVIROFACTS_SS_SUBPART_INFORMATION: async () => await fetch(`${envirofactsBaseURL}/ss_subpart_level_information${envirofactsParams}/JSON`, {
      method: "GET",
      redirect: "follow"
    }),
  FETCH_CAN_GHGRP_DATA_PUBLISH_DATE: async () => await fetch(`${canGHGRPBaseURL}/Data/PublishDate`, {
      method: "GET",
      redirect: "follow"
    })
}

export default calls