import { createAction } from '@reduxjs/toolkit'

export const API_CALL_STARTED = createAction('API_CALL_STARTED')
export const API_CALL_FINISHED = createAction('API_CALL_FINISHED')

export const FETCH_ACCOUNT_REQUESTED = createAction('FETCH_ACCOUNT_REQUESTED')
export const FETCH_ACCOUNT_FAILED = createAction('FETCH_ACCOUNT_FAILED')
export const FETCH_ACCOUNT_SUCCEEDED = createAction('FETCH_ACCOUNT_SUCCEEDED')

export const CLEAR_MESSAGE = createAction('CLEAR_MESSAGE')
export const SET_MESSAGE = createAction('SET_MESSAGE')

export const SET_SESSION = createAction('SET_SESSION')
export const SET_USER_ROLE = createAction('SET_USER_ROLE')
