import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from '../shared/hooks'
import { Typography, Flex, Button, Drawer, Input, Form, Space } from 'antd'
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { useForm } from '../shared/hooks'
import {
  UPDATE_COMPANY_REQUESTED
} from '../redux/actions'

const { Title } = Typography

const initialFormState = (company) => ({
  ...company
})

const CompanyFormDrawer = ({
  isLoadingFor,
  updateCompany,
  onClose,
  open,
  company
}) => {
  const { form, setForm, handleChange } = useForm({})
  const { t } = useTranslation('components.CompanyFormDrawer')

  useEffect(() => {
    setForm(initialFormState(company))
  }, [open])

  return <Drawer
      title={<Flex justify="space-between">
          <Title className="m-0" level={3}>
            {company?.id || t('title_new')}
          </Title>
          <Button type='text' size='small' onClick={onClose}>
            <CloseOutlined />
          </Button>
        </Flex>}
      size='large'
      closeIcon={false}
      onClose={onClose}
      open={open}>
      <Form layout='vertical'>
        <Form.Item label={t('form.name')} >
          <Input
            name='name'
            value={form.name}
            onChange={handleChange('name')} />
        </Form.Item>
      </Form>
      <Space align="end" className="self-end">
        <Button
          type="primary"
          loading={false}
          icon={<SaveOutlined />}
          onClick={() => { 
            if (form.id) {
              updateCompany({ form, successCallback: onClose })
            } else {
              // createSequestrationWellProject({ form, successCallback: onClose })
              console.log(form)
            }
          }}
        >
          {t('form.save')}
        </Button>
        <Button onClick={onClose}>{t('form.cancel')}</Button>
      </Space>
    </Drawer>
}

function mapStateToProps(state) {
  const {
    isLoadingFor
  } = state
  return {
    isLoadingFor
  }
}

function mapDispatchToProps (dispatch) {
  return {
    // createCompany: ({ form, successCallback }) => dispatch(CREATE_SEQUESTRATION_WELL_PROJECT_REQUESTED({ form, successCallback })),
    updateCompany: ({ form, successCallback }) => dispatch(UPDATE_COMPANY_REQUESTED({ form, successCallback }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyFormDrawer)