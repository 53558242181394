import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createRoot } from 'react-dom/client'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { Typography,
  Button,
  Checkbox,
  Menu,
  theme,
  Table,
  Input,
  Collapse,
  Slider,
  Select,
  Space,
  TreeSelect,
  Tree,
  Badge,
  Flex,
  Tag
} from 'antd'
import WellMarker from '../assets/images/well_marker.svg'
import EmitterMarker from '../assets/images/emitter_marker.svg'
import { 
  MAPBOX_ACCESS_TOKEN,
  SIDE_MENU_BG_COLOR,
  SIDE_MENU_BG_SELECTED_COLOR,
  MOBILE_SCREEN_WIDTH,
  LAYER_ICONS,
  LAYER_COLORS,
  SEQUESTRATION_WELL_PROJECT_CATEGORIES,
  SEQUESTRATION_WELL_PROJECT_STAGES
} from '../shared/constants'
import Sidebar from '../components/Sidebar'
import {
  FilterOutlined,
  SettingFilled,
  LoadingOutlined,
  UpOutlined,
  DownOutlined,
  DatabaseOutlined,
  BuildOutlined,
  CaretRightOutlined
} from '@ant-design/icons'
import * as AntdIcons from '@ant-design/icons'
import { useTranslation } from '../shared/hooks'
import {
  FETCH_MASS_UNITS_REQUESTED,
  FETCH_NAICS_REQUESTED,
  FETCH_GASSES_REQUESTED,
  FETCH_INDUSTRY_CATEGORIES_REQUESTED,
  FETCH_INDUSTRIES_REQUESTED,
  FETCH_MAP_DATA_REQUESTED,
  FETCH_FACILITY_MAP_DATA_REQUESTED,
  FETCH_MAXIMUM_FACILITY_EMISSIONS_REQUESTED,
  FETCH_FILTERED_FACILITY_IDS_REQUESTED,
  FETCH_FACILITY_REPORT_YEARS_REQUESTED,
  FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_REQUESTED
} from '../redux/actions'
import { formatInteger } from '../shared/utils'
import FacilityOverview from '../components/FacilityOverview'
import SequestrationWellProjectOverview from '../components/SequestrationWellProjectOverview'
import GetProBadge from '../components/GetProBadge'
import { green } from '@ant-design/colors';
import { Square3Stack3DIcon } from '@heroicons/react/24/outline'
import MapFeatureList from './MapFeatureList'
import i18n from 'i18next'
import dayjs from 'dayjs'
import IconLabel from './IconLabel'
import SequestrationWellProjectStatusTag from './SequestrationWellProjectStatusTag'

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN
mapboxgl.animate = false

const { Text } = Typography
const { SHOW_PARENT } = TreeSelect

const LAYERS = [
  {
    id: 'emitters',
    mapLayers: ['emitters', 'emitter-clusters', 'emitter-cluster-count', 'emitters-circle', 'emitter-selected', 'emitter-cluster-selected'],
    clickableLayers: ['emitters', 'emitter-clusters']
  },
  {
    id: 'wells',
    mapLayers: ['wells', 'wells-circle', 'well-selected', 'well-clusters', 'well-cluster-count', 'well-cluster-selected'],
    clickableLayers: ['wells', 'well-clusters']
  }
]

const SELECTABLE_LAYER_COLORS = [
  'case',
  ['boolean', ['get', 'selected'], false],
  '#00aa00'
]

const EMISSIONS_COLOR_SPECTRUM = (mEmissions = 1) => ['interpolate',
  ["linear"],
  ['get', 'quantity_co2e'],
  0, "#ffff00",
  mEmissions, "#ff0000"
]

const EMISSIONS_RADIUS_SPECTRUM = (mEmissions = 1, min = 12, max = 48) => {
  return [
    'interpolate',
    ["linear"],
    ['get', 'quantity_co2e'],
    0, min,
    mEmissions, max
  ]
}

const DEFAULT_WELL_FILTER = ['0', '1', '2']

const CarbonMap = ({ 
  fetchMetadata,
  fetchMaximumFacilityEmissions,
  fetchMapData,
  fetchFacilityMapData,
  fetchNaicsAverageAnnualBiogenicEmissionsPercentage,
  fetchFilteredFacilityIds,
  fetchMapSequestrationWellProjects,
  mass_units,
  naics,
  gasses,
  industry_categories,
  industries,
  facilityReportYears,
  maximumFacilityEmissions,
  mapData,
  filteredFacilityIds,
  mapSequestrationWellProjects,
  message,
  isLoading,
  isLoadingFor
}) => {
  const {
    token: { 
      colorBgContainer,
      borderRadiusLG,
      colorTextQuaternary,
      Menu: {
        darkItemBg
      }
    }
  } = theme.useToken();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const sideMenuExpandButton = useRef(null);
  const featureListExpandButton = useRef(null);
  const featureListCollapseButton = useRef(null);
  const [lng, setLng] = useState(-95);
  const [lat, setLat] = useState(40);
  const [zoom, setZoom] = useState(4);
  const [selectedLayerId, setSelectedLayerId] = useState("emitters")
  const [selectedFeatureId, setSelectedFeatureId] = useState(null)
  const [selectFeatureIdFunction, setSelectFeatureIdFunction] = useState(null)
  const [oldMapSelectFeatureIdFunction, setOldMapSelectFeatureIdFunction] = useState(null)
  const [popupCoordinates, setPopupCoordinates] = useState(null)
  const [popup, setPopup] = useState(null)
  const [selectedSideMenuItem, setSelectedSideMenuItem] = useState(null)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [data, setData] = useState([])
  const [maxEmissions, setMaxEmissions] = useState(0)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [totalEmissionsFilterRange, setTotalEmissionsFilterRange] = useState([0, 0])
  const [fossilEmissionsFilterRange, setFossilEmissionsFilterRange] = useState([0, 0])
  const [biogenicEmissionsFilterRange, setBiogenicEmissionsFilterRange] = useState([0, 0])
  const [emissionsBiogenicPercentageFilterRange, setEmissionsBiogenicPercentageFilterRange] = useState([0, 100])
  const [emissionsFilteredYear, setEmissionsFilteredYear] = useState(null)
  const [emissionsFilterYear, setEmissionsFilterYear] = useState(null)
  const [appliedEmissionsFilters, setAppliedEmissionsFilters] = useState(false)
  const [nextEmissionsPageStart, setNextEmissionsPageStart] = useState(1000)
  const [oldMaximumFacilityEmissions, setOldMaximumFacilityEmissions] = useState({})
  const [industryFilterValue, setIndustryFilterValue] = useState(['0'])
  const [updateLayerVisibility, setUpdateLayerVisibility] = useState(false)
  const [wellFilterValue, setWellFilterValue] = useState([...DEFAULT_WELL_FILTER])
  const [currentWellFilterValue, setCurrentWellFilterValue] = useState([...DEFAULT_WELL_FILTER])
  const [appliedWellFilters, setAppliedWellFilters] = useState(false)
  const { t } = useTranslation('components.CarbonMap')

  const refreshData = (d, wells) => {
    const maximumEmissions = d.reduce((max, n) => n.quantity_co2e > max ? n.quantity_co2e : max, 0)

    setMaxEmissions(maximumEmissions)

    const newData = [{
      id: 'emitters',
      name: 'Emitters',
      isLoadingCalls: ['FETCH_MAP_DATA_REQUESTED'],
      data: {
        type: 'FeatureCollection',
        features: d.map(n => ({
          type: 'Feature',
          properties: { 
            ...n,
            selected: n.id === selectedFeatureId,
            hasLocation: !!(n.latitude || n.longitude)
          },
          geometry: {
            type: 'Point',
            coordinates: [n.longitude, n.latitude]
          }
        }))
      }
    },
    {
      id: 'wells',
      name: 'Wells',
      isLoadingCalls: ['FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_REQUESTED'],
      data: {
        type: 'FeatureCollection',
        features: wells.map(w => ({
          type: 'Feature',
          properties: { 
            ...w,
            selected: w.id === selectedFeatureId,
            hasLocation: !!(w.location.latitude || w.location.longitude)
          },
          geometry: {
            type: 'Point',
            coordinates: [w.location.longitude, w.location.latitude]
          }
        }))
      }
    }]

    setData(newData)

    if (map.current?.getSource("emitters")) map.current.getSource('emitters').setData(newData.find(n => n.id === 'emitters').data)
    if (map.current?.getSource("wells")) map.current.getSource('wells').setData(newData.find(n => n.id === 'wells').data)
  }

  const clearEmissionsFilters = () => {
    setTotalEmissionsFilterRange([0, Math.ceil(maximumFacilityEmissions.total)])
    setFossilEmissionsFilterRange([0, Math.ceil(maximumFacilityEmissions.fossil)])
    setBiogenicEmissionsFilterRange([0, Math.ceil(maximumFacilityEmissions.biogenic)])
    setEmissionsBiogenicPercentageFilterRange([0, 100])
    setIndustryFilterValue(['0'])

    setAppliedEmissionsFilters(false)
  }

  const applyEmissionsFilters = (params) => {
    let industryIds = params?.industry_ids

    let needsFilter = true

    const lowerBoundTotal = params?.lower_bound_total || totalEmissionsFilterRange[0]
    const upperBoundTotal = params?.upper_bound_total || totalEmissionsFilterRange[1]
    const year = params?.year || emissionsFilteredYear
    const lowerBoundFossil = params?.lower_bound_fossil || fossilEmissionsFilterRange[0]
    const upperBoundFossil = params?.upper_bound_fossil || fossilEmissionsFilterRange[1]
    const lowerBoundBiogenic = params?.lower_bound_biogenic || biogenicEmissionsFilterRange[0]
    const upperBoundBiogenic = params?.upper_bound_biogenic || biogenicEmissionsFilterRange[1]
    const lowerBoundBiogenicPercentage = params?.lower_bound_biogenic_percentage || emissionsBiogenicPercentageFilterRange[0]
    const upperBoundBiogenicPercentage = params?.upper_bound_biogenic_percentage || emissionsBiogenicPercentageFilterRange[1]

    if (!industryIds) {
      if (industryFilterValue.length === 1 && industryFilterValue[0] === '0') {
        industryIds = null

        // Check if any of the filters are being used
        if (lowerBoundTotal === 0 && 
          upperBoundTotal === Math.ceil(maximumFacilityEmissions.total) &&
          year === emissionsFilteredYear &&
          lowerBoundFossil === 0 &&
          upperBoundFossil === Math.ceil(maximumFacilityEmissions.fossil) &&
          lowerBoundBiogenic === 0 &&
          upperBoundBiogenic === Math.ceil(maximumFacilityEmissions.biogenic) &&
          lowerBoundBiogenicPercentage === 0 &&
          upperBoundBiogenicPercentage === 100) needsFilter = false
      } else {
        industryIds = []
        industryFilterValue.forEach(ifv => {
          const split = ifv.split('_')

          const addIndustryIdIfMissing = (id) => {
            if (industryIds.findIndex(i => i === id) === -1) industryIds.push(id)
          }

          if (split.length === 2) {
            industries.filter(i => i.id === split[1]).forEach(i => addIndustryIdIfMissing(i.id))
          } else if (split.length === 1) {
            industries.filter(i => i.industry_category_id === split[0]).forEach(i => addIndustryIdIfMissing(i.id))
          }
        })
      }
    }

    if (needsFilter) fetchFilteredFacilityIds({ 
      lower_bound_total: lowerBoundTotal,
      upper_bound_total: upperBoundTotal,
      year: year,
      lower_bound_fossil: lowerBoundFossil,
      upper_bound_fossil: upperBoundFossil,
      lower_bound_biogenic: lowerBoundBiogenic,
      upper_bound_biogenic: upperBoundBiogenic,
      lower_bound_biogenic_percentage: lowerBoundBiogenicPercentage,
      upper_bound_biogenic_percentage: upperBoundBiogenicPercentage,
      industry_ids: industryIds
    })

    setAppliedEmissionsFilters(needsFilter)
  }

  const applyWellFilters = () => {
    setCurrentWellFilterValue(wellFilterValue)

    setAppliedWellFilters(true)
  }

  const clearWellFilters = () => {
    setWellFilterValue([...DEFAULT_WELL_FILTER])
    setCurrentWellFilterValue([...DEFAULT_WELL_FILTER])

    setAppliedWellFilters(false)
  }

  useEffect(() => {
    if (!mapSequestrationWellProjects && !isLoadingFor.FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_REQUESTED) fetchMapSequestrationWellProjects()
  }, [fetchMapSequestrationWellProjects, isLoadingFor.FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_REQUESTED])

  useEffect(() => {
    if (!isLoading && mapData.data?.length && mapSequestrationWellProjects) {
      let emitterData = [...mapData.data]
      if (appliedEmissionsFilters && checkLayerVisibility('emitters')) {
        emitterData = emitterData.filter(n => filteredFacilityIds.includes(n.id))
      }

      let sequestrationWellProjectData = mapSequestrationWellProjects
      if (appliedWellFilters && checkLayerVisibility('wells')) {
        const unprefixedFilters = {
          '0': [], //Category
          '1': [], //Status
          '2': [] //Stage
        }
        currentWellFilterValue.forEach(f => {
          if (f.split('-')[1]) unprefixedFilters[f.split('-')[0]].push(f.split('-')[1])
        })

        sequestrationWellProjectData = sequestrationWellProjectData.filter(n => {
          const projectActive = dayjs(n.active_date) <= dayjs()
          const categoryFilter = currentWellFilterValue.includes('0') || unprefixedFilters['0'].includes(n.well_category) || (unprefixedFilters['0'].length === 0 && n.well_category === null)
          const statusFilter = currentWellFilterValue.includes('1') || (projectActive && unprefixedFilters['1'].includes('active')) || (!projectActive && unprefixedFilters['1'].includes('planned'))
          const stageFilter = currentWellFilterValue.includes('2') || unprefixedFilters['2'].includes(n.project_stage) || (unprefixedFilters['2'].length === 0 && n.project_stage === null)

          return categoryFilter && statusFilter && stageFilter
        })
      }

      refreshData(emitterData, sequestrationWellProjectData)
    }
  }, [
    mapData,
    isLoading,
    filteredFacilityIds, 
    selectedFeatureId,
    appliedEmissionsFilters,
    mapSequestrationWellProjects,
    appliedWellFilters,
    currentWellFilterValue
  ])

  useEffect(() => {
    if (maximumFacilityEmissions !== oldMaximumFacilityEmissions) {
      const f = {}
      let needFilter = false
      if (totalEmissionsFilterRange[1] > maximumFacilityEmissions.total || totalEmissionsFilterRange[1] === Math.ceil(oldMaximumFacilityEmissions?.total)) {
        setTotalEmissionsFilterRange([totalEmissionsFilterRange[0], Math.ceil(maximumFacilityEmissions.total)])
        if (appliedEmissionsFilters) {
          f.upper_bound_total = maximumFacilityEmissions.total
          needFilter = true
        }
      }

      if (fossilEmissionsFilterRange[1] > maximumFacilityEmissions.fossil || fossilEmissionsFilterRange[1] === Math.ceil(oldMaximumFacilityEmissions?.fossil)) {
        setFossilEmissionsFilterRange([fossilEmissionsFilterRange[0], Math.ceil(maximumFacilityEmissions.fossil)])
        if (appliedEmissionsFilters) {
          f.upper_bound_fossil = maximumFacilityEmissions.fossil
          needFilter = true
        }
      }

      if (biogenicEmissionsFilterRange[1] > maximumFacilityEmissions.biogenic || biogenicEmissionsFilterRange[1] === Math.ceil(oldMaximumFacilityEmissions?.biogenic)) {
        setBiogenicEmissionsFilterRange([biogenicEmissionsFilterRange[0], Math.ceil(maximumFacilityEmissions.biogenic)])
        if (appliedEmissionsFilters) {
          f.upper_bound_biogenic = maximumFacilityEmissions.biogenic
          needFilter = true
        }
      }

      if (needFilter) applyEmissionsFilters(f)

      setOldMaximumFacilityEmissions(maximumFacilityEmissions)
    }
  }, [
    totalEmissionsFilterRange,
    fossilEmissionsFilterRange,
    biogenicEmissionsFilterRange,
    maximumFacilityEmissions,
    oldMaximumFacilityEmissions,
    appliedEmissionsFilters
  ])

  const proSideMenuItem = (Icon, tooltipTitle) => {
      return <GetProBadge tooltipTitle={tooltipTitle} tooltipPlacement='right' body={<Badge dot={true} offset={[2, 12]} color={green[4]} className='[&>*]:!shadow-none' >
        <Icon style={{ color: '#475467' }} />
      </Badge>} />
    }

  const SIDE_MENU_ITEMS = [
    {
      key: 'filters',
      label: t('side_menu.filter_layers'),
      icon: <Square3Stack3DIcon className='size-4' />
    },
    {
      key: 'directory',
      icon: proSideMenuItem(DatabaseOutlined, t('side_menu.directory'))
    },
    {
      key: 'my_projects',
      icon: proSideMenuItem(BuildOutlined, t('side_menu.my_projects'))
    }
  ]

  // LOAD DATA
  useEffect(() => {
    fetchMetadata()
  }, [fetchMetadata])

  useEffect(() => {
    if (facilityReportYears.length) {
      setEmissionsFilterYear(facilityReportYears.reduce((max, n) => max > n ? max : n, 0))
    }
  }, [facilityReportYears])

  useEffect(() => {
    if (emissionsFilterYear !== emissionsFilteredYear && !isLoadingFor.FETCH_MAP_DATA_REQUESTED) {
      fetchMaximumFacilityEmissions({ year: emissionsFilterYear })
      fetchMaximumFacilityEmissions({ year: emissionsFilterYear, gas_type: 'fossil' })
      fetchMaximumFacilityEmissions({ year: emissionsFilterYear, gas_type: 'biogenic'  })
      fetchMapData({ year: emissionsFilterYear })
      if (appliedEmissionsFilters) applyEmissionsFilters({ year: emissionsFilterYear })
      setNextEmissionsPageStart(1000)
      setEmissionsFilteredYear(emissionsFilterYear)
    }
  }, [
    emissionsFilterYear,
    isLoadingFor.FETCH_MAP_DATA_REQUESTED,
    appliedEmissionsFilters,
    applyEmissionsFilters,
    fetchMaximumFacilityEmissions,
    fetchMapData,
    emissionsFilteredYear
  ])

  useEffect(() => {
    if (!isLoadingFor.FETCH_MAP_DATA_REQUESTED && mapData.moreData && emissionsFilterYear === emissionsFilteredYear) {
      fetchMapData({ start: nextEmissionsPageStart, end: nextEmissionsPageStart + 999, year: emissionsFilteredYear })
      setNextEmissionsPageStart(nextEmissionsPageStart + 1000)
    }
  }, [
    mapData,
    emissionsFilterYear,
    emissionsFilteredYear,
    nextEmissionsPageStart,
    fetchMapData,
    isLoadingFor?.FETCH_MAP_DATA_REQUESTED
  ])

  function handleWindowSizeChange() {
      setScreenWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = screenWidth <= MOBILE_SCREEN_WIDTH;

  const selectSideMenuItem = (key) => {
    clearSelectedFeatureId()
    setSelectedSideMenuItem(key)
  }
  const clearSelectedSideMenuItem = () => setSelectedSideMenuItem(null)

  const checkLayerVisibility = (id) => ['visible', undefined].includes(map.current.getLayoutProperty(id, 'visibility'))
  const setLayerVisibility = (id, visibility) => {
    LAYERS.find(n => n.id === id).mapLayers.forEach(l => map.current.setLayoutProperty(l, 'visibility', visibility ? 'visible' : 'none'))
    setUpdateLayerVisibility(!updateLayerVisibility)
  }

  useEffect(() => {
    setSelectFeatureIdFunction(() => (layerId, id) => {
      clearSelectedFeatureId()
      setSelectedLayerId(layerId)
      setSelectedFeatureId(id)
      setSelectedSideMenuItem(null)

      const selectedLayerData = data.find(n => layerId === n.id)?.data
      if (selectedLayerData?.features) {
        const selectedFeatureIndex = selectedLayerData?.features.findIndex(n => id === n.properties.id)

        if (selectedFeatureIndex !== -1) setPopupCoordinates(selectedLayerData?.features[selectedFeatureIndex].geometry.coordinates.slice())
      }
    })
  }, [data])

  useEffect(() => {
    if (!map.current) return

    const newMapSelectFeatureIdFunction = (e) => {
      const feature = (e?.features || [])[0]

      if (!feature?.properties?.cluster && selectFeatureIdFunction) selectFeatureIdFunction(feature.layer.id, feature.properties.id)
    };

    LAYERS.forEach(l => {
      map.current.off('click', l.id, oldMapSelectFeatureIdFunction)
      map.current.on('click', l.id, newMapSelectFeatureIdFunction)

      return () => {
        map.current.off('click', l.id, newMapSelectFeatureIdFunction)
      }
    })

    setOldMapSelectFeatureIdFunction(() => newMapSelectFeatureIdFunction)
  }, [map.current, selectFeatureIdFunction])

  const clearPopups = () => {
    if (popup) {
      popup.remove()
      setPopup(null)
    }

    const popups = document.getElementsByClassName('mapboxgl-popup');
    if ( popups.length ) {
        popups[0].remove();
    }
  }

  const clearSelectedFeatureId = () => {
    // if (selectedFeatureId !== null) map.current.setFeatureState({source: selectedLayerId, id: selectedFeatureId}, {selected: false});
    setSelectedFeatureId(null)
    clearPopups()
  }

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/navigation-night-v1',
      center: [lng, lat],
      zoom: zoom,
      projection: "mercator"
    })
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right')
    map.current.addControl(new mapboxgl.ScaleControl(), 'bottom-right')
    // map.current.addControl(new mapboxgl.GeolocateControl(), 'top-right')

    map.current.on('load', () => {
      setMapLoaded(true)
    })

    const clickableLayers = LAYERS.map(n => n.clickableLayers).reduce((cat, n) => [...cat, ...n], [])

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.current.on('mouseenter', clickableLayers, () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.current.on('mouseleave', clickableLayers, () => {
      map.current.getCanvas().style.cursor = '';
    });

    // inspect a cluster on click
    map.current.on('click', 'emitter-clusters', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
            layers: ['emitter-clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        map.current.getSource('emitters').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
                if (err) return;

                map.current.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom
                });
            }
        );
    });

    // inspect a cluster on click
    map.current.on('click', 'well-clusters', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
            layers: ['well-clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        map.current.getSource('wells').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
                if (err) return;

                map.current.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom
                });
            }
        );
    });
  });

  useEffect(() => {
    if (data.length === 0 || !map.current.loaded()) return

    if (!map.current.hasImage('pulsing-dot')) {
      const pulsingDotSize = 50;

      const pulsingDot = {
        width: pulsingDotSize,
        height: pulsingDotSize,
        data: new Uint8Array(pulsingDotSize * pulsingDotSize * 4),

        onAdd: function () {
          const canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext('2d');
        },

        render: function () {
          const duration = 1500;
          const t = (performance.now() % duration) / duration;

          const radius = (pulsingDotSize / 2) * 0.3;
          const outerRadius = (pulsingDotSize / 2) * 0.7 * t + radius;
          const context = this.context;

          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          context.fillStyle = `rgba(255, 255, 255, ${(1 - t) * 0.75})`;
          context.fill();

          this.data = context.getImageData(0, 0, this.width, this.height).data;

          map.current.triggerRepaint();

          return true;
        }
      };

      map.current.addImage('pulsing-dot', pulsingDot)
    }

    const pulsingDotLayerProps = {
      type: 'symbol',
      filter: ['boolean', ['get', 'selected'], false],
      layout: {
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'icon-image': 'pulsing-dot'
      }
    }

    if (!map.current.getSource("emitters")) {
      const emitterData = {...data.find(n => n.id === 'emitters').data}
      emitterData.features = [...emitterData.features].filter(n => n.properties.hasLocation)

      const emitterSize = 0.6

      map.current.addSource('emitters', {
        type: 'geojson',
        data: emitterData,
        promoteId: 'id',
        cluster: true,
        clusterMaxZoom: 13,
        clusterRadius: 26,
        clusterProperties: {
          selected: ['any', ['get', 'selected']]
        }
      });

      map.current.addLayer({
        ...pulsingDotLayerProps,
        id: 'emitter-selected',
        source: 'emitters',
        filter: ['all', ['boolean', ['get', 'selected'], false], ['!', ['has', 'point_count']]],
        layout: {
          ...pulsingDotLayerProps.layout,
          'icon-size': 1.3 * emitterSize
        }
      });

      map.current.addLayer({
        ...pulsingDotLayerProps,
        id: 'emitter-cluster-selected',
        source: 'emitters',
        filter: ['all', ['boolean', ['get', 'selected'], false], ['has', 'point_count']],
        layout: {
          ...pulsingDotLayerProps.layout,
          'icon-size': 1.8 * emitterSize
        }
      });

      map.current.addLayer({
        id: 'emitter-clusters',
        type: 'circle',
        source: 'emitters',
        filter: ['has', 'point_count'],
        paint: {
          'circle-radius': 20 * emitterSize,
          'circle-color': [...SELECTABLE_LAYER_COLORS, LAYER_COLORS.emitters],
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      map.current.addLayer({
          id: 'emitter-cluster-count',
          type: 'symbol',
          source: 'emitters',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 20 * emitterSize,
            'text-allow-overlap': true,
            'text-ignore-placement': true
          },
          paint: {
            'text-color': '#fff',
          }
      });

      map.current.addLayer({
        id: 'emitters-circle',
        type: 'circle',
        source: 'emitters',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-radius': 12 * emitterSize,
          'circle-color': [...SELECTABLE_LAYER_COLORS, LAYER_COLORS.emitters],
          "circle-emissive-strength": 1,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      let emitterImg = new Image(128,128)
      emitterImg.onload = () => {
        map.current.addImage('emitter-marker', emitterImg, { sdf: true })

        map.current.addLayer({
          id: 'emitters',
          type: 'symbol',
          source: 'emitters',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            "icon-image": 'emitter-marker',
            'icon-size': 0.14 * emitterSize,
          },
          paint: {
            'icon-color': '#fff'
          }
        });
      }
      emitterImg.src = EmitterMarker
    }

    if (!map.current.getSource("wells")) {
      const wellData = {...data.find(n => n.id === 'wells').data}
      wellData.features = [...wellData.features].filter(n => n.properties.hasLocation)

      map.current.addSource('wells', {
        type: 'geojson',
        data: wellData,
        promoteId: 'id',
        cluster: true,
        clusterMaxZoom: 13,
        clusterRadius: 26,
        clusterProperties: {
          selected: ['any', ['get', 'selected']]
        }
      });

      const wellSize = 0.6

      map.current.addLayer({
        ...pulsingDotLayerProps,
        id: 'well-cluster-selected',
        source: 'wells',
        filter: ['all', ['boolean', ['get', 'selected'], false], ['has', 'point_count']],
        layout: {
          ...pulsingDotLayerProps.layout,
          'icon-size': 1.8 * wellSize
        }
      });

      map.current.addLayer({
        id: 'well-clusters',
        type: 'circle',
        source: 'wells',
        filter: ['has', 'point_count'],
        paint: {
          'circle-radius': 20 * wellSize,
          'circle-color': [...SELECTABLE_LAYER_COLORS, LAYER_COLORS.wells],
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      map.current.addLayer({
          id: 'well-cluster-count',
          type: 'symbol',
          source: 'wells',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 20 * wellSize,
            'text-allow-overlap': true,
            'text-ignore-placement': true
          },
          paint: {
            'text-color': '#fff',
          }
      });

      map.current.addLayer({
        ...pulsingDotLayerProps,
        id: 'well-selected',
        source: 'wells',
        filter: ['all', ['boolean', ['get', 'selected'], false], ['!', ['has', 'point_count']]],
        layout: {
          ...pulsingDotLayerProps.layout,
          'icon-size': 1.3 * wellSize
        }
      });

      map.current.addLayer({
        id: 'wells-circle',
        type: 'circle',
        source: 'wells',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-radius': 12 * wellSize,
          'circle-color': [...SELECTABLE_LAYER_COLORS, LAYER_COLORS.wells],
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      let wellImg = new Image(128,128)
      wellImg.onload = () => {
        map.current.addImage('well-marker', wellImg, { sdf: true })

        map.current.addLayer({
          id: 'wells',
          type: 'symbol',
          source: 'wells',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-image': 'well-marker',
            'icon-size': 0.1 * wellSize
          },
          paint: {
            'icon-color': '#fff'
          }
        });
      }
      wellImg.src = WellMarker
    }
  }, [data, mapLoaded]);

  //Auto Resize Map on detecting size change
  useEffect(() => {
    if (!mapContainer.current) return;
    const resizeObserver = new ResizeObserver(() => map.current.resize());
    resizeObserver.observe(mapContainer.current);
    return () => resizeObserver.disconnect(); // clean up 
  })

  useEffect(() => {
    if (selectedFeatureId && selectedLayerId && !popup && popupCoordinates) {
      const popupNode = document.createElement("div")
      const popupRoot = createRoot(popupNode)

      const pop = new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
        .setLngLat(popupCoordinates)
        .addTo(map.current);

      const layer = data.find(n => selectedLayerId === n.id)

      const popupFeature = (layer?.data?.features || []).find(n => selectedFeatureId === n.properties?.id)

      if (popupFeature) {
        let popupBody = <></>
        switch (selectedLayerId) {
          case 'emitters':
            popupBody = <Text>{formatInteger(Math.round(popupFeature.properties.quantity_co2e))} tCO2e</Text>
            break;
          case 'wells':
            popupBody = <Flex className='!w-fit'>
              { popupFeature.properties.well_category ? <Text>{i18n.t(`constants.sequestration_well_project_category.${popupFeature.properties.well_category}`)}</Text> : <></> }
            </Flex>
            break;
        }


        const content = <div>
          <div>
            <Text strong>{popupFeature.properties.name}</Text>
          </div>
          <Flex justify='space-between'>
            <IconLabel title={layer.name.toUpperCase()} icon_name={LAYER_ICONS[layer.id].icon_name} icon_type={LAYER_ICONS[layer.id].icon_type} color={LAYER_COLORS[layer.id]} />
            { selectedLayerId === 'wells' &&  <SequestrationWellProjectStatusTag className='ml-2' sequestrationWellProject={popupFeature.properties} /> }
          </Flex>
          <div>
            { popupBody }
          </div>
        </div>

        popupRoot.render(content)

        pop.setDOMContent(popupNode)

        setPopup(pop)
      }
    }
  }, [selectedFeatureId, data, popup, selectedLayerId, popupCoordinates])

  const selectedLayerData = data.find(n => selectedLayerId === n.id)?.data
  const selectedFeature = (selectedLayerData?.features || []).find(n => selectedFeatureId === n.properties?.id)

  // LOAD Feature Details
  useEffect(() => {
    if (selectedFeatureId && selectedLayerId === 'emitters' && !selectedFeature?.properties?.mapSummaryLoaded) {
      fetchFacilityMapData(selectedFeatureId)
    }
  }, [selectedFeatureId, selectedLayerId, fetchFacilityMapData, selectedFeature?.properties?.mapSummaryLoaded])

  const centerOnFeature = (feature) => {
    const f = feature || selectedFeature
    if (f.properties.hasLocation)  map.current.flyTo({ center: f.geometry.coordinates, zoom: 6 })
  }

  let detailsContent = ''
  if (selectedLayerId) {
    detailsContent = <MapFeatureList
      layers={LAYERS}
      rowClick={(layerId, featureId) => { selectFeatureIdFunction(layerId, featureId); centerOnFeature((data.find(n => layerId === n.id)?.data?.features || []).find(n => featureId === n.properties?.id)) } }
      selectedFeatureId={selectedFeatureId}
      data={data}
      clearSelectedFeatureId={clearSelectedFeatureId}
      emissionsFilteredYear={emissionsFilteredYear} />
  }

  const updateEmissionsFilterYear = (value) => setEmissionsFilterYear(value)
  const updateTotalEmissionsFilterRange = (value) => setTotalEmissionsFilterRange(value)
  const updateFossilEmissionsFilterRange = (value) => setFossilEmissionsFilterRange(value)
  const updateBiogenicEmissionsFilterRange = (value) => setBiogenicEmissionsFilterRange(value)
  const updateEmissionsBiogenicPercentageFilterRange = (value) => setEmissionsBiogenicPercentageFilterRange(value)

  useEffect(() => {
    if (maximumFacilityEmissions.total && totalEmissionsFilterRange[0] === 0 && totalEmissionsFilterRange[1] === 0) updateTotalEmissionsFilterRange([0, Math.ceil(maximumFacilityEmissions.total)])
    if (maximumFacilityEmissions.fossil && fossilEmissionsFilterRange[0] === 0 && fossilEmissionsFilterRange[1] === 0) updateFossilEmissionsFilterRange([0, Math.ceil(maximumFacilityEmissions.fossil)])
    if (maximumFacilityEmissions.biogenic && biogenicEmissionsFilterRange[0] === 0 && biogenicEmissionsFilterRange[1] === 0) updateBiogenicEmissionsFilterRange([0, Math.ceil(maximumFacilityEmissions.biogenic)])
  }, [maximumFacilityEmissions, totalEmissionsFilterRange, fossilEmissionsFilterRange, biogenicEmissionsFilterRange])

  let controlSidebarContent = ''
  if (selectedSideMenuItem !== null) {
    switch(selectedSideMenuItem) {
      case 'filters':
        const maximumFacilityEmissionsRounded = Math.ceil(maximumFacilityEmissions.total)
        const maximumFacilityFossilEmissionsRounded = Math.ceil(maximumFacilityEmissions.fossil)
        const maximumFacilityBiogenicEmissionsRounded = Math.ceil(maximumFacilityEmissions.biogenic)

        const filterCollapseExpandIcon = ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />

        const proFilterCollapse = (title) => <Collapse 
          bordered={false}
          className='!rounded-none'
          activeKey={false}
          collapsible={'disabled'}
          expandIcon={filterCollapseExpandIcon}
          items={[{
            key: title,
            label: <span className='flex w-full align-middle'>
              <Checkbox checked={false} disabled onChange={e => {
                e.preventDefault()
                e.stopPropagation()
              }} />
              <Text style={{ color: colorTextQuaternary }} className='ml-1 align-middle my-auto'>
                {title}
              </Text>
              <span className='ml-auto'>
                <GetProBadge size='small' tooltipPlacement='right'/>
              </span>
            </span>
          }]} />

        controlSidebarContent = <>
            <div className='grow overflow-scroll p-2'>
              <Space direction='vertical' className='w-full'>
                {LAYERS.map((l, i) => {
                  const layer = data.find(n => l.id === n.id)

                  if (!layer) return <></>

                  const AntdIcon = AntdIcons[layer.icon]

                  const layerVisible = checkLayerVisibility(l.id)
                  const layerKey = `layer-${l.id}`

                  if (l.id === 'emitters') {
                    const collapseItems = [{
                      key: layerKey,
                      label: <>
                        <Checkbox checked={layerVisible} onChange={e => {
                          setLayerVisibility(l.id, e.target.checked)
                        }} />
                        <span>
                          <IconLabel
                            title={layer.name.toUpperCase()}
                            icon_name={LAYER_ICONS[layer.id].icon_name}
                            icon_type={LAYER_ICONS[layer.id].icon_type}
                            color={LAYER_COLORS[layer.id]}
                            onClick={() => { setLayerVisibility(l.id, !layerVisible) }}
                            className='ml-2' />
                          <LoadingOutlined className={`ml-2 ${layer.isLoadingCalls.findIndex(n => isLoadingFor[n]) !== -1 ? '' : '!hidden'}`} />
                        </span>
                        <span className='ml-2'>
                          <Select
                            defaultValue={emissionsFilteredYear}
                            className='min-w-32'
                            size='small'
                            onChange={updateEmissionsFilterYear}
                            options={facilityReportYears.map(n => ({ value: n }))} />
                        </span>
                      </>,
                      children: <Space direction='vertical' className='w-full'>
                        <div>
                          <div>
                            <Text type='secondary' strong>{t('filters.annual_emissions_co2e')}</Text>
                          </div>
                          <div>
                            <Text type='secondary'>{formatInteger(totalEmissionsFilterRange[0])} - {formatInteger(totalEmissionsFilterRange[1])}</Text>
                          </div>
                          <Slider
                            step={100}
                            onChange={updateTotalEmissionsFilterRange}
                            value={totalEmissionsFilterRange}
                            tooltip={{ formatter: formatInteger }}
                            max={maximumFacilityEmissionsRounded}
                            range={{ draggableTrack: true }} />
                        </div>
                        <div>
                          <div>
                            <Text type='secondary' strong>{t('filters.annual_fossil_emissions_co2e')}</Text>
                          </div>
                          <div>
                            <Text type='secondary'>{formatInteger(fossilEmissionsFilterRange[0])} - {formatInteger(fossilEmissionsFilterRange[1])}</Text>
                          </div>
                          <Slider
                            step={100}
                            onChange={updateFossilEmissionsFilterRange}
                            value={fossilEmissionsFilterRange}
                            tooltip={{ formatter: formatInteger }}
                            max={maximumFacilityFossilEmissionsRounded}
                            range={{ draggableTrack: true }} />
                        </div>
                        <div>
                          <div>
                            <Text type='secondary' strong>{t('filters.annual_biogenic_emissions_co2e')}</Text>
                          </div>
                          <div>
                            <Text type='secondary'>{formatInteger(biogenicEmissionsFilterRange[0])} - {formatInteger(biogenicEmissionsFilterRange[1])}</Text>
                          </div>
                          <Slider
                            step={100}
                            onChange={updateBiogenicEmissionsFilterRange}
                            value={biogenicEmissionsFilterRange}
                            tooltip={{ formatter: formatInteger }}
                            max={maximumFacilityBiogenicEmissionsRounded}
                            range={{ draggableTrack: true }} />
                        </div>
                        <div>
                          <div>
                            <Text type='secondary' strong>{t('filters.percentage_biogenic_annual_emissions')}</Text>
                          </div>
                          <div>
                            <Text type='secondary'>{formatInteger(emissionsBiogenicPercentageFilterRange[0])}% - {formatInteger(emissionsBiogenicPercentageFilterRange[1])}%</Text>
                          </div>
                          <Slider
                            onChange={updateEmissionsBiogenicPercentageFilterRange}
                            value={emissionsBiogenicPercentageFilterRange}
                            defaultValue={[0, 100]}
                            tooltip={{ formatter: (value) => `${formatInteger(value)}%` }}
                            range={{ draggableTrack: true }} />
                        </div>
                        <div>
                          <div>
                            <Text type='secondary' strong>{t('filters.industry_sector')}</Text>
                          </div>
                          <TreeSelect 
                            className='min-w-32'
                            placeholder={t('filters.filter_sector')}
                            treeData={[{
                              title: 'All',
                              filterValue: 'All',
                              value: '0',
                              key: '0',
                              children: (industry_categories || []).map(ic => ({ 
                                  title: ic.name,
                                  filterValue: ic.name,
                                  value: ic.id,
                                  id: ic.id,
                                  children: industries.filter(i => i.industry_category_id === ic.id).length > 1 ? industries.filter(i => i.industry_category_id === ic.id).map(i => ({
                                    title: i.name,
                                    filterValue: `${ic.name}-${i.name}`,
                                    value: `${ic.id}_${i.id}`,
                                    isLeaf: true
                                  })) : []
                                }))
                              }]
                            }
                            onChange={value => setIndustryFilterValue(value)}
                            value={industryFilterValue}
                            treeDefaultExpandedKeys={['0']}
                            treeNodeFilterProp='filterValue'
                            defaultValue='0'
                            treeCheckable
                            popupMatchSelectWidth={false}
                            allowClear
                            maxTagCount={4}
                            showCheckedStrategy={SHOW_PARENT} />
                        </div>
                        <Flex justify="space-between">
                          <Button 
                            onClick={applyEmissionsFilters}
                            type='primary'
                            disabled={isLoadingFor.FETCH_FILTERED_FACILITY_IDS_REQUESTED} >
                              { isLoadingFor.FETCH_FILTERED_FACILITY_IDS_REQUESTED ? <LoadingOutlined /> : t('filters.apply_filters') }
                          </Button>
                          { !isLoadingFor.FETCH_FILTERED_FACILITY_IDS_REQUESTED ? <Button onClick={clearEmissionsFilters}>{t('filters.clear_filters')}</Button> : <></>}
                        </Flex>
                      </Space>
                    }]

                    return <Collapse
                      expandIcon={filterCollapseExpandIcon}
                      bordered={false}
                      className='!rounded-none'
                      defaultActiveKey={layerKey}
                      activeKey={!layerVisible ? false : undefined}
                      destroyInactivePanel
                      collapsible={layerVisible ? 'icon' : 'disabled'}
                      items={collapseItems} />
                  } else if (l.id === 'wells') {
                    const collapseItems = [{
                      key: layerKey,
                      label: <>
                        <Checkbox checked={layerVisible} onChange={e => {
                          setLayerVisibility(l.id, e.target.checked)
                        }} />
                        <span>
                          <IconLabel
                            title={layer.name.toUpperCase()}
                            icon_name={LAYER_ICONS[layer.id].icon_name}
                            icon_type={LAYER_ICONS[layer.id].icon_type}
                            color={LAYER_COLORS[layer.id]}
                            onClick={() => { setLayerVisibility(l.id, !layerVisible) }}
                            className='ml-2' />
                          <LoadingOutlined className={`ml-2 ${layer.isLoadingCalls.findIndex(n => isLoadingFor[n]) !== -1 ? '' : '!hidden'}`} />
                        </span>
                      </>,
                      children: <>
                        <Tree 
                          className='!bg-transparent'
                          checkable
                          selectable={false}
                          treeData={[
                            {
                              title: 'All Categories',
                              value: 'all_categories',
                              key: '0',
                              children: SEQUESTRATION_WELL_PROJECT_CATEGORIES.map(c => ({ 
                                title: i18n.t(`constants.sequestration_well_project_category.${c}`),
                                value: c,
                                key: `0-${c}`
                              }))
                            },
                            {
                              title: 'All Statuses',
                              value: 'all_project_active_statuses',
                              key: '1',
                              children: [
                                { 
                                  title: i18n.t(`constants.sequestration_well_project_status.active`),
                                  value: 'active',
                                  key: '1-active'
                                },
                                { 
                                  title: i18n.t(`constants.sequestration_well_project_status.planned`),
                                  value: 'planned',
                                  key: '1-planned'
                                }
                              ]
                            },
                            {
                              title: 'All Permit Stages',
                              value: 'all_project_stages',
                              key: '2',
                              children: SEQUESTRATION_WELL_PROJECT_STAGES.map(s => ({ 
                                title: i18n.t(`constants.sequestration_well_project_stage.${s}`),
                                value: s,
                                key: `2-${s}`
                              }))
                            }]
                          }
                          onCheck={value => setWellFilterValue(value) }
                          checkedKeys={wellFilterValue}
                          defaultExpandedKeys={[...DEFAULT_WELL_FILTER]} />
                        <Flex justify="space-between">
                          <Button
                            onClick={applyWellFilters}
                            type='primary' >
                              { t('filters.apply_filters') }
                          </Button>
                          <Button onClick={clearWellFilters} >{t('filters.clear_filters')}</Button>
                        </Flex>
                      </>
                    }]

                    return <Collapse 
                      expandIcon={filterCollapseExpandIcon}
                      bordered={false}
                      className='!rounded-none'
                      activeKey={!layerVisible ? false : undefined}
                      destroyInactivePanel
                      collapsible={layerVisible ? 'icon' : 'disabled'}
                      items={collapseItems} />
                  }
                })}
              {proFilterCollapse(t('filters.capture_project_membership'))}
              {proFilterCollapse(t('filters.co2_eligibility'))}
            </Space>
          </div>
        </>
        break;
      default:
        break;
    }
  } else if (selectedFeatureId !== null && selectedFeature) {
    switch(selectedLayerId) {
      case 'emitters':
        controlSidebarContent = <div className='max-h-full h-full flex flex-col'>
          <FacilityOverview facility={selectedFeature.properties} />
        </div>
        break;
      case 'wells':
        controlSidebarContent = <div className='max-h-full h-full flex flex-col'>
          <SequestrationWellProjectOverview sequestrationWellProject={selectedFeature.properties} />
        </div>
        break;
      default:
        break;
    }
  }

  return <><div className='grow flex bg-transparent relative'>
      <Sidebar className='min-w-14 !w-fit' forceExpanded={isMobile ? null : true} style={{ background: selectedSideMenuItem ? SIDE_MENU_BG_SELECTED_COLOR : SIDE_MENU_BG_COLOR }} showCollapseButton={isMobile} collapseButtonPosition='bottom-left' collapseButtonType={'text'} collapseButtonClassName={'!text-white'} expandButton={sideMenuExpandButton} onCollapse={clearSelectedSideMenuItem} collapseButtonSize='small'>
        <div className='mx-auto mt-4'>
          <Menu theme='dark' items={SIDE_MENU_ITEMS} mode='inline' inlineCollapsed selectedKeys={[selectedSideMenuItem]} onSelect={e => selectSideMenuItem(e.key)} onDeselect={clearSelectedSideMenuItem} style={{ background: selectedSideMenuItem ? SIDE_MENU_BG_SELECTED_COLOR : SIDE_MENU_BG_COLOR }} />
        </div>
      </Sidebar>
      <div className='flex flex-col grow'>
        <div className='flex grow relative'>
          <Sidebar className='min-w-full md:min-w-[475px]' forceExpanded={!!(selectedSideMenuItem || selectedFeatureId)} style={{ background: colorBgContainer }} showCollapseButton={selectedFeatureId} collapseButtonType='text' collapseButtonClassName='!text-white' onCollapse={clearSelectedFeatureId} >
            {controlSidebarContent}
          </Sidebar>
          <div ref={mapContainer} className='map-container grow w-full' />
          { isMobile && !selectedFeatureId && <div className='absolute top-1 left-1'>
            <Button ref={sideMenuExpandButton} icon={<SettingFilled />} size='small' shape='circle' />
          </div> }
          <div className='absolute bottom-0 left-1/2 w-fit -translate-x-1/2'>
            <Button className='!text-white !rounded-b-none !rounded-t-xl min-w-12' style={{ background: darkItemBg, borderColor: darkItemBg }} ref={featureListExpandButton} icon={<UpOutlined />} />
            <Button className='!text-white !rounded-b-none !rounded-t-xl min-w-12' style={{ background: darkItemBg, borderColor: darkItemBg }} ref={featureListCollapseButton} icon={<DownOutlined />} />
          </div>
        </div>
        <Sidebar className='w-full min-h-64' direction='horizontal' style={{ background: colorBgContainer }} expandButton={featureListExpandButton} collapseButton={featureListCollapseButton}>
          {detailsContent}
        </Sidebar>
      </div>
    </div>
  </>
}

function mapStateToProps(state) {
  const {
    mass_units,
    naics,
    gasses,
    industry_categories,
    industries,
    facilityReportYears,
    maximumFacilityEmissions,
    mapData,
    filteredFacilityIds,
    mapSequestrationWellProjects,
    message,
    isLoading,
    isLoadingFor
  } = state
  return {
    mass_units,
    naics,
    gasses,
    industry_categories,
    industries,
    facilityReportYears,
    maximumFacilityEmissions,
    mapData,
    filteredFacilityIds,
    mapSequestrationWellProjects,
    message,
    isLoading,
    isLoadingFor
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchMetadata: () => {
      dispatch(FETCH_MASS_UNITS_REQUESTED())
      dispatch(FETCH_NAICS_REQUESTED())
      dispatch(FETCH_GASSES_REQUESTED())
      dispatch(FETCH_FACILITY_REPORT_YEARS_REQUESTED())
      dispatch(FETCH_INDUSTRY_CATEGORIES_REQUESTED())
      dispatch(FETCH_INDUSTRIES_REQUESTED())
    },
    fetchMaximumFacilityEmissions: (params) => dispatch(FETCH_MAXIMUM_FACILITY_EMISSIONS_REQUESTED(params)),
    fetchMapData: (params) => dispatch(FETCH_MAP_DATA_REQUESTED(params)),
    fetchFacilityMapData: (id) => dispatch(FETCH_FACILITY_MAP_DATA_REQUESTED(id)),
    fetchFilteredFacilityIds: (params) => dispatch(FETCH_FILTERED_FACILITY_IDS_REQUESTED(params)),
    fetchMapSequestrationWellProjects: () => dispatch(FETCH_SEQUESTRATION_WELL_PROJECTS_FOR_MAP_REQUESTED())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarbonMap);
