import { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from '../shared/hooks'
import { Typography, Flex, Button, Drawer, Tooltip, Table } from 'antd'
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import CompanyFormDrawer from './CompanyFormDrawer'
import { FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_REQUESTED } from '../redux/actions'

const { Title, Text } = Typography

const SEQUESTRATION_WELL_TABLE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id.toUpperCase().localeCompare(b.id.toUpperCase()),
    render: (text) => <Tooltip title={text}><Text>{text.slice(0, 6)}...</Text></Tooltip>
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    sorter: (a, b) => a.role?.toUpperCase()?.localeCompare(b.role?.toUpperCase())
  },
  {
    title: 'Project Developer',
    dataIndex: 'project_developer_name',
    key: 'project_developer_name',
    sorter: (a, b) => a.project_developer_name?.toUpperCase()?.localeCompare(b.project_developer_name?.toUpperCase())
  }
]

const CompanyAdminInfoDrawer = ({
  fetchSequestrationWellProjectCompanies,
  onClose,
  open,
  company,
  placement,
  setSequestrationWellProjectId
}) => {
  const [formDrawerOpen, setFormDrawerOpen] = useState(false)
  const [formCompany, setFormCompany] = useState(null)
  const { t } = useTranslation('components.CompanyAdminInfoDrawer')

  const closeFormDrawer = () => {
    setFormDrawerOpen(false)
    fetchSequestrationWellProjectCompanies()
  }
  const openFormDrawer = () => setFormDrawerOpen(true)

  const onClickEdit = () => {
    if (company) {
      openFormDrawer()
      setFormCompany({
        ...company
      })
    }
  }

  return <Drawer
    placement={placement}
    title={
      <Flex vertical>
        <Flex justify="space-between">
          <Title className="m-0" level={3}>
            {company?.name}
          </Title>
          <Flex>
            <Button size='small' className='mr-2' onClick={onClickEdit} type='primary' icon={<Tooltip placement='left' title={t('edit_tooltip')}><EditOutlined /></Tooltip>} />
            <Button type='text' size='small' onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Flex>
        </Flex>
        <Text className='font-normal'>{company?.id}</Text>
      </Flex>}
    size='large'
    closeIcon={false}
    onClose={onClose}
    open={open}>
    <Flex vertical className='gap-4'>
      { company?.sequestrationWellProjects ? <>
        <Title level={5}>{t('title.sequestration_well_projects')}</Title>
        <Table
          rowKey={r => r.id}
          size='small'
          pagination={false}
          columns={SEQUESTRATION_WELL_TABLE_COLUMNS}
          onRow={(record, rowIndex) => ({
              onClick: event => { // click row
                if (setSequestrationWellProjectId) setSequestrationWellProjectId(record.id)
              }
            })
          }
          dataSource={company.sequestrationWellProjects.map(swp => ({
            id: swp.id,
            name: swp.name,
            role: swp.role === 'project_developer' ? 'Project Developer' : 'Partner',
            project_developer_name: swp.project_developer?.name
          }))} />
        </> : <></> }
    </Flex>
    <CompanyFormDrawer
      onClose={closeFormDrawer}
      open={formDrawerOpen}
      company={formCompany} />
  </Drawer>
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchSequestrationWellProjectCompanies: () => dispatch(FETCH_SEQUESTRATION_WELL_PROJECT_COMPANIES_REQUESTED())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdminInfoDrawer)