import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Menu, theme } from 'antd'
import Unauthorized from '../components/Unauthorized'
import Sidebar from '../components/Sidebar'
import UserList from '../components/UserList'
import DataLoader from '../components/DataLoader'
import WellDataCRUD from '../components/WellDataCRUD'
import { CloudDownloadOutlined, TeamOutlined, AppstoreOutlined } from '@ant-design/icons'
import { SIDE_MENU_BG_COLOR } from '../shared/constants'
import { useTranslation } from '../shared/hooks'

const Admin = ({ user_role }) => {
  const [selectedSideMenuItem, setSelectedSideMenuItem] = useState('users')
  const { t } = useTranslation('pages.Admin')
  const { pathname, hash, key } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (hash) setSelectedSideMenuItem(hash.replace('#', ''))
  }, [hash])

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const selectSideMenuItem = (key) => {
    navigate(`#${key}`)
    setSelectedSideMenuItem(key)
  }

  const sideMenuItems = [
    {
      key: 'users',
      label: t('side_menu.users'),
      icon: <TeamOutlined />
    },
    {
      key: 'well_data',
      label: t('side_menu.well_data'),
      icon: <AppstoreOutlined />
    },
    {
      key: 'bulkUpload',
      label: t('side_menu.bulk_upload'),
      icon: <CloudDownloadOutlined />
    }
  ]

  if (user_role !== 'admin') return <Unauthorized />

  let content = '' 
  switch(selectedSideMenuItem) {
    case 'users':
      content = <UserList />
      break
    case 'well_data':
      content = <WellDataCRUD />
      break
    case 'bulkUpload':
      content = <DataLoader />
      break
    default:
      break
  }

  return (<>
    <Sidebar className='min-w-14 !w-fit' forceExpanded style={{ background: SIDE_MENU_BG_COLOR }}>
      <Menu theme='dark' mode='inline' inlineCollapsed items={sideMenuItems} style={{ background: SIDE_MENU_BG_COLOR }} selectedKeys={[selectedSideMenuItem]} onSelect={e => selectSideMenuItem(e.key)} />
    </Sidebar>
    <div className='w-full p-6' style={{ background: colorBgContainer }}>
      { content }
    </div>
  </>)
}

function mapStateToProps(state) {
  const { user_role } = state
  return {
    user_role
  }
}

export default connect(mapStateToProps)(Admin);

