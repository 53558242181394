import { Fragment } from 'react'
import { Typography, Tooltip, Tag } from 'antd'
import { connect } from 'react-redux'
import { useTranslation } from '../shared/hooks'
import i18n from 'i18next'

const { Text } = Typography

const FacilityNaicsList = ({ naics, facility_naics, className, tooltipColor, tooltipTextColor, showPrimacy = false }) => {
  const { t } = useTranslation('components.FacilityNaicsList')

  const primacies = ['primary', 'secondary', 'extra']
  const primacyColors = ['success', 'processing', 'default']

  return <Text className={className} >{
      [...facility_naics].sort((a, b) => primacies.findIndex(p => p === a.facility_naics_primacy) - primacies.findIndex(p => p === b.facility_naics_primacy))
        .map((n, i) => {
          const f_naics = naics.find(m => m.id === n.naics_id)

          let primacyBadge = <></>
          if (n.facility_naics_primacy && showPrimacy) {
            const badgeColor = primacyColors[primacies.findIndex(p => p === n.facility_naics_primacy)]
            primacyBadge = <span className='ml-1'>
              <Tag size='small' color={badgeColor}>{i18n.t(`constants.facility_naics_primacy.${n.facility_naics_primacy}`)}</Tag>
            </span>
          }

          return <Fragment key={i}>
              {i !== 0 && !showPrimacy ? ' ' : ''}
              {i !== 0 ? '/ ' : ''}
              <Tooltip title={`${t('naics')}: ${f_naics.code}`} color={tooltipColor} overlayInnerStyle={{ color: tooltipTextColor }}>
                {f_naics.title}
                {primacyBadge}
              </Tooltip>
            </Fragment>
        })
    }
  </Text>
}

function mapStateToProps(state) {
  const {
    naics
  } = state
  return {
    naics
  }
}

export default connect(mapStateToProps)(FacilityNaicsList);